<script setup lang="js">
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import { useNodeStore } from '@/stores/node.store';
const route = useRoute();
const nodeStore = useNodeStore();
import { useInputFieldStore } from '@/stores/inputField.store';
import { useViewFieldStore } from '@/stores/viewField.store';
import { useInputDocumentsStore } from '@/stores/inputDocuments.store';
import { useInputGeometriesStore } from '@/stores/inputGeometries.store';
import { useLayerStore } from '@/stores/layer.store';
import { useAutoformStore } from '@/stores/autoform.store';

const inputFieldStore = useInputFieldStore();
const viewFieldStore = useViewFieldStore();
const documentStore = useInputDocumentsStore();
const geomStore = useInputGeometriesStore();
const layerStore = useLayerStore();
const autoformStore = useAutoformStore();

const { inputFieldList } = storeToRefs(inputFieldStore);
const { viewFieldList } = storeToRefs(viewFieldStore);
const { inputDocumentsList, viewDocumentsList } = storeToRefs(documentStore);
const { inputGeometryList, viewGeometryList } = storeToRefs(geomStore);
const { layersList } = storeToRefs(layerStore);
const { autoformList } = storeToRefs(autoformStore);

const hasUnstableViewField = computed(() => {
  return viewFieldList.value.some((viewField) => !viewField.stable);
});
const hasUnstableInputField = computed(() => {
  return inputFieldList.value.some((inputField) => !inputField.stable);
});
const hasUnstableViewDoc = computed(() => {
  return viewDocumentsList.value.some((viewDoc) => !viewDoc.stable);
});
const hasUnstableInputDoc = computed(() => {
  return inputDocumentsList.value.some((inputDoc) => !inputDoc.stable);
});
const hasUnstableViewGeom = computed(() => {
  return viewGeometryList.value.some((viewGeom) => !viewGeom.stable);
});
const hasUnstableInputGeom = computed(() => {
  return inputGeometryList.value.some((inputGeom) => !inputGeom.stable);
});
const hasUnstableLayer = computed(() => {
  return layersList.value.some((layer) => !layer.stable);
});
const hasUnstableAutoForm = computed(() => {
  return autoformList.value.some((autoform) => !autoform.stable);
});
</script>
<template>
  <div class="bp-section-block" :class="{ show: nodeStore.isShowSidebar }">
    <img
      src="@/assets/img/bp-sections-btn.svg"
      @click="nodeStore.isShowSidebar = !nodeStore.isShowSidebar"
    />
    <div class="bp-section-content" v-if="nodeStore.isShowSidebar">
      <router-view />
    </div>
    <div class="bp-section-links">
      <div
        class="section-link"
        :class="{
          'node-fixation_circle': hasUnstableViewField || hasUnstableInputField
        }"
      >
        <RouterLink
          :to="{
            name: 'input-fields',
            params: {
              projectId: route.params.projectId,
              bpId: route.params.bpId,
              nodeId: route.params.nodeId
            }
          }"
          class="bp-section_field"
        />
      </div>
      <div
        class="section-link"
        :class="{
          'node-fixation_circle': hasUnstableViewDoc || hasUnstableInputDoc
        }"
      >
        <RouterLink
          :to="{
            name: 'input-documents',
            params: {
              projectId: route.params.projectId,
              bpId: route.params.bpId,
              nodeId: route.params.nodeId
            }
          }"
          class="bp-section_document"
        />
      </div>
      <div
        class="section-link"
        :class="{
          'node-fixation_circle': hasUnstableInputGeom || hasUnstableViewGeom || hasUnstableLayer
        }"
      >
        <RouterLink
          :to="{
            name: 'input-geometry',
            params: {
              projectId: route.params.projectId,
              bpId: route.params.bpId,
              nodeId: route.params.nodeId
            }
          }"
          class="bp-section_geometry"
        />
      </div>
      <div
        class="section-link"
        :class="{
          'node-fixation_circle': hasUnstableAutoForm
        }"
      >
        <RouterLink
          :to="{
            name: 'autoforms',
            params: {
              projectId: route.params.projectId,
              bpId: route.params.bpId,
              nodeId: route.params.nodeId
            }
          }"
          class="bp-section_formula"
        />
      </div>
    </div>
  </div>
</template>
