<script setup lang="js">
import { onMounted, ref } from 'vue';
import { useProjectStore } from '@/stores/project.store';
import { useLocaleStore } from '@/stores/locale.store';
import { storeToRefs } from 'pinia';
import { useRouteParams } from '@vueuse/router';

const projectId = useRouteParams('projectId');
const projectStore = useProjectStore();
const localeStore = useLocaleStore();
const { project, updateProject } = storeToRefs(projectStore);
const { locales } = storeToRefs(localeStore);

const description = ref('');
const projectName = ref('');
const projectLocales = ref('');
const projectDefaultLocale = ref('');

onMounted(() => {
  localeStore.getLocales();
});

function save() {
  updateProject(projectId, description.value);
}

function selectLocale(itemId) {
  // if (isSelectedLocale(itemId)) {
  //     projectLocales.value = projectLocales.filter((service) => service != itemId)
  // } else {
  //     projectLocales.value.push(itemId)
  // }
}

function isSelectedLocale(localeId) {
  return true;
  // project.locales.value.forEach((locale) => {
  //     if (projectLocales.value.includes(locale.id)) {
  //         return true
  //     } else {
  //         return false
  //     }
  // })
}
</script>
<template>
  <div class="container-fluid project-info-page">
    <div class="row">
      <div class="d-flex flex-wrap mb-5 mt-4">
        <label class="col-md-4">Наименование проекта</label>
        <div class="col-md-8">
          <textarea class="form-control" disabled>{{ project.name }}</textarea>
        </div>
      </div>
      <div class="d-flex flex-wrap mb-5">
        <label class="col-md-4">Интерфейсные языки</label>
        <div class="col-md-8 d-flex flex-wrap">
          <span
            v-for="locale in project.locales"
            class="d-flex flex-wrap align-items-center project-locale"
          >
            <span
              @click="selectLocale(locale.id)"
              class="table-checkbox"
              :class="{ active: isSelectedLocale(locale.id) }"
            />
            {{ locale.name }}
          </span>
        </div>
      </div>
      <div class="d-flex flex-wrap mb-5">
        <label class="col-md-4">Язык по умолчанию</label>
        <div class="col-md-8 d-flex flex-wrap">
          <select class="form-select" disabled>
            <option
              :value="locale.id"
              v-for="locale in locales"
              :selected="locale.id == project.default_locale"
            >
              {{ locale.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="d-flex flex-wrap mb-5">
        <label class="col-md-4">
          Изображение
          <div class="label-gary-text">(формат загружаемого файлa: .png)</div>
        </label>
        <div class="image-border">
          <img src="@/assets/img/project-no-image.svg" />
        </div>
      </div>
      <div class="d-flex flex-wrap mb-5">
        <label class="col-md-4">
          Favicon
          <div class="label-gary-text">(формат загружаемого файлa: .ico)</div>
        </label>
        <div class="image-border d-flex align-items-center justify-content-center">+</div>
      </div>
      <!-- 
            <div class="d-flex flex-wrap mb-5">
                <label class="col-md-4"></label>
                <div class="col-md-3 d-flex flex-wrap">
                    <button class="btn-default btn-primary" @clicl="save()">Сохранить</button>
                </div>
            </div>
             -->
    </div>
  </div>
</template>
