<script setup lang="js">
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import { useRegistryStore } from '@/stores/registry.store';
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';
import { useRouteParams } from '@vueuse/router';
import { useLayerStore } from '@/stores/layer.store';
import Multiselect from 'vue-multiselect';
import { useProjectStore } from '@/stores/project.store';

const { closeModal } = useModal();
const registryStore = useRegistryStore();
const layerStore = useLayerStore();
const projectStore = useProjectStore();

const { regTables } = storeToRefs(registryStore);
const { createLayerRequest } = storeToRefs(layerStore);
const { project } = storeToRefs(projectStore);

const bpId = useRouteParams('bpId');
const nodeId = useRouteParams('nodeId');
const projectId = useRouteParams('projectId');

const regTableId = ref({});
const title = ref({});

onMounted(() => {
  if (!regTables.value.length) {
    registryStore.getRegTableList(projectId.value);
  }
});

function create() {
  layerStore
    .createLayer(projectId.value, bpId.value, nodeId.value, regTableId.value.id, title.value)
    .then(() => {
      closeModal();
    });
}
function isErrorField(fieldName) {
  if (createLayerRequest.value.error?.errors) {
    return createLayerRequest.value.error.errors[fieldName] || false;
  }
}
function isNotFieldError() {
  if (!createLayerRequest.value.error?.errors) {
    return createLayerRequest.value.error;
  }
}
</script>

<template>
  <ModalTemplateSide @submit="create">
    <template v-slot:title>Добавить слой</template>
    <template v-slot:body>
      <div class="mt-3">
        <label class="form-label">Выберите таблицу</label>
        <Multiselect
          v-model="regTableId"
          :options="regTables"
          placeholder="Выбрать..."
          :multiple="false"
          label="id"
          track-by="id"
          class="multiselect-style"
        />

        <p v-if="isErrorField('regTableId')" class="error-mesage">
          <span v-for="error in isErrorField('regTableId')">{{ error }}</span>
        </p>
      </div>

      <div class="mt-3" v-for="locale in project.locales" :key="locale.id">
        <label class="form-label">Наименование слоя на {{ locale.name }}</label>
        <input type="text" v-model="title[locale.id]" class="form-control" :class="{'error': isErrorField('title')}" />
      </div>
      <p v-if="isErrorField('title')" class="error-mesage">
        <span v-for="error in isErrorField('title')">{{ error }}</span>
      </p>

      <p v-if="isNotFieldError()" class="error-mesage">
        {{ isNotFieldError() }}
      </p>
    </template>
    <template v-slot:submit>Добавить</template>
  </ModalTemplateSide>
</template>
