<script setup lang="js">
import { onMounted, ref } from 'vue';
import { useServiceStore } from '@/stores/service.store';
import { useProjectStore } from '@/stores/project.store';
import { useCriterionStore } from '@/stores/criterion.store';
import { useRouteParams } from '@vueuse/router';
import { storeToRefs } from 'pinia';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';

const serviceStore = useServiceStore();
const projectStore = useProjectStore();
const criterionStore = useCriterionStore();
const projectId = useRouteParams('projectId');
const { createServiceRequest } = storeToRefs(serviceStore);
const { project } = storeToRefs(projectStore);
const { criterionList } = storeToRefs(criterionStore);
const { closeModal } = useModal();
const type = ref('');
const title = ref({});
const selectedCriterion = ref([]);
const restricted = ref();

const typeOptions = [
  { value: null, text: '' },
  { value: 'legal_entity', text: 'Юр.лицо' },
  { value: 'individual', text: 'Физ.лицо' }
];

const restrictedValues = [
  { value: null, text: '' },
  { value: true, text: 'Да' },
  { value: false, text: 'Нет' }
];

onMounted(() => {
  projectStore.getProject(projectId.value);
  criterionStore.getCriterionList(projectId.value);
});

function createService() {
  serviceStore
    .createService(
      projectId.value,
      title.value,
      type.value,
      selectedCriterion.value,
      restricted.value
    )
    .then(() => {
      closeModal();
    });
}
function isErrorField(fieldName) {
  if (createServiceRequest.value.error?.errors) {
    return createServiceRequest.value.error.errors[fieldName] || false;
  }
}
function isNotFieldError() {
  if (!createServiceRequest.value.error?.errors) {
    return createServiceRequest.value.error;
  }
}
</script>

<template>
  <ModalTemplateSide @submit="createService">
    <template v-slot:title>Создать услугу</template>
    <template v-slot:body>
      <div class="mt-3">
        <label class="form-label">Тип</label>
        <select class="form-select" v-model="type" :class="{ error: isErrorField('type') }">
          <option v-for="option in typeOptions" :key="option.value" :value="option.value">
            {{ option.text }}
          </option>
        </select>
        <p v-if="isErrorField('type')" class="error-mesage">
          <span v-for="error in isErrorField('type')">{{ error }}</span>
        </p>
      </div>
      <div class="mt-3" v-for="locale in project.locales" :key="locale.id">
        <label class="form-label">Наименование услуги на {{ locale.name }}</label>
        <input
          type="text"
          v-model="title[locale.id]"
          class="form-control"
          :class="{ error: isErrorField('title') }"
        />
        <p v-if="isErrorField('title')" class="error-mesage">
          <span v-for="error in isErrorField('title')">{{ error }}</span>
        </p>
      </div>
      <div class="mt-3">
        <label class="form-label">C ограничением</label>
        <select
          class="form-select"
          v-model="restricted"
          :class="{ error: isErrorField('restricted') }"
        >
          <option
            v-for="restricted in restrictedValues"
            :key="restricted.value"
            :value="restricted.value"
          >
            {{ restricted.text }}
          </option>
        </select>
        <p v-if="isErrorField('restricted')" class="error-mesage">
          <span v-for="error in isErrorField('restricted')">{{ error }}</span>
        </p>
      </div>
      <div class="mt-3">
        <p class="bold-field-name">Выберите критерии:</p>
        <label
          v-for="criterion in criterionList"
          :key="criterion.id"
          class="form-check d-flex align-items-center"
        >
          <input
            type="checkbox"
            v-model="selectedCriterion"
            :value="criterion.id"
            class="form-check-input"
            :class="{ error: isErrorField('criterions') }"
          />
          <span class="bold-field-name ps-2">{{ criterion.title.ru }}</span>
        </label>
        <p v-if="isErrorField('criterions')" class="error-mesage">
          <span v-for="error in isErrorField('criterions')">{{ error }}</span>
        </p>
      </div>
      <p v-if="isNotFieldError()" class="error-mesage">
        {{ isNotFieldError() }}
      </p>
    </template>
    <template v-slot:submit>Создать</template>
  </ModalTemplateSide>
</template>
