<script setup lang="js">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useInputDocumentsStore } from '@/stores/inputDocuments.store';
const inputDocStore = useInputDocumentsStore();

const { inputDocumentsList, viewDocumentsList } = storeToRefs(inputDocStore);

const route = useRoute();

const hasUnstableViewDoc = computed(() => {
  return viewDocumentsList.value.some(viewDoc => !viewDoc.stable);
});
const hasUnstableInputDoc = computed(() => {
  return inputDocumentsList.value.some(inputDoc => !inputDoc.stable);
});
</script>
<template>
  <div class="bp-section-content_links">
    <RouterLink
      :to="{
        name: 'input-documents',
        params: {
          projectId: route.params.projectId,
          bpId: route.params.bpId,
          nodeId: route.params.nodeId
        }
      }"
      class="bp-section-content_link"
      :class="{'input-fixation_circle': hasUnstableInputDoc}"
    >
      ДОКУМЕНТЫ ДЛЯ ЗАПОЛНЕНИЯ
    </RouterLink>
    <RouterLink
      :to="{
        name: 'view-documents',
        params: {
          projectId: route.params.projectId,
          bpId: route.params.bpId,
          nodeId: route.params.nodeId
        }
      }"
      class="bp-section-content_link"
      :class="{'view-fixation_circle': hasUnstableViewDoc}"
    >
      ДОКУМЕНТЫ ДЛЯ ПРОСМОТРА
    </RouterLink>
  </div>
  
  <router-view />
</template>
