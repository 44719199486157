<script setup lang="js">
import _ from 'lodash';
import { onMounted, ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useAutoformStore } from '@/stores/autoform.store';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import BasicToggleSwitch from '@/components/toggle-switch.vue';
import Multiselect from 'vue-multiselect';

const autoformStore = useAutoformStore();
const {
  operationList,
  instruction_steps,
  custom_variables,
  variableList,
  selectedInstructionStep,
  newUuid
} = storeToRefs(autoformStore);
const { closeModal } = useModal();

const original_instruction_steps = ref(0);
const id = ref(0);
const operation = ref(null);
const input_vars = ref({});
const output_vars = ref({});
const condition = ref(false);
const condition_var = ref(null);
const true_step_id = ref(null);
const false_step_id = ref(null);
const children = ref(null);

const inputVarList = computed(() => operation.value?.input_args || []);
const outputVarList = computed(() => operation.value?.output_args || []);

const allVariables = computed(() => [...custom_variables.value, ...variableList.value]);
const columnVariables = computed(() =>
  allVariables.value.reduce((acc, v) => {
    if (v.type != 'array') {
      return acc;
    }

    return [...acc, ...v.table_columns];
  }, [])
);

onMounted(() => {
  original_instruction_steps.value = _.cloneDeep(instruction_steps.value);
  if (selectedInstructionStep.value) {
    let step = { ...selectedInstructionStep.value };
    id.value = step.id;
    if (step.operation_id) {
      operation.value = operationList.value.find((op) => op.id == step.operation_id);
      selectOperation();
    }
    if (step.input_vars) {
      input_vars.value = step.input_vars;
    }
    if (step.output_vars) {
      output_vars.value = step.output_vars;
    }
    if (step.children) {
      children.value = step.children;
    }
    if (step.condition) {
      condition.value = true;
      condition_var.value = step.condition.var;
      true_step_id.value = step.condition.true_step_id;
      false_step_id.value = step.condition.false_step_id;
    }
  }
});

async function getUuid() {
  await autoformStore.generateUuid();
  return newUuid.value;
}
async function generateUuid() {
  let uuid;
  let isUnique = false;

  while (!isUnique) {
    uuid = await getUuid();
    isUnique = !instruction_steps.value.some((step) => step.id === uuid);
  }

  return uuid;
}

function getAllChildren(id) {
  let result = [];
  function findChildren(id) {
    const step = original_instruction_steps.value.find((step) => step.id === id);
    if (step && step.children) {
      step.children.forEach((childId) => {
        result.push(childId);
        findChildren(childId);
      });
    }
  }
  findChildren(id);
  return result;
}

async function updateStep() {
  const stepIndexInList = original_instruction_steps.value.findIndex(
    (st) => st.id == selectedInstructionStep.value.id
  );
  let step = {
    id: id.value,
    operation_id: operation.value.id,
    input_vars: input_vars.value,
    output_vars: output_vars.value,
    parent_id: selectedInstructionStep.value.parent_id
  };
  let children = [];
  let cond = null;
  let firstChildId = null;
  let secondChildId = null;
  if (condition.value && !selectedInstructionStep.value.condition) {
    cond = {};
    cond.var = condition_var.value;
    if (selectedInstructionStep.value.children.length > 1) {
      cond.true_step_id = true_step_id.value;
      cond.false_step_id = false_step_id.value;
      children.push(true_step_id.value, false_step_id.value);
    } else if (selectedInstructionStep.value.children.length) {
      cond.true_step_id = selectedInstructionStep.value.children[0];
      secondChildId = await generateUuid();
      cond.false_step_id = secondChildId;
      children.push(cond.true_step_id);
      children.push(secondChildId);
      instruction_steps.value.push({
        id: secondChildId,
        parent_id: id.value,
        children: []
      });
    } else {
      firstChildId = await generateUuid();
      secondChildId = await generateUuid();
      children.push(firstChildId);
      children.push(secondChildId);
      cond.true_step_id = firstChildId;
      cond.false_step_id = secondChildId;
      instruction_steps.value.push({
        id: firstChildId,
        parent_id: id.value,
        children: []
      });
      instruction_steps.value.push({
        id: secondChildId,
        parent_id: id.value,
        children: []
      });
    }
  } else {
    if (condition.value && selectedInstructionStep.value.condition) {
      cond = {};
      cond.var = condition_var.value;
      cond.true_step_id = true_step_id.value;
      cond.false_step_id = false_step_id.value;
    }
    children = selectedInstructionStep.value.children;
  }
  if (!condition.value && selectedInstructionStep.value.children.length > 1) {
    cond = null;
    let stepsForDelete = getAllChildren(selectedInstructionStep.value.children[1]);
    stepsForDelete.push(selectedInstructionStep.value.children[1]);
    instruction_steps.value = instruction_steps.value.filter(
      (instruction_step) => !stepsForDelete.includes(instruction_step.id)
    );
  }
  step.condition = cond;
  step.children = children;
  instruction_steps.value[stepIndexInList] = step;
  closeModal();
}

function selectOperation() {
  input_vars.value = {};
  output_vars.value = {};
}

async function createCustomVar(outputVar) {
  const varName = await autoformStore.generateUuid();

  let varTitle = outputVar.name;
  let counter = 1;

  while (custom_variables.value.find((v) => v.title == varTitle)) {
    varTitle = `${outputVar.name}_${counter++}`;
  }

  const cVar = {
    name: varName,
    title: varTitle,
    type: outputVar.type
  };

  if (outputVar.type === 'array') {
    cVar.table_columns = Object.values(outputVar.columns).map((i) => ({ ...i }));
  }
  if (outputVar.type === 'text') {
    cVar.default_value = ' ';
  }

  custom_variables.value.push(cVar);

  output_vars.value[outputVar.name] = varName;
}

function isValidVarType(outputVar, customVar) {
  if (outputVar?.type !== 'array') {
    return outputVar?.type == customVar?.type;
  }

  return !_.differenceBy(outputVar?.columns, customVar?.table_columns, (c) => c?.name).length;
}
</script>

<template>
  <ModalTemplateSide @submit="updateStep">
    <template v-slot:title>Редактировать операцию</template>
    <template v-slot:body>
      <div class="mt-3">
        <label class="form-label">Операция</label>
        <Multiselect
          v-model="operation"
          :options="operationList"
          placeholder="Выбрать..."
          :multiple="false"
          label="title"
          track-by="id"
          @select="selectOperation"
          class="multiselect-style"
        />
      </div>
      <div class="operation-modal_section-header">Входные данные</div>
      <div class="mt-3" v-for="(inputVar, index) in inputVarList" :key="index">
        <label class="form-label">{{ inputVar.title }} [{{ inputVar.type }}]</label>
        <select v-if="inputVar.onlyColumn" v-model="input_vars[inputVar.name]" class="form-select">
          <option :value="null" selected></option>
          <template v-for="variable in columnVariables">
            <option
              :value="variable.name"
              v-if="inputVar.type == variable.type"
              :key="variable.name"
            >
              {{ variable.title }}
            </option>
          </template>
        </select>
        <select v-else v-model="input_vars[inputVar.name]" class="form-select">
          <option :value="null" selected></option>
          <template v-for="variable in allVariables">
            <option
              :value="variable.name"
              v-if="inputVar.type == variable.type"
              :key="variable.name"
            >
              {{ variable.title }}
            </option>
          </template>
        </select>
      </div>
      <div class="operation-modal_section-header">Выходные данные</div>
      <div class="mt-3" v-for="(outputVar, index) in outputVarList" :key="index">
        <div class="d-flex justify-content-between">
          <label class="form-label flex-shrink-0">
            {{ outputVar.title }} [{{ outputVar.type }}]
          </label>
          <div class="add-btn mb-2 flex-shrink-0">
            <img
              @click="createCustomVar(outputVar)"
              src="@/assets/img/plus-ico.svg"
              alt="plus-icon"
            />
          </div>
        </div>
        <select v-model="output_vars[outputVar.name]" class="form-select">
          <option :value="null" selected></option>
          <template v-for="variable in custom_variables">
            <option
              :value="variable.name"
              v-if="isValidVarType(outputVar, variable)"
              :key="variable.name"
            >
              {{ variable.title }}
            </option>
          </template>
        </select>
      </div>
      <div class="operation-modal_section-header">&nbsp;</div>
      <div class="mt-3 d-flex align-items-center justify-content-between">
        <label class="form-label">Условие</label>
        <basic-toggle-switch v-model="condition" :disabled="selectedInstructionStep.condition" />
      </div>
      <div class="mt-3" v-if="condition">
        <label class="form-label">Переменная для условия [boolean]</label>
        <select v-model="condition_var" class="form-select">
          <option :value="null" selected></option>
          <template v-for="variable in variableList">
            <option :value="variable.name" v-if="variable.type == 'boolean'" :key="variable.name">
              {{ variable.title }}
            </option>
          </template>
          <template v-for="variable in custom_variables">
            <option :value="variable.name" v-if="variable.type == 'boolean'" :key="variable.name">
              {{ variable.title }}
            </option>
          </template>
        </select>
      </div>
      <div class="mt-3" v-if="condition && (true_step_id || children.length > 1)">
        <label class="form-label">Шаг при true</label>
        <select v-model="true_step_id" class="form-select">
          <option :value="child" v-for="child in children" :key="child.id">
            {{ child }}
          </option>
        </select>
      </div>
      <div class="mt-3" v-if="condition && (false_step_id || children.length > 1)">
        <label class="form-label">Шаг при false</label>
        <select v-model="false_step_id" class="form-select">
          <option :value="child" v-for="child in children" :key="child.id">
            {{ child }}
          </option>
        </select>
      </div>
    </template>
    <template v-slot:submit>Сохранить</template>
  </ModalTemplateSide>
</template>
