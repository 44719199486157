<script setup lang="js">
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useRouteParams } from '@vueuse/router';
import { useProjectStore } from '@/stores/project.store';
import { useAutoformStore } from '@/stores/autoform.store';
import { useInputFieldStore } from '@/stores/inputField.store';
import { useInputGeometriesStore } from '@/stores/inputGeometries.store';
import { storeToRefs } from 'pinia';
import AutoformVariables from '@/components/Autoform/Variables.vue';
import AutoformOperations from '@/components/Autoform/Operations.vue';
import AutoformOutputs from '@/components/Autoform/Outputs.vue';

const autoformStore = useAutoformStore();
const projectId = useRouteParams('projectId');
const bpId = useRouteParams('bpId');
const nodeId = useRouteParams('nodeId');
const autoformId = useRouteParams('autoformId');
const {
  autoform,
  name,
  instruction_steps,
  custom_variables,
  output_entities,
  updateAutoformRequest,
} = storeToRefs(autoformStore);
const activeTab = ref('variables');
const router = useRouter();
const inputGeometriesStore = useInputGeometriesStore();
const { inputGeometryList } = storeToRefs(inputGeometriesStore);
const inputFieldStore = useInputFieldStore();
const { inputFieldList } = storeToRefs(inputFieldStore);
const projectStore = useProjectStore();
const { project } = storeToRefs(projectStore);

onMounted(() => {
  autoformStore.getOperationList(projectId.value, bpId.value, nodeId.value);
  autoformStore.getVariableList(projectId.value, bpId.value, nodeId.value);
  autoformStore.getOutputVariableList(projectId.value, bpId.value, nodeId.value);

  autoformStore
    .getAutoform(projectId.value, bpId.value, nodeId.value, autoformId.value)
    .then(() => {
      prepareAutoform();
    });
  if (!inputFieldList.value.length) {
    inputFieldStore.getInputFieldList(projectId.value, bpId.value, nodeId.value);
  }
  if (!inputGeometryList.value.length) {
    inputGeometriesStore.getInputGeometriesList(projectId.value, bpId.value, nodeId.value);
  }
  if (!Object.keys(project.value).length) {
    projectStore.getProject(projectId.value);
  }
});

function prepareAutoform() {
  if (autoform.value) {
    name.value = autoform.value.name;
    let parentMap = {};
    autoform.value.instruction_steps.forEach((step) => {
      step.children.forEach((childId) => {
        parentMap[childId] = step.id;
      });
    });
    instruction_steps.value = autoform.value.instruction_steps.map((step) => {
      return {
        ...step,
        parent_id: parentMap[step.id] || null
      };
    });
    custom_variables.value = autoform.value.custom_variables;
    output_entities.value = autoform.value.output_entities;
  }
}

function updateAutoform() {
  autoformStore
    .updateAutoform(
      projectId.value,
      bpId.value,
      nodeId.value,
      autoform.value.id,
      name.value,
      instruction_steps.value,
      custom_variables.value,
      output_entities.value
    )
    .then(() =>
      router.push({
        name: 'autoforms',
        params: {
          projectId: projectId.value,
          bpId: bpId.value,
          nodeId: nodeId.value
        }
      })
    );
}
function isErrorField() {
  if (updateAutoformRequest.value.error?.errors) {
    return updateAutoformRequest.value.error.errors || false;
  }
}
function isNotFieldError() {
  if (!updateAutoformRequest.value.error?.errors) {
    return updateAutoformRequest.value.error;
  }
}
</script>
<template>
  <div class="container-fluid">
    <div class="page-header d-flex mt-3 align-items-center">
      <RouterLink
        :to="{
          name: 'autoforms',
          params: {
            projectId: projectId,
            bpId: bpId,
            nodeId: nodeId
          }
        }"
        class="back-btn"
      />
      <h1 class="mb-0">Редактирование автоформы "{{ name }}"</h1>
    </div>
  </div>
  <div class="autoform-page d-flex">
    <div class="autoform_sidebar">
      <div
        class="bp-section-content_link"
        :class="{ 'router-link-active': activeTab == 'variables' }"
        @click="activeTab = 'variables'"
      >
        1. Переменные
      </div>
      <div
        class="bp-section-content_link"
        :class="{ 'router-link-active': activeTab == 'operations' }"
        @click="activeTab = 'operations'"
      >
        2. Операции
      </div>
      <div
        class="bp-section-content_link"
        :class="{ 'router-link-active': activeTab == 'outputs' }"
        @click="activeTab = 'outputs'"
      >
        3. Выходные данные
      </div>
      <div class="px-3 mt-3">
        <div
          class="orange-btn w-100"
          @click="updateAutoform()"
          :disabled="updateAutoformRequest.isLoading"
        >
          Сохранить
        </div>
      </div>
      <p v-if="isErrorField()" class="error-mesage">
        <span v-for="error in isErrorField()" :key="error">{{ error }}</span>
      </p>
      <p v-if="isNotFieldError()" class="error-mesage">
        {{ isNotFieldError() }}
      </p>
    </div>
    <div class="autoform_content" :class="{ 'gray-bg': activeTab == 'operations' }" v-if="autoform">
      <AutoformVariables v-if="activeTab == 'variables'" />
      <AutoformOperations v-if="activeTab == 'operations'" />
      <AutoformOutputs v-if="activeTab == 'outputs'" />
    </div>
  </div>
</template>
