<script setup lang="js">
import { computed } from 'vue';
import { useNodeStore } from '@/stores/node.store';
import { useProjectStore } from '@/stores/project.store';
import { useRouteParams } from '@vueuse/router';
import ModalTemplateCenter from '@/components/modals/ModalTemplateCenter.vue';
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';

const emit = defineEmits(['save']);

const projectId = useRouteParams('projectId');
const bpId = useRouteParams('bpId');

const { closeModal } = useModal();
const nodeStore = useNodeStore();
const { nodeRefData, addNodeRefRequest } = storeToRefs(nodeStore);
const projectStore = useProjectStore();

const locale = computed(() => projectStore.project.default_locale);
const sourceNodeTitle = computed(() => nodeRefData.value.sourceNode?.current?.node?.title[locale.value]);
const targetNodeTitle = computed(() => nodeRefData.value.targetNode?.current?.node?.title[locale.value]);

function addLink() {
  const bpNodeId = nodeRefData.value.sourceNode.id;
  const childBpNodeId = nodeRefData.value.targetNode.id;

  nodeStore.addNodeRef(projectId.value, bpId.value, bpNodeId, childBpNodeId).then(() => {
    closeModal();
  });
}
</script>

<template>
  <ModalTemplateCenter @submit="addLink">
    <template v-slot:title>
      Вы уверены, что хотите добавить связь между узлами "{{ sourceNodeTitle }}" и "{{
        targetNodeTitle
      }}"?
    </template>
    <template v-slot:body>
      <div
        class="d-flex justify-content-center align-items-center"
        v-if="addNodeRefRequest.isLoading"
      >
        <img src="@/assets/img/loader.gif" alt="loader" />
      </div>
      <p v-if="addNodeRefRequest.error" class="error-mesage">
        {{ addNodeRefRequest.error }}
      </p>
    </template>
    <template v-slot:submit>Добавить</template>
  </ModalTemplateCenter>
</template>
