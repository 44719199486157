<script setup lang="js">
import { useVirtualMachineStore } from '@/stores/virtualMachine.store';
import { useRouteParams } from '@vueuse/router';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';

const virtualMachineStore = useVirtualMachineStore();
const projectId = useRouteParams('projectId');
const { closeModal } = useModal();
const { deployVirtualMachineRequest, virtualMachine } = storeToRefs(virtualMachineStore);

function deployMachine() {
  if (deployVirtualMachineRequest.isLoading) return;

  virtualMachineStore.deployVirtualMachine(projectId.value, virtualMachine.value.id).then(() => {
    closeModal();
  });
}
function isRequestError() {
  if (!(deployVirtualMachineRequest.value.error?.errors)) {
    return deployVirtualMachineRequest.value.error;
  }
}
</script>

<template>
  <ModalTemplateSide @submit="deployMachine">
    <template v-slot:title>Деплоить вирт. машину с доменом {{ virtualMachine.domain }}</template>
    <template v-slot:body>
      <p v-if="isRequestError()" class="error-mesage">
        {{ isRequestError() }}
      </p>
    </template>
    <template v-slot:submit>
      <img
        v-if="deployVirtualMachineRequest.isLoading"
        class="loader"
        src="@/assets/img/loader.gif"
        alt="loader.gif"
      />
      <div v-else>Подтвердить</div></template>
  </ModalTemplateSide>
</template>
