<script setup lang="js">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useInputGeometryCatalogsStore } from '@/stores/inputGeometryCatalogs.store';

const geomCatalogStore = useInputGeometryCatalogsStore();

const { inputGeometryCatalogList, viewGeometryCatalogList } = storeToRefs(geomCatalogStore);
const route = useRoute();

const hasUnstableViewGeomCatalogs = computed(() => {
  return viewGeometryCatalogList.value.some((viewGeomCat) => !viewGeomCat.stable);
});
const hasUnstableInputGeomCatalogs = computed(() => {
  return inputGeometryCatalogList.value.some((inputGeomCat) => !inputGeomCat.stable);
});
</script>

<template>
  <div class="bp-section-content_child-links">
    <div class="bp-section-content_child-link" :class="{ active: route.name == 'input-geometry-catalog' }">
      <RouterLink
        :to="{
          name: 'input-geometry-catalog',
          params: {
            projectId: route.params.projectId,
            bpId: route.params.bpId,
            nodeId: route.params.nodeId
          }
        }"
        :class="{ 'input-fixation_circle': hasUnstableInputGeomCatalogs }"
      >
        Заполняемая
      </RouterLink>
    </div>
    <div class="bp-section-content_child-link">
      <RouterLink
        :to="{
          name: 'view-geometry-catalog',
          params: {
            projectId: route.params.projectId,
            bpId: route.params.bpId,
            nodeId: route.params.nodeId
          }
        }"
        :class="{
          'view-fixation_circle': hasUnstableViewGeomCatalogs
        }"
      >
        Просматриваемая
      </RouterLink>
    </div>
  </div>
  <router-view />
</template>
