<script setup lang="js">
import { onMounted, ref } from 'vue';
import { useProjectStore } from '@/stores/project.store';
import { useInputFieldStore } from '@/stores/inputField.store';
import { useRouteParams } from '@vueuse/router';
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';
import ColumnCreateModal from '@/components/modals/ColumnCreateModal.vue';
import ColumnUpdateModal from '@/components/modals/ColumnUpdateModal.vue';
import DeleteModal from '@/components/modals/DeleteModal.vue';
import { useRoute } from 'vue-router';
const route = useRoute();

const inputFieldStore = useInputFieldStore();
const projectStore = useProjectStore();

const projectId = useRouteParams('projectId');
const bpId = useRouteParams('bpId');
const nodeId = useRouteParams('nodeId');
const inputTableId = useRouteParams('inputTableId');
const { closeModal, activeModal } = useModal();
const { project } = storeToRefs(projectStore);
const { inputField, typeList, deleteColumnRequest, changeColumnOrderRequest } =
  storeToRefs(inputFieldStore);
const isChangeOrderMode = ref(false);
const isChangeOrderActive = ref(false);
const selectedColumnIndex = ref(null);
const selectedColumnForEdit = ref({});
const originalFieldsOrder = ref([]);

onMounted(() => {
  inputFieldStore.clearRequestVariables();
  if (!project.value.locales) {
    projectStore.getProject(projectId.value);
  }
  if (Object.keys(inputField.value).length == 0) {
    inputFieldStore.getInputTable(projectId.value, bpId.value, nodeId.value, inputTableId.value);
  }
});

function addColumn() {
  activeModal.value = 'ColumnCreateModal';
}

function openDeleteModal(item) {
  selectedColumnForEdit.value = item;
  activeModal.value = 'deleteModal';
}

function selectItem(index) {
  if (!isChangeOrderMode.value) {
    selectedColumnIndex.value = index;
    isChangeOrderMode.value = true;
  } else {
    const selectedItem = inputField.value.table_columns[selectedColumnIndex.value];
    inputField.value.table_columns.splice(selectedColumnIndex.value, 1);
    inputField.value.table_columns.splice(index, 0, selectedItem);
    isChangeOrderMode.value = false;
    selectedColumnIndex.value = null;
  }
}

function getTableTitle() {
  if (Object.keys(inputField.value).length) {
    let title = inputField.value?.the_table?.title;
    if (title) {
      return title[project.value.default_locale];
    }
  }
}

function selectItemForEdit(item) {
  selectedColumnForEdit.value = item;
  activeModal.value = 'ColumnUpdateModal';
}

function deleteColumn() {
  inputFieldStore.deleteColumn(
    projectId.value,
    bpId.value,
    nodeId.value,
    inputTableId.value,
    selectedColumnForEdit.value.id
  );
  closeModal();
}

function activeOrderState() {
  if (!isChangeOrderActive.value) {
    originalFieldsOrder.value = [...inputField.value.table_columns];
  }
  isChangeOrderActive.value = !isChangeOrderActive.value;
}

function cancelOrdering() {
  inputField.value.table_columns = [...originalFieldsOrder.value];
  isChangeOrderMode.value = false;
  isChangeOrderActive.value = false;
}

function saveOrder() {
  let forSent = [];
  inputField.value.table_columns.forEach((column, index) => {
    forSent.push({
      id: column.id,
      order: index
    });
  });
  inputFieldStore.changeColumnOrder(
    projectId.value,
    bpId.value,
    nodeId.value,
    inputTableId.value,
    forSent
  );
  isChangeOrderMode.value = false;
  selectedColumnIndex.value = null;
  isChangeOrderActive.value = false;
}

function sortColumns(list) {
  if (list) {
    if (!isChangeOrderActive.value) {
      return list.sort((a, b) => a.order - b.order);
    } else {
      return list;
    }
  }
}
function isChangeOrderError() {
  return deleteColumnRequest.value.error;
}
function isDeleteColumnError() {
  return changeColumnOrderRequest.value.error;
}
</script>
<template>
  <div class="container-fluid">
    <div class="d-flex mt-3 ml-60">
      <RouterLink
        :to="{
          name: 'input-fields',
          params: {
            projectId: route.params.projectId,
            bpId: route.params.bpId,
            nodeId: route.params.nodeId
          }
        }"
        class="back-btn"
      />
      <h1>{{ getTableTitle() }}</h1>
    </div>
    <div class="d-flex align-items-center justify-content-end flex-wrap">
      <div class="simple-underline-btn" @click="activeOrderState()" v-if="!isChangeOrderActive">
        Настроить порядок
      </div>
      <template v-else>
        <div class="gray-btn mr-3" @click="cancelOrdering()">Отмена</div>
        <div class="orange-btn" @click="saveOrder()">Сохранить изменения</div>
      </template>
    </div>
    <p v-if="isChangeOrderError()" class="error-mesage">
      {{ isChangeOrderError() }}
    </p>
    <p v-if="isDeleteColumnError()" class="error-mesage">
      {{ isDeleteColumnError() }}
    </p>
    <div class="columns-settings mt-4">
      <div class="columns-settings_list" :class="{ changeOrderModeActive: isChangeOrderActive }">
        <div
          class="columns-settings_item-wrapper"
          v-for="(item, colIndex) in sortColumns(inputField.table_columns)"
          :key="colIndex"
          :class="{ checked: selectedColumnIndex == colIndex }"
        >
          <span
            class="columns-settings_drag-btn"
            @click="selectItem(colIndex)"
            v-if="isChangeOrderActive"
          />
          <div class="columns-settings_item">
            <div v-for="locale in project.locales" :key="locale.id">
              <label>Наименование({{ locale.name }})</label>
              <input
                type="text"
                v-model="item.column.title[locale.id]"
                class="form-control"
                disabled
                :title="item.column.title[locale.id]"
              />
            </div>
            <div>
              <label>Тип</label>
              <select v-model="item.column.type" class="form-select" disabled>
                <option v-for="typeKey in Object.keys(typeList)" :key="typeKey" :value="typeKey">
                  {{ typeList[typeKey] }}
                </option>
              </select>
            </div>
            <div>
              <label>Обязательность</label>
              <select v-model="item.column.required" class="form-select" disabled>
                <option :value="true">Да</option>
                <option :value="false">Нет</option>
              </select>
            </div>
          </div>
          <span class="columns-settings_edit-btn" @click="selectItemForEdit(item)"><i /></span>
          <span class="columns-settings_delete-btn" @click="openDeleteModal(item)"><i /></span>
        </div>
      </div>
    </div>
    <button class="simple-btn mt-4 mb-3 ml-35" @click="addColumn()">
      <span class="orange-plus" />
      Добавить столбец
    </button>
  </div>
  <ColumnCreateModal v-if="activeModal === 'ColumnCreateModal'" />
  <ColumnUpdateModal v-if="activeModal === 'ColumnUpdateModal'" :column="selectedColumnForEdit" />
  <DeleteModal
    v-if="activeModal === 'deleteModal'"
    :itemText="'эту колонку'"
    @confirmDelete="deleteColumn()"
  />
</template>
