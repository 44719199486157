<script setup lang="js">
import { onMounted, ref, computed } from 'vue';
import { useProjectStore } from '@/stores/project.store';
import { useInputFieldStore } from '@/stores/inputField.store';
import { useNodeStore } from '@/stores/node.store';
import { useRouteParams } from '@vueuse/router';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import BasicToggleSwitch from '@/components/toggle-switch.vue';
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';
import Multiselect from 'vue-multiselect';

const inputFieldStore = useInputFieldStore();
const projectStore = useProjectStore();
const nodeStore = useNodeStore();
const { bpNode } = storeToRefs(nodeStore);
const { createInputStaticRequest } = storeToRefs(inputFieldStore);

const projectId = useRouteParams('projectId');
const bpId = useRouteParams('bpId');
const nodeId = useRouteParams('nodeId');
const { closeModal } = useModal();
const { project } = storeToRefs(projectStore);
const childNodes = bpNode.value.child_nodes;

const title = ref({});
const order = ref(0);
const italic = ref(false);
const bold = ref(false);
const size = ref("header");
const child_node_ids = ref([]);
const selectedChildNodeIds = computed(() => {
  return child_node_ids.value.map(node => node.id);
});

onMounted(() => {
  inputFieldStore.clearRequestVariables();
  if (!project.locales) {
    projectStore.getProject(projectId.value);
  }
});

function createStatic() {
  inputFieldStore.createInputStatic(projectId.value, bpId.value, nodeId.value, title.value, italic.value, bold.value, size.value, selectedChildNodeIds.value)
  .then(() => {
    closeModal();
  });
}

function getName(node) {
  return node.current.node.name;
}
function isErrorField(fieldName) {
  if (createInputStaticRequest.value.error?.errors) {
    return createInputStaticRequest.value.error.errors[fieldName] || false;
  }
}
function isNotFieldError() {
  if (!(createInputStaticRequest.value.error?.errors)) {
    return createInputStaticRequest.value.error;
  }
}
</script>

<template>
  <ModalTemplateSide @submit="createStatic">
    <template v-slot:title>Добавить заголовок для заполнения</template>
    <template v-slot:body>
      <div class="mt-3" v-for="locale in project.locales" :key="locale.id">
        <label class="form-label">Наименование поля({{ locale.name }})</label>
        <input type="text" v-model="title[locale.id]" class="form-control" :class="{'error': isErrorField('title')}" />
      </div>
      <p v-if="isErrorField('title')" class="error-mesage">
        <span v-for="error in isErrorField('title')">{{ error }}</span>
      </p>
      <!-- <div class="mt-3 d-flex align-items-center justify-content-between">
        <label class="form-label">Курсив</label>
        <basic-toggle-switch v-model="italic" />
      </div>
      <div class="mt-3 d-flex align-items-center justify-content-between">
        <label class="form-label">Жирный текст</label>
        <basic-toggle-switch v-model="bold" />
      </div>
      <div class="mt-3 d-flex align-items-center justify-content-between">
        <label class="form-label">Размер:</label>
        <div class="static-sizes">
          <span @click="size = 'header'" :class="{checked: size == 'header'}">Заголовок</span>
          <span @click="size = 'note'" :class="{checked: size == 'note'}">Примечание</span>
        </div>
      </div> -->
      <div class="mt-3">
        <label class="form-label">Дочерние узлы</label>
        <Multiselect
          v-model="child_node_ids"
          :options="childNodes"
          placeholder="Выбрать..."
          :multiple="true"
          :custom-label="getName"
          track-by="id"
          class="multiselect-style"
          :class="{ error: isErrorField('child_node_ids') }"
        />
        <p v-if="isErrorField('child_node_ids')" class="error-mesage">
          <span v-for="error in isErrorField('child_node_ids')">{{ error }}</span>
        </p>
      </div>
      <!-- <div class="mt-3">
        <label class="form-label">Порядок</label>
        <input type="number" v-model="order" class="form-control" min="0"/>
      </div> -->
      <p v-if="isNotFieldError()" class="error-mesage">
        {{ isNotFieldError() }}
      </p>
    </template>
    <template v-slot:submit>Создать</template>
  </ModalTemplateSide>
</template>
