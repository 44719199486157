import { inject, ref } from 'vue';
import { defineStore } from 'pinia';
import baseAxios from 'axios';

import { AXIOS } from '../const';

import * as api from '../router/endpoints';

export const useInputFieldStore = defineStore('inputField', () => {
  const axios = inject(AXIOS) || baseAxios;

  const inputFieldList = ref([]);
  const inputField = ref({});
  const registryList = ref([]);
  const nodeAncestorList = ref([]);
  const newInputFieldOrder = ref(0);

  const typeList = ref({
    number: 'Число',
    datetime: 'Дата',
    text: 'Текст',
    boolean: 'Логический (да/нет)',
    polygon: 'Полигон',
    point: 'Точка',
    line: 'Линия',
  });

  const getInputFieldListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const createInputFieldRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const createInputCatalogRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const createInputTableRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const createInputStaticRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const deleteFieldRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const getAncestorListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const changeFormInputOrderRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const changeDirectionalsRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const updateInputFieldRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const updateInputCatalogRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const updateInputStaticRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const updateInputTableRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const getInputTableRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const createColumnRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const updateColumnRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const deleteColumnRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const changeColumnOrderRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  function getInputFieldList(projectId, bpId, nodeId) {
    getInputFieldListRequest.value = {};
    const req = getInputFieldListRequest.value;
    req.isLoading = true;
    inputFieldList.value.sort((a, b) => a.order - b.order);
    return axios
      .get(api.formInput({ projectId, bpId, nodeId }))
      .then((resp) => {
        inputFieldList.value = resp.data.sort((a, b) => a.order - b.order);
        newInputFieldOrder.value = inputFieldList.value.length + 1;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getAncestorList(projectId, bpId, nodeId) {
    getAncestorListRequest.value = {};
    const req = getAncestorListRequest.value;
    req.isLoading = true;
    return axios
      .get(api.formInputAncestors({ projectId, bpId, nodeId }))
      .then((resp) => {
        nodeAncestorList.value = resp.data.sort((a, b) => a.id - b.id);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function createInputField(
    projectId,
    bpId,
    nodeId,
    title,
    type,
    required,
    settings = null,
    child_node_ids
  ) {
    createInputFieldRequest.value = {};
    const req = createInputFieldRequest.value;
    req.isLoading = true;
    const order = newInputFieldOrder.value;

    return axios
      .post(api.inputFields({ projectId, bpId, nodeId }), {
        title,
        type,
        required,
        settings,
        order,
        child_node_ids
      })
      .then(() => {
        getInputFieldList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function createInputCatalog(
    projectId,
    bpId,
    nodeId,
    reg_table_id,
    reg_version,
    title,
    title_columns,
    required,
    child_node_ids
  ) {
    createInputCatalogRequest.value = {};
    const req = createInputCatalogRequest.value;
    req.isLoading = true;
    const order = newInputFieldOrder.value;

    return axios
      .post(api.inputCatalog({ projectId, bpId, nodeId }), {
        reg_table_id,
        reg_version,
        title,
        title_columns,
        order,
        required,
        child_node_ids
      })
      .then(() => {
        getInputFieldList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function createInputTable(projectId, bpId, nodeId, title, child_node_ids, required) {
    createInputTableRequest.value = {};
    const req = createInputTableRequest.value;
    req.isLoading = true;
    const order = newInputFieldOrder.value;
    const columns = [];

    return axios
      .post(api.inputTable({ projectId, bpId, nodeId }), {
        title,
        columns,
        order,
        child_node_ids,
        required
      })
      .then(() => {
        getInputFieldList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function createInputStatic(projectId, bpId, nodeId, title, italic, bold, size, child_node_ids) {
    createInputStaticRequest.value = {};
    const req = createInputStaticRequest.value;
    req.isLoading = true;
    const order = newInputFieldOrder.value;

    return axios
      .post(api.inputStatic({ projectId, bpId, nodeId }), {
        title,
        order,
        child_node_ids
      })
      .then(() => {
        getInputFieldList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function deleteInputField(projectId, bpId, nodeId, inputFieldId) {
    deleteFieldRequest.value = {};
    const req = deleteFieldRequest.value;
    req.isLoading = true;

    return axios
      .delete(api.inputFieldById({ projectId, bpId, nodeId, inputFieldId }))
      .then(() => {
        getInputFieldList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function deleteInputCatalog(projectId, bpId, nodeId, inputCatalogId) {
    deleteFieldRequest.value = {};
    const req = deleteFieldRequest.value;
    req.isLoading = true;

    return axios
      .delete(api.inputCatalogById({ projectId, bpId, nodeId, inputCatalogId }))
      .then(() => {
        getInputFieldList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function deleteInputTable(projectId, bpId, nodeId, inputTableId) {
    deleteFieldRequest.value = {};
    const req = deleteFieldRequest.value;
    req.isLoading = true;

    return axios
      .delete(api.inputTableById({ projectId, bpId, nodeId, inputTableId }))
      .then(() => {
        getInputFieldList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function deleteInputStatic(projectId, bpId, nodeId, inputStaticId) {
    deleteFieldRequest.value = {};
    const req = deleteFieldRequest.value;
    req.isLoading = true;

    return axios
      .delete(api.inputStaticById({ projectId, bpId, nodeId, inputStaticId }))
      .then(() => {
        getInputFieldList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function deleteField(projectId, bpId, nodeId, field) {
    let fieldKeys = Object.keys(field);
    if (fieldKeys.includes('catalog')) {
      deleteInputCatalog(projectId, bpId, nodeId, field.id);
    }
    if (fieldKeys.includes('the_table')) {
      deleteInputTable(projectId, bpId, nodeId, field.id);
    }
    if (fieldKeys.includes('static')) {
      deleteInputStatic(projectId, bpId, nodeId, field.id);
    }
    if (fieldKeys.includes('field')) {
      deleteInputField(projectId, bpId, nodeId, field.id);
    }
  }

  function changeFormInputOrder(projectId, bpId, nodeId, fields, tables, catalogs, statics) {
    changeFormInputOrderRequest.value = {};
    const req = changeFormInputOrderRequest.value;
    req.isLoading = true;
    return axios
      .post(api.formInputOrder({ projectId, bpId, nodeId }), {
        fields,
        tables,
        catalogs,
        statics
      })
      .then(() => {
        getInputFieldList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function formInputChangeDirectionals(
    projectId,
    bpId,
    nodeId,
    nodeDirectionId,
    fields,
    tables,
    catalogs,
    statics
  ) {
    changeDirectionalsRequest.value = {};
    const req = changeDirectionalsRequest.value;
    req.isLoading = true;
    return axios
      .post(api.formInputDirectional({ projectId, bpId, nodeId, nodeDirectionId }), {
        fields,
        tables,
        catalogs,
        statics
      })
      .then(() => {
        getInputFieldList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function updateInputField(
    projectId,
    bpId,
    nodeId,
    title,
    type,
    order,
    required,
    settings = null,
    child_node_ids
  ) {
    updateInputFieldRequest.value = {};
    const req = updateInputFieldRequest.value;
    req.isLoading = true;
    const inputFieldId = inputField.value.id;
    return axios
      .post(api.inputFieldById({ projectId, bpId, nodeId, inputFieldId }), {
        title,
        type,
        required,
        settings,
        order,
        child_node_ids
      })
      .then(() => {
        getInputFieldList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function updateInputCatalog(
    projectId,
    bpId,
    nodeId,
    reg_table_id,
    reg_version,
    title,
    title_columns,
    required,
    child_node_ids
  ) {
    updateInputCatalogRequest.value = {};
    const req = updateInputCatalogRequest.value;
    req.isLoading = true;
    const inputCatalogId = inputField.value.id;

    return axios
      .post(api.inputCatalogById({ projectId, bpId, nodeId, inputCatalogId }), {
        reg_table_id,
        reg_version,
        title,
        title_columns,
        required,
        child_node_ids
      })
      .then(() => {
        getInputFieldList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function updateInputStatic(
    projectId,
    bpId,
    nodeId,
    inputStaticId,
    title,
    italic,
    bold,
    size,
    child_node_ids
  ) {
    updateInputStaticRequest.value = {};
    const req = updateInputStaticRequest.value;
    req.isLoading = true;
    const order = newInputFieldOrder.value;

    return axios
      .post(api.inputStaticById({ projectId, bpId, nodeId, inputStaticId }), {
        title,
        order,
        child_node_ids
      })
      .then(() => {
        getInputFieldList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function updateInputTable(projectId, bpId, nodeId, title, child_node_ids, required) {
    updateInputTableRequest.value = {};
    const req = updateInputTableRequest.value;
    req.isLoading = true;
    const inputTableId = inputField.value.id;

    return axios
      .post(api.inputTableById({ projectId, bpId, nodeId, inputTableId }), {
        title,
        child_node_ids,
        required
      })
      .then(() => {
        getInputFieldList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getInputTable(projectId, bpId, nodeId, inputTableId) {
    getInputTableRequest.value = {};
    const req = getInputTableRequest.value;
    req.isLoading = true;

    return axios
      .get(api.inputTableById({ projectId, bpId, nodeId, inputTableId }))
      .then((resp) => {
        inputField.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function createColumn(projectId, bpId, nodeId, inputTableId, title, type, required) {
    createColumnRequest.value = {};
    const req = createColumnRequest.value;
    req.isLoading = true;
    const order = inputField.value.table_columns.length - 1;

    return axios
      .post(api.column({ projectId, bpId, nodeId, inputTableId }), {
        title,
        type,
        order,
        required
      })
      .then(() => {
        getInputTable(projectId, bpId, nodeId, inputTableId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function updateColumn(projectId, bpId, nodeId, inputTableId, columnId, title, type, required) {
    updateColumnRequest.value = {};
    const req = updateColumnRequest.value;
    req.isLoading = true;

    return axios
      .post(api.columnById({ projectId, bpId, nodeId, inputTableId, columnId }), {
        title,
        type,
        required
      })
      .then(() => {
        getInputTable(projectId, bpId, nodeId, inputTableId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function deleteColumn(projectId, bpId, nodeId, inputTableId, columnId) {
    deleteColumnRequest.value = {};
    const req = deleteColumnRequest.value;
    req.isLoading = true;
    return axios
      .delete(api.columnById({ projectId, bpId, nodeId, inputTableId, columnId }))
      .then(() => {
        getInputTable(projectId, bpId, nodeId, inputTableId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function changeColumnOrder(projectId, bpId, nodeId, inputTableId, table_columns) {
    changeColumnOrderRequest.value = {};
    const req = changeColumnOrderRequest.value;
    req.isLoading = true;
    return axios
      .post(api.columnOrder({ projectId, bpId, nodeId, inputTableId }), {
        table_columns
      })
      .then(() => {
        getInputFieldList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function clearRequestVariables() {
    getInputFieldListRequest.value = {};
    createInputFieldRequest.value = {};
    createInputCatalogRequest.value = {};
    createInputTableRequest.value = {};
    createInputStaticRequest.value = {};
    deleteFieldRequest.value = {};
    getAncestorListRequest.value = {};
    changeFormInputOrderRequest.value = {};
    changeDirectionalsRequest.value = {};
    updateInputFieldRequest.value = {};
    updateInputCatalogRequest.value = {};
    updateInputTableRequest.value = {};
    createColumnRequest.value = {};
    updateColumnRequest.value = {};
    deleteColumnRequest.value = {};
    changeColumnOrderRequest.value = {};
    updateInputStaticRequest.value = {};
    getInputTableRequest.value = {};
  }

  return {
    getInputFieldList,
    createInputField,
    createInputCatalog,
    createInputTable,
    createInputStatic,
    deleteField,
    getAncestorList,
    changeFormInputOrder,
    formInputChangeDirectionals,
    updateInputField,
    updateInputCatalog,
    updateInputTable,
    updateColumn,
    deleteColumn,
    getInputTable,
    createColumn,
    changeColumnOrder,
    updateInputStatic,
    clearRequestVariables,
    inputFieldList,
    typeList,
    registryList,
    changeFormInputOrderRequest,
    nodeAncestorList,
    inputField,
    getInputFieldListRequest,
    createInputFieldRequest,
    createInputCatalogRequest,
    createInputTableRequest,
    createInputStaticRequest,
    deleteFieldRequest,
    getAncestorListRequest,
    changeDirectionalsRequest,
    updateInputFieldRequest,
    updateInputCatalogRequest,
    updateInputTableRequest,
    createColumnRequest,
    updateColumnRequest,
    deleteColumnRequest,
    changeColumnOrderRequest,
    updateInputStaticRequest,
    getInputTableRequest
  };
});
