<script setup lang="js">
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useBusinessProcessStore } from '@/stores/businessProcess.store';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';

const bpStore = useBusinessProcessStore();
const { closeModal } = useModal();
const { copyBPRequest } = storeToRefs(bpStore);

const name = ref('');
const description = ref('');

function copyBP() {
  bpStore.copyBP( name.value, description.value).then(() => closeModal());
}
function isErrorField(fieldName) {
  if (copyBPRequest.value.error?.errors) {
    return copyBPRequest.value.error.errors[fieldName] || false;
  }
}
function isNotFieldError() {
  if (!(copyBPRequest.value.error?.errors)) {
    return copyBPRequest.value.error;
  }
}
</script>

<template>
  <ModalTemplateSide @submit="copyBP">
    <template v-slot:title>Копировать бизнес-процесс</template>
    <template v-slot:body>
      <div class="mt-3">
        <label class="form-label">Системное наименование БП</label>
        <input type="text" v-model="name" class="form-control" :class="{'error': isErrorField('name')}" />
        <p v-if="isErrorField('name')" class="error-mesage">
          <span v-for="error in isErrorField('name')" :key="error">
            {{ error }}
          </span>
        </p>
      </div>
      <div class="mt-3">
        <label class="form-label">Описание БП</label>
        <textarea v-model="description" class="form-control" :class="{'error': isErrorField('description')}"></textarea>
        <p v-if="isErrorField('description')" class="error-mesage">
          <span v-for="error in isErrorField('description')" :key="error">
            {{ error }}
          </span>
        </p>
      </div>
      <p v-if="isNotFieldError()" class="error-mesage">
        {{ isNotFieldError() }}
      </p>
    </template>
    <template v-slot:submit>Копировать</template>
  </ModalTemplateSide>
</template>
