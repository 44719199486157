<script setup lang="js">
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';
import { useRouteParams } from '@vueuse/router';
import { useRoute } from 'vue-router';
import useModal from '@/stores/modal.store';
import ViewGeoCatalogCreateModal from '@/components/modals/ViewGeoCatalogCreateModal.vue';
import { useInputGeometryCatalogsStore } from '@/stores/inputGeometryCatalogs.store';
import { useProjectStore } from '@/stores/project.store';

const { activeModal, openModal, closeModal } = useModal();
const inputGeoCatalogStore = useInputGeometryCatalogsStore();
const projectStore = useProjectStore();
const { viewGeometryCatalogList, viewGeometryCatalogsChangeOrderRequest } = storeToRefs(inputGeoCatalogStore);
const { project } = storeToRefs(projectStore);
const projectId = useRouteParams('projectId');
const bpId = useRouteParams('bpId');
const nodeId = useRouteParams('nodeId');
const order = ref(false);
const originalGeoCatalogOrder = ref([]);
const selectedGeoCatalog = ref(null);

onMounted(() => {
  inputGeoCatalogStore.clearRequestVariables();
  if (!project.value.locales) {
    projectStore.getProject(projectId.value);
  }
  inputGeoCatalogStore.getViewGeometryCatalogsList(projectId.value, bpId.value, nodeId.value);
});

function getTitle(title) {
  return title[project.value.default_locale];
}
function toggleOrderState() {
  if (!order.value) {
    originalGeoCatalogOrder.value = [...viewGeometryCatalogList.value];
  }
  order.value = !order.value;
}
function selectForChangeOrder(index) {
  if (order.value) {
    if (selectedGeoCatalog.value === null) {
      selectedGeoCatalog.value = index;
    } else {
      const selectedItem = viewGeometryCatalogList.value[selectedGeoCatalog.value];
      viewGeometryCatalogList.value.splice(selectedGeoCatalog.value, 1);
      viewGeometryCatalogList.value.splice(index, 0, selectedItem);
      selectedGeoCatalog.value = null;

      viewGeometryCatalogList.value.forEach((geom, idx) => {
        geom.order = idx + 1;
      });
    }
  }
}
function saveOrderState() {
  order.value = false;
  const geoCatalogOrder = viewGeometryCatalogList.value.map((geom, index) => ({
    id: geom.id,
    order: index + 1
  }));

  inputGeoCatalogStore.viewGeometryCatalogsChangeOrder(
    projectId.value,
    bpId.value,
    nodeId.value,
    geoCatalogOrder
  );
}
function cancel() {
  viewGeometryCatalogList.value = [...originalGeoCatalogOrder.value];
  order.value = false;
}
function isChangeOrderError() {
  return viewGeometryCatalogsChangeOrderRequest.value.error;
}
</script>

<template>
  <div class="input-btns d-flex justify-content-between align-items-center">
    <span v-if="order">НАСТРОИТЬ ПОРЯДОК ГЕОМЕТРИИ</span>
    <div v-else class="add-btn" @click="openModal('viewGeoCatalogCreateModal')">
      <img src="@/assets/img/plus-ico.svg" alt="plus-ico.svg" />
      Добавить гео каталог
    </div>
    <div class="input-btns_order d-flex">
      <div v-if="order" class="d-flex">
        <div class="gray-btn me-3" @click="cancel()">Отмена</div>
        <div class="orange-btn" @click="saveOrderState">Сохранить изменения</div>
      </div>
      <div v-else class="order-btn" @click="toggleOrderState">Настроить порядок</div>
    </div>
  </div>
  <p v-if="isChangeOrderError()" class="error-mesage">
    {{ isChangeOrderError() }}
  </p>
  <div class="table-responsive pb-5">
    <table class="table gray-table">
      <thead>
        <tr>
          <th>Наименование геометрии</th>
          <th>Слой</th>
        </tr>
      </thead>
      <tbody>
        <tr
          :class="{
            'fixation-row-style': !viewGeoCatalog.stable,
            selectedDoc: viewGeoCatalog === index
          }"
          v-for="(viewGeoCatalog, index) in viewGeometryCatalogList"
          :key="viewGeoCatalog.id"
        >
          <td>
            <div class="d-flex align-items-center">
              <div v-if="!viewGeoCatalog.stable" class="fixation-circle-style"></div>
              <img
                v-if="order"
                src="@/assets/img/order-dots-ico.svg"
                alt="order-dots-ico.svg"
                @click="selectForChangeOrder(index)"
                :class="{ hidden: order && selectedGeoCatalog === index }"
              />
              <img
                v-if="order && selectedGeoCatalog === index"
                src="@/assets/img/order-dots-ico_hover.svg"
                alt="order-dots-ico_hover.svg"
              />
              <span class="ps-2 name">{{ getTitle(viewGeoCatalog.input_geo_catalog.geo_catalog.title) }}</span>
            </div>
          </td>
          <td>{{ getTitle(viewGeoCatalog.input_geo_catalog.geo_catalog?.layer?.title) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <ViewGeoCatalogCreateModal v-if="activeModal === 'viewGeoCatalogCreateModal'" />
</template>
