<script setup lang="js">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useInputGeometriesStore } from '@/stores/inputGeometries.store';
import { useLayerStore } from '@/stores/layer.store';
const geomStore = useInputGeometriesStore();
const layerStore = useLayerStore();

const { inputGeometryList, viewGeometryList } = storeToRefs(geomStore);
const { layersList } = storeToRefs(layerStore);
const route = useRoute();

const hasUnstableViewGeom = computed(() => {
  return viewGeometryList.value.some((viewGeom) => !viewGeom.stable);
});
const hasUnstableInputGeom = computed(() => {
  return inputGeometryList.value.some((inputGeom) => !inputGeom.stable);
});
const hasUnstableLayer = computed(() => {
  return layersList.value.some((layer) => !layer.stable);
});
</script>
<template>
  <div class="bp-section-content_links">
    <RouterLink
      :to="{
        name: 'geometry-fields',
        params: {
          projectId: route.params.projectId,
          bpId: route.params.bpId,
          nodeId: route.params.nodeId
        }
      }"
      class="bp-section-content_link"
      :class="{ 'input-fixation_circle': hasUnstableViewGeom || hasUnstableInputGeom }"
    >
      ГЕОМЕТРИЯ
    </RouterLink>
    <RouterLink
      :to="{
        name: 'geometry-catalogs',
        params: {
          projectId: route.params.projectId,
          bpId: route.params.bpId,
          nodeId: route.params.nodeId
        }
      }"
      class="bp-section-content_link"
      :class="{ 'input-fixation_circle': hasUnstableLayer }"
    >
      Гео справочник
    </RouterLink>
    <RouterLink
      :to="{
        name: 'geometry-layers',
        params: {
          projectId: route.params.projectId,
          bpId: route.params.bpId,
          nodeId: route.params.nodeId
        }
      }"
      class="bp-section-content_link"
      :class="{ 'input-fixation_circle': hasUnstableLayer }"
    >
      СЛОИ
    </RouterLink>
  </div>

  <router-view />
</template>
