<script setup lang="js">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useInputGeometriesStore } from '@/stores/inputGeometries.store';
const geomStore = useInputGeometriesStore();

const { inputGeometryList, viewGeometryList } = storeToRefs(geomStore);
const route = useRoute();

const hasUnstableViewGeom = computed(() => {
  return viewGeometryList.value.some((viewGeom) => !viewGeom.stable);
});
const hasUnstableInputGeom = computed(() => {
  return inputGeometryList.value.some((inputGeom) => !inputGeom.stable);
});
</script>

<template>
  <div class="bp-section-content_child-links">
    <div class="bp-section-content_child-link" :class="{ active: route.name == 'input-geometry' }">
      <RouterLink
        :to="{
          name: 'input-geometry',
          params: {
            projectId: route.params.projectId,
            bpId: route.params.bpId,
            nodeId: route.params.nodeId
          }
        }"
        :class="{ 'input-fixation_circle': hasUnstableInputGeom }"
      >
        Заполняемая
      </RouterLink>
    </div>
    <div class="bp-section-content_child-link">
      <RouterLink
        :to="{
          name: 'view-geometry',
          params: {
            projectId: route.params.projectId,
            bpId: route.params.bpId,
            nodeId: route.params.nodeId
          }
        }"
        :class="{
          'view-fixation_circle': hasUnstableViewGeom
        }"
      >
        Просматриваемая
      </RouterLink>
    </div>
  </div>
  <router-view />
</template>
