<script setup lang="js">
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';
import { useRouteParams } from '@vueuse/router';
import { useInputGeometriesStore } from '@/stores/inputGeometries.store';
import { useProjectStore } from '@/stores/project.store';

const { closeModal } = useModal();
const inputGeometriesStore = useInputGeometriesStore();
const projectStore = useProjectStore();
const projectId = useRouteParams('projectId');
const bpId = useRouteParams('bpId');
const bpNodeId = useRouteParams('nodeId');
const nodeOpenStates = ref({});
const checkedGeometry = ref({});
const selectAllChecked = ref(false);
const { project } = storeToRefs(projectStore);
const { ancestorNodesList, viewGeometryList, createViewGeometryRequest } = storeToRefs(inputGeometriesStore);

onMounted(() => {
  inputGeometriesStore
    .getAncestorNodesList(projectId.value, bpId.value, bpNodeId.value)
    .then(() => {
      if (viewGeometryList.value && viewGeometryList.value.length > 0) {
        viewGeometryList.value.forEach((geom) => {
          checkedGeometry.value[geom.input_geometry.id] = true;
        });
      }
    });
});

function toggleNode(bpNodeId) {
  nodeOpenStates.value[bpNodeId] = !nodeOpenStates.value[bpNodeId];
}
function toggleAll() {
  Object.keys(nodeOpenStates.value).forEach((key) => {
    nodeOpenStates.value[key] = false;
  });
}
function selectAll() {
  const newState = selectAllChecked.value;
  ancestorNodesList.value.forEach((ancestor) => {
    ancestor.input_geometries.forEach((geom) => {
      checkedGeometry.value[geom.id] = newState;
    });
  });
}
function getTitle(title) {
  return title[project.value.default_locale];
}
function create() {
  const creating_input_geom_ids = [];
  const removing_input_geom_ids = [];
  ancestorNodesList.value.forEach((ancestor) => {
    ancestor.input_geometries.forEach((geom) => {
      const wasSelected = geom.id in checkedGeometry.value;
      if (checkedGeometry.value[geom.id]) {
        const existsInViewDocuments = viewGeometryList.value.some(
          (viewGeom) => viewGeom.input_geometry.id === geom.id
        );
        if (!existsInViewDocuments) {
          creating_input_geom_ids.push(geom.id);
        }
      } else {
        if (wasSelected) {
          removing_input_geom_ids.push(geom.id);
        }
      }
    });
  });
  inputGeometriesStore
    .createViewGeometries(
      projectId.value,
      bpId.value,
      bpNodeId.value,
      creating_input_geom_ids,
      removing_input_geom_ids
    )
    .then(() => {
      closeModal();
    });
}
function isRequestError() {
  return createViewGeometryRequest.value.error;
}
</script>

<template>
  <ModalTemplateSide @submit="create">
    <template v-slot:title>Добавить геометрию для просмотра</template>
    <template v-slot:body>
      <p v-if="isRequestError()" class="error-mesage">
        {{ isRequestError() }}
      </p>
      <div class="d-flex justify-content-between">
        <div class="form-label d-flex align-items-center">
          <input type="checkbox" class="me-3 modal-checkbox" v-model="selectAllChecked" @change="selectAll" />Выделить
          все
        </div>
        <div class="form-label" @click="toggleAll">
          Свернуть все
          <img src="@/assets/img/minus-ico-with-border.svg" class="ms-3" />
        </div>
      </div>
      <div class="d-flex justify-content-between flex-column" v-for="ancestor in ancestorNodesList" :key="ancestor.id">
        <div class="modal-nodes d-flex justify-content-between">
          <span> {{ ancestor.current.node.name }}</span>
          <img src="@/assets/img/plus-ico-with-border.svg" @click="toggleNode(ancestor.current.id)"
            v-if="!nodeOpenStates[ancestor.current.id]" />
          <img src="@/assets/img/minus-ico-with-border.svg" @click="toggleNode(ancestor.current.id)"
            v-if="nodeOpenStates[ancestor.current.id]" />
        </div>
        <div v-if="nodeOpenStates[ancestor.current.id]" class="node-docs">
          <div class="d-flex justify-content-between">
            <span>Название геометрии</span>
            <span>Подпись</span>
          </div>
          <div v-for="inputGeom in ancestor.input_geometries" :key="inputGeom.id"
            class="node-doc-list d-flex align-items-start mt-3">
            <input type="checkbox" v-model="checkedGeometry[inputGeom.id]" class="me-1 modal-checkbox" />
            <span>{{ getTitle(inputGeom.geometry.title) }}</span>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:submit>Добавить</template>
  </ModalTemplateSide>
</template>
