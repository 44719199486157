<script setup lang="js">
import { useAutonodeStore } from '@/stores/autonode.store';
import { storeToRefs } from 'pinia';
import useModal from '@/stores/modal.store';
import DeleteModal from '@/components/modals/DeleteModal.vue';
import VariableCreateModal from '@/components/modals/Autonode/VariableCreateModal.vue';
import VariableUpdateModal from '@/components/modals/Autonode/VariableUpdateModal.vue';

const autonodeStore = useAutonodeStore();
const { activeModal } = useModal();
const { custom_variables, variableTypeList, selectedCustomVariableIndex } = storeToRefs(autonodeStore);

function openUpdateModal(itemIndex) {
  selectedCustomVariableIndex.value = itemIndex;
  activeModal.value = "VariableUpdateModal";
}

function deleteVariable(itemIndex) {
  custom_variables.value.splice(itemIndex, 1);
}

</script>

<template>
  <div class="autoform-variables">
    <div class="d-flex justify-content-between align-items-center input-btns">
      <div class="add-btn" @click="activeModal = 'VariableCreateModal'">
        <img src="@/assets/img/plus-ico.svg" alt="plus-ico.svg" />
        Создать переменную
      </div>
    </div>
    <div class="table-responsive">
      <table class="table gray-table">
        <thead>
          <tr>
            <th>Наименование</th>
            <th>Тип</th>
            <th>Значение по умолчанию</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(variable, index) in custom_variables" :key="index">
            <td>
              {{ variable.title }}
            </td>
            <td>
              {{ variableTypeList[variable.type] }}
            </td>
            <td>
              {{ variable.default_value }}
            </td>
            <td>
              <div class="table-buttons">
                <div class="table-button" v-if="variable.type != 'array'" @click="openUpdateModal(index)">
                  <span class="edit-ico" />
                </div>
                <div class="table-button" @click="deleteVariable(index)">
                  <span class="delite-ico" />
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <DeleteModal v-if="activeModal === 'deleteModal'" :itemText="'эту переменную'" @confirmDelete="deleteVariable()" />
  <VariableCreateModal v-if="activeModal === 'VariableCreateModal'" />
  <VariableUpdateModal v-if="activeModal === 'VariableUpdateModal'" />
</template>
