<script setup lang="js">
import { useAutonodeStore } from '@/stores/autonode.store';
import { storeToRefs } from 'pinia';
import useModal from '@/stores/modal.store';
import DeleteModal from '@/components/modals/DeleteModal.vue';
import OutputModal from '@/components/modals/Autonode/OutputModal.vue';

const autonodeStore = useAutonodeStore();
const { custom_variables, output_entities, selectedOutputIndex, outputVariableList } =
  storeToRefs(autonodeStore);
const { activeModal, closeModal } = useModal();

function getFieldName(entity, entity_id) {
  return (
    outputVariableList.value?.find((v) => v.name === `${entity}.${entity_id}`)?.title ||
    'Отсутствует переменная'
  );
}

function getVariableTitle(variableName) {
  let variable = custom_variables.value.find((v) => v.name == variableName);
  return variable.title || '';
}

function openUpdateModal(index) {
  selectedOutputIndex.value = index;
  activeModal.value = 'OutputModal';
}

function openDeleteModal(index) {
  selectedOutputIndex.value = index;
  activeModal.value = 'deleteModal';
}

function deleteOutput() {
  output_entities.value.splice(selectedOutputIndex.value, 1);
  closeModal();
}
</script>

<template>
  <div class="autoform-variables">
    <div class="d-flex justify-content-between align-items-center input-btns">
      <div class="add-btn" @click="activeModal = 'OutputModal'">
        <img src="@/assets/img/plus-ico.svg" alt="plus-ico.svg" />
        Добавить выходные данные
      </div>
    </div>
    <div class="table-responsive">
      <table class="table gray-table">
        <thead>
          <tr>
            <th>Переменная</th>
            <th>Поле (в исполняемой системе)</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(output_entity, index) in output_entities" :key="index">
            <td>
              {{ getVariableTitle(output_entity.variable) }}
            </td>
            <td>
              {{ getFieldName(output_entity.entity, output_entity.entity_id) }}
            </td>
            <td>
              <div class="table-buttons">
                <div class="table-button" @click="openUpdateModal(index)">
                  <span class="edit-ico" />
                </div>
                <div class="table-button" @click="openDeleteModal(index)">
                  <span class="delite-ico" />
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <DeleteModal
    v-if="activeModal === 'deleteModal'"
    :itemText="'эту строку'"
    @confirmDelete="deleteOutput()"
  />
  <OutputModal v-if="activeModal === 'OutputModal'" />
</template>
