<script setup lang="js">
import { onMounted, ref, watch } from 'vue';
import { useServiceStore } from '@/stores/service.store';
import { useProjectStore } from '@/stores/project.store';
import { useCriterionStore } from '@/stores/criterion.store';
import { useRouteParams } from '@vueuse/router';
import { storeToRefs } from 'pinia';
import useModal from '@/stores/modal.store';
import ServiceRouteCreateModal from '@/components/modals/ServiceRouteCreateModal.vue';
import ServiceRouteEditModal from '@/components/modals/ServiceRouteEditModal.vue';
import DeleteModal from '@/components/modals/DeleteModal.vue';
import Multiselect from 'vue-multiselect';

const serviceStore = useServiceStore();
const projectStore = useProjectStore();
const criterionStore = useCriterionStore();
const { project } = storeToRefs(projectStore);
const { optionList } = storeToRefs(criterionStore);
const serviceId = useRouteParams('serviceId');
const projectId = useRouteParams('projectId');
const { service, serviceRouteList, serviceCriterions } = storeToRefs(serviceStore);
const { activeModal, openModal, closeModal } = useModal();
const selectedOption = ref({});
const selectedRoute = ref(null);
const selectedRouteId = ref(null);

onMounted(() => {
  projectStore.getProject(projectId.value);
  serviceStore.getService(serviceId.value, projectId.value);
  serviceStore.getServiceRouteList(projectId.value, serviceId.value, selectedOption.value);
  serviceStore.getServiceCriterion(projectId.value, serviceId.value);
});
watch(
  () => serviceCriterions.value,
  () => {
    serviceCriterions.value.forEach((criterion) => {
      criterionStore.getOptionList(projectId.value, criterion.id);
    });
  }
);

function getOption(criterionId, options, locale) {
  let option = options.find((option) => option.criterion_id === criterionId);
  return option?.title[locale];
}

function getOptionsForSelect(criterionId) {
  return optionList.value[criterionId] || [];
}

function customLabel(option) {
  return option.title[project.value.default_locale];
}
function handleChange(criterionId) {
  serviceStore.getServiceRouteList(projectId.value, serviceId.value, [
    selectedOption.value[criterionId]?.id
  ]);
}
function handleClick(route, modal) {
  selectedRouteId.value = route.route_id;
  selectedRoute.value = route;
  activeModal.value = modal;
}
function deleteRoute() {
  serviceStore.deleteRoute(projectId.value, serviceId.value, selectedRouteId.value).then(() => {
    closeModal();
    serviceStore.getServiceRouteList(projectId.value, serviceId.value, selectedOption.value);
  });
}
</script>

<template>
  <div id="service-route-list-view">
    <div class="container-fluid">
      <div class="d-flex mt-3 page-header">
        <RouterLink :to="{ name: 'service-list' }" class="back-btn" />
        <h1 v-if="service.title">{{ service.title[project.default_locale] }}</h1>
      </div>
      <div class="service-route-content">
        <h5 class="pt-5 pb-4">Маршруты:</h5>
        <div @click="openModal('serviceRouteCreateModal')" class="orange-btn">
          <img src="@/assets/img/plus-ico.svg" alt="" /> Создать маршрут
        </div>
        <div v-if="serviceRouteList.length" class="table-responsive mt-4">
          <table class="table gray-table">
            <thead>
              <tr>
                <th>Бизнес-процесс</th>
                <th v-for="criterion in serviceCriterions" :key="criterion.id">
                  {{ criterion.title[project.default_locale] }}
                  <Multiselect
                    v-model="selectedOption[criterion.id]"
                    :options="getOptionsForSelect(criterion.id)"
                    placeholder="Выбрать..."
                    :multiple="false"
                    :custom-label="customLabel"
                    track-by="id"
                    class="mt-2"
                    @select="handleChange(criterion.id)"
                  />
                </th>
                <th class="th-buttons">Действие</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="route in serviceRouteList"
                :key="route.id"
                :class="{
                  'fixation-row-style': !route.stable
                }"
              >
                <td>{{ route.business_process.name }}</td>
                <td v-for="criterion in serviceCriterions" :key="criterion.id">
                  {{ getOption(criterion.id, route.route.options, project.default_locale) }}
                </td>

                <td class="table-buttons">
                  <div class="table-button" @click="handleClick(route, 'serviceRouteBPEditModal')">
                    <span class="edit-ico" />
                  </div>
                  <div class="table-button" @click="handleClick(route, 'deleteModal')">
                    <span class="delite-ico" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <ServiceRouteCreateModal v-if="activeModal === 'serviceRouteCreateModal'" />
    <ServiceRouteEditModal
      v-if="activeModal === 'serviceRouteBPEditModal'"
      :selectedRoute="selectedRoute"
    />
    <DeleteModal
      v-if="activeModal === 'deleteModal'"
      :itemText="'эту опцию'"
      :title="selectedRoute.business_process.name"
      @confirmDelete="deleteRoute()"
    />
  </div>
</template>
