<script setup lang="js">
import { onMounted, ref } from 'vue';
import { useVirtualMachineStore } from '@/stores/virtualMachine.store';
import { useRouteParams } from '@vueuse/router';
import { storeToRefs } from 'pinia';
import VirtualMachineDeployModal from '@/components/modals/VirtualMachineDeployModal.vue';
import useModal from '@/stores/modal.store';
import { useRoute } from 'vue-router';
import moment from 'moment';

const route = useRoute();

const virtualMachineStore = useVirtualMachineStore();
const projectId = useRouteParams('projectId');
const { activeModal, openModal } = useModal();
const { virtualMachineData, virtualMachine, deployStatus } = storeToRefs(virtualMachineStore);

onMounted(() => {
  virtualMachineStore.virtualMachineInfo(projectId.value, route.params.virtualMachineId);
  virtualMachineStore.getVirtualMachine(projectId.value, route.params.virtualMachineId);
});
function dateTime(value) {
  return value ? moment.utc(value).utcOffset(5).format('DD.MM.YYYY HH:mm') : '';
}
</script>

<template>
  <div class="container-fluid">
    <div class="d-flex mt-3 page-header">
        <RouterLink :to="{ name: 'virtual-machine-list' }" class="back-btn" />
        <h1 >{{ virtualMachine.domain}}</h1>
      </div>
    <div class="row">
      <div class="d-flex justify-content-end mb-4 flex-wrap">
        <div @click="openModal('VirtualMachineDeployModal')" class="orange-btn">Задеплоить</div>
      </div>
      <div class="table-responsive">
        <table class="table gray-table">
          <thead>
            <tr>
              <th>Дата</th>
              <th>Пользователь</th>
              <th>Статус</th>
              <th>Ошибка</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in virtualMachineData" :key="item.id">
              <td>
                {{ dateTime(item.created_at) || '' }}
              </td>
              <td>
                {{ item.user?.name }}
              </td>
              <td>
                {{ deployStatus[item.status] }}
              </td>
              <td>
                {{ item.error }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <VirtualMachineDeployModal v-if="activeModal === 'VirtualMachineDeployModal'" />
    </div>
  </div>
</template>
