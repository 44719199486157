import { inject, ref } from 'vue';
import { defineStore } from 'pinia';
import baseAxios from 'axios';

import { AXIOS } from '../const';

import * as api from '../router/endpoints';

export const useLocaleStore = defineStore('locale', () => {
  const axios = inject(AXIOS) || baseAxios;

  const locales = ref([]);

  const getLocalesRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  function getLocales() {
    getLocalesRequest.value = {};
    const req = getLocalesRequest.value;
    req.isLoading = true;

    return axios
      .get(api.locales())
      .then((resp) => {
        locales.value = resp.data;
      })
      .catch((e) => {
        req.error = 'onload_error';
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  return {
    locales,
    getLocales,
    getLocalesRequest
  };
});
