<script setup lang="js">
import { onMounted, ref, watch, onUnmounted } from 'vue';
import * as d3 from 'd3';
import { useAutonodeStore } from '@/stores/autonode.store';
import { storeToRefs } from 'pinia';
import _ from 'lodash';
import useModal from '@/stores/modal.store';
import DeleteModal from '@/components/modals/DeleteModal.vue';
import OperationCreateModal from '@/components/modals/Autonode/OperationCreateModal.vue';
import OperationUpdateModal from '@/components/modals/Autonode/OperationUpdateModal.vue';

const autonodeStore = useAutonodeStore();
const { instruction_steps, operationList, selectedInstructionStep, newUuid } =
  storeToRefs(autonodeStore);
const { activeModal, closeModal } = useModal();
const isOpenInfoModal = ref(false);

function openUpdateModal(item) {
  isOpenInfoModal.value = false;
  selectedInstructionStep.value = item;
  activeModal.value = 'OperationUpdateModal';
}

function openDeleteModal(item) {
  selectedInstructionStep.value = item;
  activeModal.value = 'deleteModal';
}

function removeWithChildren(step_id) {
  const step = instruction_steps.value.find(s => s.id === step_id);

  step.children.forEach(removeWithChildren);

  instruction_steps.value = instruction_steps.value.filter(s => s.id  !== step_id);
}

function deleteOperation() {
  const selected = selectedInstructionStep.value;
  const parent_id = selected.parent_id;
  const steps = instruction_steps.value;

  if (parent_id) {
    const parent = steps.find(s => s.id == parent_id);

    if (parent?.condition?.var && parent?.children?.length === 2) {
      parent.condition = null;
    }

    parent.children = _.without(parent.children, selected.id);
    removeWithChildren(selected.id);
  } else {
    instruction_steps.value = [];
  }
  drawGraph();
  closeModal();
}

function operationName(operationId) {
  const operation = operationList.value.find((op) => op.id == operationId);
  return operation?.title || '';
}

const svg = ref(null);
const menuContainer = ref(null);
const activeNodeMenuId = ref(null);
const stratify = d3
  .stratify()
  .id((d) => d.id)
  .parentId((d) => d.parent_id);

const cluster = d3
  .tree()
  .nodeSize([400, 150])
  .separation(() => 1.1);

const drawGraph = () => {
  if (!instruction_steps.value || !instruction_steps.value.length) {
    return;
  }
  const stratifyData = stratify(instruction_steps.value);
  const clusterData = cluster(stratifyData);

  svg.value.selectAll('*').remove();

  const containerWidth = svg.value.node().getBoundingClientRect().width;
  const containerHeight = svg.value.node().getBoundingClientRect().height;

  const initialTransform = d3.zoomIdentity.translate(containerWidth / 2, containerHeight / 4);

  const root = svg.value.append('g').attr('transform', initialTransform);

  let nodeHeight = 60;
  const padding = 20; // Padding around the text

  const measureTextWidth = (text) => {
    const tempText = svg.value
      .append('text')
      .attr('font-size', 18)
      .attr('visibility', 'hidden')
      .text(text);
    const width = tempText.node().getBBox().width;
    tempText.remove();
    return width;
  };

  const drawNode = (obj) => {
    const nameWidth = measureTextWidth(obj.data.id);
    let nodeWidth = Math.max(nameWidth) + padding * 2;
    const opName = operationName(obj.data.operation_id || null);
    if (opName) {
      const operationNameWidth = opName ? measureTextWidth(opName) : 0;
      nodeWidth = Math.max(nameWidth, operationNameWidth) + padding * 2;
    }

    const x = obj.x - nodeWidth / 2;
    const y = obj.y - nodeHeight / 2;

    const g = root
      .append('g')
      .attr('transform', `translate(${x}, ${y})`)
      .style('cursor', 'pointer')
      .on('click', () => {
        openUpdateModal(obj.data);
        menuContainer.value?.remove();
        menuContainer.value = null;
      });
    g.append('rect')
      .attr('rx', 5)
      .attr('ry', 5)
      .attr('width', nodeWidth)
      .attr('height', nodeHeight)
      .attr('x', 0)
      .attr('y', 0)
      .attr('fill', '#FEFEFE')
      .attr('stroke', '#DDD')
      .attr('stroke-width', 1);

    g.append('text').attr('x', padding).attr('y', 25).attr('font-size', 18).text(obj.data.id);

    if (opName) {
      g.append('text')
        .attr('x', padding)
        .attr('y', 45)
        .attr('font-size', 14)
        .attr('fill', '#888')
        .text(opName);
    }

    g.append('text')
      .attr('x', 0)
      .attr('y', 25)
      .attr('width', 20)
      .attr('font-size', 19)
      .attr('cursor', 'pointer')
      .attr('fill', '#AEAEAE')
      .text('⋮')
      .on('click', (event) => {
        event.stopPropagation();

        if (menuContainer.value) {
          if (activeNodeMenuId.value === obj.data.id) {
            menuContainer.value.remove();
            menuContainer.value = null;
            activeNodeMenuId.value = null;
            return;
          }
          menuContainer.value.remove();
          menuContainer.value = null;
          activeNodeMenuId.value = null;
        }

        activeNodeMenuId.value = obj.data.id;
        menuContainer.value = d3
          .select('body')
          .append('div')
          .attr('class', 'node-context-menu')
          .style('position', 'absolute')
          .style('left', `${event.pageX}px`)
          .style('top', `${event.pageY}px`);

        if (!obj.data.children?.length) {
          menuContainer.value
            .append('div')
            .attr('class', 'node-context-menu_item node-context-menu_item_plus-ico')
            .text('Создать дочерний шаг')
            .on('click', async () => {
              const uuid = await autonodeStore.generateUuid();
              instruction_steps.value
                .find((step) => {
                  return step.id == obj.data.id;
                })
                .children.push(uuid);
              instruction_steps.value.push({
                id: uuid,
                parent_id: obj.data.id,
                children: []
              });
              menuContainer.value?.remove();
              menuContainer.value = null;
            });
        }
        if (obj.data.parent_id) {
          menuContainer.value
            .append('div')
            .attr('class', 'node-context-menu_item node-context-menu_item_plus-ico')
            .text('Добавить предшествующий шаг')
            .on('click', async () => {
              const uuid = await autonodeStore.generateUuid();
              const current = instruction_steps.value.find(s => s.id == obj.data.id);
              const parent = instruction_steps.value.find(s => s.id == current.parent_id);

              if (parent.condition?.var && parent.children.length == 2) {
                _.pull(parent.children, obj.data.id);
                parent.children.push(uuid);
                if (parent.condition.true_step_id === obj.data.id) {
                  parent.condition.true_step_id = uuid;
                } else {
                  parent.condition.false_step_id = uuid;
                }
              } else {
                parent.children = [uuid];
              }
              current.parent_id = uuid;
              instruction_steps.value.push({
                id: uuid,
                parent_id: parent.id,
                children: [obj.data.id]
              });
              menuContainer.value?.remove();
              menuContainer.value = null;
            });
        }

        menuContainer.value
          .append('div')
          .attr('class', 'node-context-menu_item node-context-menu_item_delete-ico')
          .text('Удалить')
          .on('click', () => {
            openDeleteModal(obj.data);
            menuContainer.value?.remove();
            menuContainer.value = null;
          });

        d3.select('body').on(
          'click',
          () => {
            if (menuContainer.value) {
              menuContainer.value?.remove();
              menuContainer.value = null;
            }
          },
          { once: true }
        );
      });

    if (obj.children) {
      obj.children.forEach(drawNode);
    }
  };

  const drawLinks = () => {
    root
      .append('g')
      .selectAll('path')
      .data(clusterData.links())
      .enter()
      .append('path')
      .attr('fill', 'none')
      .attr('stroke', (d) => {
        const true_id = d.source.data?.condition?.true_step_id;
        const false_id = d.source.data?.condition?.false_step_id;
        if (d.target.id == true_id) return 'green';
        if (d.target.id == false_id) return 'red';
        return '#555';
      })
      .attr('stroke-opacity', 0.4)
      .attr('stroke-width', 1.5)
      .attr('marker-end', 'url(#arrowhead)')
      .attr('marker-start', 'url(#dot)')
      .attr('d', (d) => {
        const path = d3.path();
        const xSource = d.source.x;
        const ySource = d.source.y + 30;
        const xTarget = d.target.x;
        const yTarget = d.target.y - 30;

        path.moveTo(xSource, ySource);
        path.lineTo(xSource, ySource + 25);
        if (xSource > xTarget) {
          path.arc(xSource - 20, ySource + 25, 20, 0, Math.PI / 2);
          path.lineTo(xTarget + 20, yTarget - 45);
          path.arc(xTarget + 20, yTarget - 25, 20, -1.5, Math.PI, true);
        } else if (xSource < xTarget) {
          path.arc(xSource + 20, ySource + 25, 20, Math.PI, Math.PI / 2, true);
          path.lineTo(xTarget - 20, yTarget - 45);
          path.arc(xTarget - 20, yTarget - 25, 20, Math.PI * 1.5, Math.PI * 2);
        }

        path.lineTo(xTarget, yTarget);
        return path.toString();
      });
  };

  drawNode(clusterData);
  drawLinks();
};
watch(
  instruction_steps,
  () => {
    drawGraph();
  },
  { deep: true }
);
onMounted(() => {
  svg.value = d3.select('#steps');

  const zoom = d3.zoom().on('zoom', (event) => {
    svg.value.select('g').attr('transform', event.transform);
  });

  svg.value.call(zoom);

  const containerWidth = svg.value.node().getBoundingClientRect().width;
  const containerHeight = svg.value.node().getBoundingClientRect().height;
  const initialTransform = d3.zoomIdentity.translate(containerWidth / 2, containerHeight / 4);

  svg.value.call(zoom.transform, initialTransform);

  drawGraph();
});

onUnmounted(() => {
  svg.value.on('.zoom', null);
});
</script>

<template>
  <div v-if="!instruction_steps.length" class="px-3 mt-3">
    <div class="orange-btn" @click="activeModal = 'OperationCreateModal'">Создать операцию</div>
  </div>
  <svg id="steps" class="chart">
    <defs>
      <marker
        id="arrowhead"
        viewBox="0 0 10 10"
        refX="10"
        refY="5"
        markerWidth="5"
        markerHeight="5"
        orient="auto"
      >
        <path d="M10 5 0 10 0 0Z" fill="#aaa"></path>
      </marker>
      <marker
        id="dot"
        viewBox="0 0 10 10"
        refX="5"
        refY="5"
        markerWidth="4"
        markerHeight="4"
        orient="auto"
      >
        <circle cx="5" cy="5" r="5" fill="#aaa"></circle>
      </marker>
    </defs>
  </svg>

  <DeleteModal
    v-if="activeModal === 'deleteModal'"
    :itemText="'эту операцию'"
    @confirmDelete="deleteOperation()"
  />

  <OperationCreateModal v-if="activeModal === 'OperationCreateModal'" @createStep="drawGraph()" />
  <OperationUpdateModal
    v-if="activeModal === 'OperationUpdateModal'"
    @updateOperation="drawGraph()"
  />
</template>
