import { inject, ref } from 'vue';
import { defineStore } from 'pinia';
import baseAxios from 'axios';

import { AXIOS } from '../const';

import * as api from '../router/endpoints';

export const useLayerStore = defineStore('layers', () => {
  const axios = inject(AXIOS) || baseAxios;

  const layersList = ref([]);

  const getLayersListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const createLayerRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const deleteLayerRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  function getLayersList(projectId, bpId, nodeId) {
    getLayersListRequest.value = {};
    const req = getLayersListRequest.value;
    req.isLoading = true;

    return axios
      .get(api.layers({ projectId, bpId, nodeId }))
      .then((resp) => {
        layersList.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function createLayer(projectId, bpId, nodeId, reg_table_id, title) {
    createLayerRequest.value = {};
    const req = createLayerRequest.value;
    req.isLoading = true;

    return axios
      .post(api.layers({ projectId, bpId, nodeId }), {
        reg_table_id,
        title
      })
      .then(() => {
        getLayersList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function layerDelete(projectId, bpId, nodeId, layerId) {
    deleteLayerRequest.value = {};
    const req = deleteLayerRequest.value;
    req.isLoading = true;

    return axios
      .delete(api.layerById({ projectId, bpId, nodeId, layerId }))
      .then(() => {
        getLayersList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  return {
    layersList,
    getLayersListRequest,
    createLayerRequest,
    deleteLayerRequest,
    getLayersList,
    createLayer,
    layerDelete
  };
});
