import { inject, ref } from 'vue';
import { defineStore } from 'pinia';
import baseAxios from 'axios';

import { AXIOS } from '../const';

import * as api from '../router/endpoints';

export const useAutoformStore = defineStore('autoform', () => {
  const axios = inject(AXIOS) || baseAxios;

  const autoformList = ref([]);
  const autoform = ref({});
  const variableList = ref([]);
  const variableTypeList = ref({
    text: "text",
    double: 'double',
    boolean: 'boolean',
    identificator: 'identificator',
    date: 'date',
    line: 'line',
    point: 'point',
    polygon: 'polygon',
    geometry: 'geometry',
    document: 'document',
    array: 'array',
  });
  const outputVariableList = ref([]);
  const operationList = ref([]);
  const selectedCustomVariableIndex = ref(null);
  const selectedInstructionStep = ref(null);
  const name = ref(null);
  const instruction_steps = ref([]);
  const custom_variables = ref([]);
  const output_entities = ref([]);
  const selectedOutputIndex = ref(null);
  const newUuid = ref(null);

  const getAutoformListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const getVariableListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const getOutputVariableListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const getOperationListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const createAutoformRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const deleteAutoformRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const updateAutoformRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const generateUuidRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const getAutoformRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  function getAutoformList(projectId, bpId, nodeId) {
    getAutoformListRequest.value = {};
    const req = getAutoformListRequest.value;
    req.isLoading = true;
    return axios
      .get(api.autoforms({ projectId, bpId, nodeId }))
      .then((resp) => {
        autoformList.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getVariableList(projectId, bpId, nodeId) {
    getVariableListRequest.value = {};
    const req = getVariableListRequest.value;
    req.isLoading = true;
    return axios
      .get(api.autoformVariables({ projectId, bpId, nodeId }))
      .then((resp) => {
        variableList.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getOutputVariableList(projectId, bpId, nodeId) {
    getOutputVariableListRequest.value = {};
    const req = getOutputVariableListRequest.value;
    req.isLoading = true;

    return axios
      .get(api.autoformOutputVariables({ projectId, bpId, nodeId }))
      .then((resp) => {
        outputVariableList.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getOperationList(projectId, bpId, nodeId) {
    getOperationListRequest.value = {};
    const req = getOperationListRequest.value;
    req.isLoading = true;
    return axios
      .get(api.autoformOperations({ projectId, bpId, nodeId }))
      .then((resp) => {
        operationList.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function createAutoform(
    projectId,
    bpId,
    nodeId,
    name,
    instruction_steps,
    custom_variables,
    output_entities
  ) {
    createAutoformRequest.value = {};
    const req = createAutoformRequest.value;
    req.isLoading = true;

    return axios
      .post(api.autoforms({ projectId, bpId, nodeId }), {
        name,
        instruction_steps,
        custom_variables,
        output_entities
      })
      .then(() => {
        getAutoformList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function deleteAutoform(projectId, bpId, nodeId, autoformId) {
    deleteAutoformRequest.value = {};
    const req = deleteAutoformRequest.value;
    req.isLoading = true;

    return axios
      .delete(api.autoformById({ projectId, bpId, nodeId, autoformId }))
      .then(() => {
        getAutoformList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function updateAutoform(
    projectId,
    bpId,
    nodeId,
    autoformId,
    name,
    instruction_steps,
    custom_variables,
    output_entities
  ) {
    updateAutoformRequest.value = {};
    const req = updateAutoformRequest.value;
    req.isLoading = true;
    return axios
      .post(api.autoformById({ projectId, bpId, nodeId, autoformId }), {
        name,
        instruction_steps,
        custom_variables,
        output_entities
      })
      .then(() => {
        getAutoformList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getAutoform(
    projectId,
    bpId,
    nodeId,
    autoformId
  ) {
    getAutoformRequest.value = {};
    const req = getAutoformRequest.value;
    req.isLoading = true;
    return axios
      .get(api.autoformById({ projectId, bpId, nodeId, autoformId }))
      .then((resp) => {
        autoform.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function generateUuid() {
    generateUuidRequest.value = {};
    const req = generateUuidRequest.value;
    req.isLoading = true;
    return axios
      .get(api.uuid())
      .then((resp) => {
        newUuid.value = resp.data;
        return resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  return {
    getAutoformList,
    getOperationList,
    getVariableList,
    getOutputVariableList,
    createAutoform,
    updateAutoform,
    deleteAutoform,
    generateUuid,
    getAutoform,
    autoformList,
    operationList,
    variableList,
    variableTypeList,
    outputVariableList,
    selectedCustomVariableIndex,
    autoform,
    name,
    instruction_steps,
    custom_variables,
    output_entities,
    selectedInstructionStep,
    selectedOutputIndex,
    newUuid,
    getAutoformListRequest,
    getOperationListRequest,
    getVariableListRequest,
    getOutputVariableListRequest,
    createAutoformRequest,
    generateUuidRequest,
    updateAutoformRequest,
    getAutoformRequest,
    deleteAutoformRequest
  };
});
