import { inject, ref } from 'vue';
import { defineStore } from 'pinia';
import baseAxios from 'axios';

import { AXIOS } from '../const';

import * as api from '../router/endpoints';

export const useVirtualMachineStore = defineStore('virtualMachine', () => {
  const axios = inject(AXIOS) || baseAxios;

  const virtualMachine = ref({});
  const virtualMachineList = ref([]);
  const virtualMachineData = ref([]);
  const virtualMachineTypes = ref({
    test: 'Тест',
    prod: 'Прод'
  });
  const deployStatus = ref({
    success: 'Успешно',
    broken: 'Сломано',
    pending: 'В процессе'
  });

  const getVirtualMachineListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const getVirtualMachineRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const createVirtualMachineRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const updateVirtualMachineRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const deleteVirtualMachineRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const deployVirtualMachineRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const deployVirtualMachineInfoRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  function getVirtualMachineList(projectId) {
    getVirtualMachineListRequest.value = {};
    const req = getVirtualMachineListRequest.value;
    req.isLoading = true;
    virtualMachineList.value = [];
    return axios
      .get(api.virtualMachines({ projectId }))
      .then((resp) => {
        virtualMachineList.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function createVirtualMachine(
    projectId,
    ip,
    domain,
    type,
    user,
    password,
    env_file,
    composable_file
  ) {
    createVirtualMachineRequest.value = {};
    const req = createVirtualMachineRequest.value;
    req.isLoading = true;

    let fd = new FormData();
    fd.append('ip', ip);
    fd.append('domain', domain);
    fd.append('type', type);
    fd.append('user', user);
    fd.append('password', password);
    if (env_file) {
      fd.append('env_file', env_file);
    }
    if (composable_file) {
      fd.append('custom_compose_file', composable_file);
    }

    return axios
      .post(api.virtualMachines({ projectId }), fd)
      .then(() => {
        getVirtualMachineList(projectId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function updateVirtualMachine(
    projectId,
    virtualMachineId,
    ip,
    domain,
    type,
    user,
    password,
    env_file,
    composable_file
  ) {
    updateVirtualMachineRequest.value = {};
    const req = updateVirtualMachineRequest.value;
    req.isLoading = true;

    let fd = new FormData();
    fd.append('ip', ip);
    fd.append('domain', domain);
    fd.append('type', type);
    fd.append('user', user);
    fd.append('password', password);
    if (env_file) {
      fd.append('env_file', env_file);
    }
    if (composable_file) {
      fd.append('custom_compose_file', composable_file);
    }

    return axios
      .post(api.virtualMachineById({ projectId, virtualMachineId }), fd)
      .then(() => {
        getVirtualMachineList(projectId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function deleteVirtualMachine(projectId, virtualMachineId) {
    deleteVirtualMachineRequest.value = {};
    const req = deleteVirtualMachineRequest.value;
    req.isLoading = true;

    return axios
      .delete(api.virtualMachineById({ projectId, virtualMachineId }))
      .then(() => {
        getVirtualMachineList(projectId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getVirtualMachine(projectId, virtualMachineId) {
    getVirtualMachineRequest.value = {};
    const req = getVirtualMachineRequest.value;
    req.isLoading = true;
    virtualMachine.value = {};
    return axios
      .get(api.virtualMachineById({ projectId, virtualMachineId }))
      .then((resp) => {
        virtualMachine.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function deployVirtualMachine(projectId, virtualMachineId) {
    deployVirtualMachineRequest.value = {};
    const req = deployVirtualMachineRequest.value;
    req.isLoading = true;

    return axios
      .post(api.virtualMachineDeploy({ projectId, virtualMachineId }))
      .then(() => {})
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function virtualMachineInfo(projectId, virtualMachineId) {
    deployVirtualMachineInfoRequest.value = {};
    const req = deployVirtualMachineInfoRequest.value;
    req.isLoading = true;

    return axios
      .get(api.virtualMachineDeploy({ projectId, virtualMachineId }))
      .then((resp) => {
        virtualMachineData.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function clearRequestVariables() {
    deployVirtualMachineRequest.value = {};
    createVirtualMachineRequest.value = {};
    deleteVirtualMachineRequest.value = {};
    updateVirtualMachineRequest.value = {};
    getVirtualMachineRequest.value = {};
    getVirtualMachineListRequest.value = {};
  }

  return {
    virtualMachine,
    virtualMachineList,
    virtualMachineTypes,
    getVirtualMachineListRequest,
    getVirtualMachineRequest,
    createVirtualMachineRequest,
    deleteVirtualMachineRequest,
    updateVirtualMachineRequest,
    deployVirtualMachineRequest,
    deployVirtualMachineInfoRequest,
    virtualMachineData,
    deployStatus,
    getVirtualMachineList,
    deleteVirtualMachine,
    createVirtualMachine,
    updateVirtualMachine,
    deployVirtualMachine,
    clearRequestVariables,
    getVirtualMachine,
    virtualMachineInfo
  };
});
