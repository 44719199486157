<script setup lang="js">
import { onMounted, ref } from 'vue';
import { useRouteParams } from '@vueuse/router';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import useModal from '@/stores/modal.store';
import { useProjectStore } from '@/stores/project.store';
import InputDocCreateModal from '@/components/modals/InputDocCreateModal.vue';
import InputDocTemplatesModal from '@/components/modals/InputDocTemplatesModal.vue';
import InputDocEditModal from '@/components/modals/InputDocEditModal.vue';
import DeleteModal from '@/components/modals/DeleteModal.vue';
import { useInputDocumentsStore } from '@/stores/inputDocuments.store';
import { useNodeStore } from '@/stores/node.store';

const { activeModal, openModal, closeModal } = useModal();
const inputDocumentsStore = useInputDocumentsStore();
const projectStore = useProjectStore();
const projectId = useRouteParams('projectId');
const bpId = useRouteParams('bpId');
const nodeId = useRouteParams('nodeId');
const { inputDocumentsList, inputDocumentsChangeOrderRequest } = storeToRefs(inputDocumentsStore);
const isOrdering = ref(false);
const isOpenMenu = ref({});
const selectedDocument = ref(null);
const originalDocumentsOrder = ref([]);
const route = useRoute();
const nodeStore = useNodeStore();
const { bpNode } = storeToRefs(nodeStore);
const { project } = storeToRefs(projectStore);

onMounted(() => {
  inputDocumentsStore.clearRequestVariables();
  if (!project.value.locales) {
    projectStore.getProject(projectId.value);
  }
  inputDocumentsStore.getInputDocumentsList(projectId.value, bpId.value, nodeId.value);
  if (Object.keys(bpNode.value).length == 0) {
    nodeStore.getNode(projectId.value, bpId.value, nodeId.value);
  }
});

function openModalForInputDoc(modalName, doc) {
  if (modalName == "inputDocTemplatesModal") {
    inputDocumentsStore.getInputDoc(projectId.value, bpId.value, nodeId.value, doc.id).then(() => {
      activeModal.value = modalName;
      selectedDocument.value = doc;
    })
  } else {
    activeModal.value = modalName;
    selectedDocument.value = doc;
  }
}

function deleteDocument() {
  inputDocumentsStore.inputDocDelete(projectId.value, bpId.value, nodeId.value, selectedDocument.value.id);
  closeModal();
}
function toggleOrderState() {
  if (!isOrdering.value) {
    originalDocumentsOrder.value = [...inputDocumentsList.value];
  }
  isOrdering.value = !isOrdering.value;
}
function saveOrderState() {
  isOrdering.value = false;
  const documentsOrder = inputDocumentsList.value.map((doc, index) => ({
    id: doc.id,
    order: index + 1
  }));

  inputDocumentsStore.inputDocumentsChangeOrder(
    projectId.value,
    bpId.value,
    nodeId.value,
    documentsOrder
  );
}
function cancel() {
  inputDocumentsList.value = [...originalDocumentsOrder.value];
  isOrdering.value = false;
}
function toggleMenu(id) {
  for (const key in isOpenMenu.value) {
    if (key !== id.toString()) {
      isOpenMenu.value[key] = false;
    }
  }
  isOpenMenu.value[id] = !isOpenMenu.value[id];
}

function selectForChangeOrder(index) {
  if (isOrdering.value) {
    if (selectedDocument.value === null) {
      selectedDocument.value = index;
    } else {
      const selectedItem = inputDocumentsList.value[selectedDocument.value];
      inputDocumentsList.value.splice(selectedDocument.value, 1);
      inputDocumentsList.value.splice(index, 0, selectedItem);
      selectedDocument.value = null;

      inputDocumentsList.value.forEach((doc, idx) => {
        doc.order = idx + 1;
      });
    }
  }
}
function getTitle(doc) {
  return doc?.title[project.value.default_locale];
}
function isChangeOrderError() {
  return inputDocumentsChangeOrderRequest.value.error;
}
</script>
<template>
  <div v-if="route.name == 'input-documents'" class="docs-view">
    <div class="d-flex justify-content-between align-items-center input-btns">
      <span v-if="isOrdering">НАСТРОИТЬ ПОРЯДОК ДОКУМЕНТОВ</span>
      <div v-else class="add-btn" @click="openModal('addDocForInputModal')">
        <img src="@/assets/img/plus-ico.svg" alt="plus-ico.svg" />
        Добавить документ
      </div>

      <div class="input-btns_order d-flex">
        <RouterLink
          :to="{
            name: 'input-documents-child',
            params: {
              projectId: projectId,
              bpId: bpId,
              nodeId: nodeId
            }
          }"
          v-if="!isOrdering"
          class="order-btn me-4"
        >
          Дочерние узлы
        </RouterLink>

        <div v-if="isOrdering" class="d-flex">
          <div class="gray-btn me-3" @click="cancel()">Отмена</div>
          <div class="orange-btn" @click="saveOrderState">Сохранить изменения</div>
        </div>

        <div v-else class="order-btn" @click="toggleOrderState">Настроить порядок</div>
      </div>
    </div>
    <p v-if="isChangeOrderError()" class="error-mesage">
      {{ isChangeOrderError() }}
    </p>
    <div class="table-responsive pb-5">
      <table class="table gray-table">
        <thead>
          <tr>
            <th class="w-50">Наименование документа</th>
            <th>Генерируемый</th>
            <th>Подпись</th>
            <th class="w-5">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(inputDocument, index) in inputDocumentsList"
            :key="inputDocument.id"
            :class="{'fixation-row-style': !inputDocument.stable, selectedDoc: selectedDocument === index }"
          >
            <td>
              <div class="d-flex align-items-center">
              <div v-if="!inputDocument.stable" class="fixation-circle-style"></div>
              <img
                v-if="isOrdering"
                src="@/assets/img/order-dots-ico.svg"
                alt="order-dots-ico.svg"
                @click="selectForChangeOrder(index)"
                :class="{ hidden: isOrdering && selectedDocument === index }"
              />
              <img
                v-if="isOrdering && selectedDocument === index"
                src="@/assets/img/order-dots-ico_hover.svg"
                alt="order-dots-ico_hover.svg"
              />
              <span class="ps-2 name">{{ getTitle(inputDocument.document) }}</span>
            </div>
            </td>
            <td v-if="inputDocument?.templates?.length === 0">Загружаемый</td>
            <td v-else>Генерируемый</td>
            <td>
              {{ inputDocument.signable ? 'Да' : 'Нет' }}
            </td>
            <td class="imgs" @click="toggleMenu(inputDocument.id)">
              <img src="@/assets/img/context-menu-ico.svg" alt="context-menu-ico.svg" class="img" />
              <img
                src="@/assets/img/context-menu-ico-hover.svg"
                alt="context-menu-ico.svg"
                class="img-hover"
              />
              <div v-if="isOpenMenu[inputDocument.id]" class="menu">
                <div
                  class="mb-2"
                  @click="openModalForInputDoc('inputDocTemplatesModal', inputDocument)"
                >
                  <img
                    src="@/assets/img/template-ico.svg"
                    alt="template-ico.svg"
                    class="pe-2"
                  />Шаблоны
                </div>
                <div class="mb-2" @click="openModalForInputDoc('inputDocEditModal', inputDocument)">
                  <img
                    src="@/assets/img/edit-ico.svg"
                    alt="edit-ico.svg"
                    class="pe-2"
                  />Редактировать
                </div>
                <div @click="openModalForInputDoc('deleteModal', inputDocument)">
                  <img
                    src="@/assets/img/context-menu_delete-ico.svg"
                    alt="context-menu_delete-ico"
                    class="pe-2"
                  />Удалить
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <InputDocCreateModal v-if="activeModal === 'addDocForInputModal'" />
    <InputDocTemplatesModal
      v-if="activeModal === 'inputDocTemplatesModal'"
      :selectedDocument="selectedDocument"
    />
    <InputDocEditModal
      v-if="activeModal === 'inputDocEditModal'"
      :selectedDocument="selectedDocument"
    />
    <DeleteModal
      v-if="activeModal === 'deleteModal'"
      :itemText="'этот документ'"
      :title="getTitle(selectedDocument.document)"
      @confirmDelete="deleteDocument()"
    />
  </div>
  <router-view />
</template>
