<script setup lang="js">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useInputFieldStore } from '@/stores/inputField.store';
import { useViewFieldStore } from '@/stores/viewField.store';
const inputFieldStore = useInputFieldStore();
const viewFieldStore = useViewFieldStore();

const { inputFieldList } = storeToRefs(inputFieldStore);
const { viewFieldList } = storeToRefs(viewFieldStore);
const route = useRoute();

const hasUnstableViewField = computed(() => {
  return viewFieldList.value.some(viewField => !viewField.stable);
});
const hasUnstableInputField = computed(() => {
  return inputFieldList.value.some(inputField => !inputField.stable);
});
</script>
<template>
  <div class="bp-section-content_links">
    <RouterLink
      :to="{
        name: 'input-fields',
        params: {
          projectId: route.params.projectId,
          bpId: route.params.bpId,
          nodeId: route.params.nodeId
        }
      }"
      class="bp-section-content_link"
      :class="{'input-fixation_circle': hasUnstableInputField}"
    >
      Заполняемые
    
    </RouterLink>
    <RouterLink
      :to="{
        name: 'view-fields',
        params: {
          projectId: route.params.projectId,
          bpId: route.params.bpId,
          nodeId: route.params.nodeId
        }
      }"
      class="bp-section-content_link"
      :class="{'view-fixation_circle': hasUnstableViewField}"
    >
      Просматриваемые
     
    </RouterLink>
  </div>
  <router-view />
</template>
