<script setup lang="js">
import { onMounted, ref, watch } from 'vue';
import { useRegistryStore } from '@/stores/registry.store';
import { useRouteParams } from '@vueuse/router';
import CreateTableModal from '@/components/modals/Registry/CreateTableModal.vue';
import DeleteTableModal from '@/components/modals/Registry/DeleteTableModal.vue';
import CreateColumnModal from '@/components/modals/Registry/CreateColumnModal.vue';
import UpdateColumnModal from '@/components/modals/Registry/UpdateColumnModal.vue';
import DeleteColumnModal from '@/components/modals/Registry/DeleteColumnModal.vue';
import CreateRefModal from '@/components/modals/Registry/CreateRefModal.vue';
import DeleteRefModal from '@/components/modals/Registry/DeleteRefModal.vue';
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';

const projectId = useRouteParams('projectId');
const diagramWrapper = ref(null);
const registryStore = useRegistryStore();
const { getRegTableList, initGraph, fixateRegistries, searchBox, searchNext } = useRegistryStore();
const { fixateRequest, searchText, searchGenerator } = storeToRefs(registryStore);
const { activeModal, openModal } = useModal();

function getData() {
  getRegTableList(projectId.value).then(() => {
    initGraph(diagramWrapper.value);
  });
}

function fixate() {
  fixateRegistries(projectId.value);
}

function isHasError() {
  if (!fixateRequest.value.error?.errors) {
    return fixateRequest.value.error;
  }
}
function clearSearchValue() {
  searchText.value = null;
  searchGenerator.value = null;
}
onMounted(() => {
  getData();
});
</script>

<template>
  <div class="container-fluid registry-page">
    <p v-if="isHasError()" class="error-mesage">
      {{ isHasError() }}
    </p>
    <div class="d-flex mb-4">
      <div class="search-block registry-search">
        <input type="text" v-model="searchText">
        <button @click="clearSearchValue" v-if="searchText" class="registry-search_clear-btn">×</button>
        <button v-if="!searchGenerator || !searchText" @click="searchBox(searchText)">Поиск</button>
        <button v-else @click="searchNext">
          Следующий
        </button>
      </div>
    </div>
    <div class="registry-buttons d-flex justify-content-between">
      <div class="d-flex">
        <div class="d-flex">
          <div @click="openModal('RegistryCreateTableModal')" class="orange-border-btn d-flex align-items-center">
            <span class="icon-btn plus-icon"></span>
            Создать таблицу
          </div>
          <div @click="openModal('RegistryDeleteTableModal')" class="gray-border-btn d-flex align-items-center">
            <span class="icon-btn delete-icon"></span> Удалить таблицу
          </div>
        </div>
        <div class="d-flex">
          <div @click="openModal('RegistryCreateColumnModal')" class="orange-border-btn d-flex align-items-center">
            <span class="icon-btn plus-icon"></span> Добавить колонку
          </div>
          <!-- 
      <div @click="openModal('RegistryUpdateColumnModal')" class="orange-btn">
        Редактировать колонку
      </div> -->
          <div @click="openModal('RegistryDeleteColumnModal')" class="gray-border-btn d-flex align-items-center">
            <span class="icon-btn delete-icon"></span> Удалить колонку
          </div>
        </div>
        <div class="d-flex">
          <div @click="openModal('RegistryCreateRefModal')" class="orange-border-btn d-flex align-items-center">
            <span class="icon-btn plus-icon"></span>
            Создать связь
          </div>
          <div @click="openModal('RegistryDeleteRefModal')" class="gray-border-btn no-after d-flex align-items-center">
            <span class="icon-btn delete-icon"></span> Удалить связь
          </div>
        </div>
      </div>
      <div @click="fixate()" class="btn-default black-btn d-flex align-items-center">
        Зафиксировать
      </div>
    </div>
    <div class="registry-block">
      <div ref="diagramWrapper" class="wrapper"></div>
    </div>
    <CreateTableModal v-if="activeModal === 'RegistryCreateTableModal'" @save="getData()" />
    <DeleteTableModal v-if="activeModal === 'RegistryDeleteTableModal'" @save="getData()" />
    <CreateColumnModal v-if="activeModal === 'RegistryCreateColumnModal'" @save="getData()" />
    <UpdateColumnModal v-if="activeModal === 'RegistryUpdateColumnModal'" @save="getData()" />
    <DeleteColumnModal v-if="activeModal === 'RegistryDeleteColumnModal'" @save="getData()" />
    <CreateRefModal v-if="activeModal === 'RegistryCreateRefModal'" @save="getData()" />
    <DeleteRefModal v-if="activeModal === 'RegistryDeleteRefModal'" @save="getData()" />
  </div>
</template>