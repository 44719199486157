import { inject, ref } from 'vue';
import { defineStore } from 'pinia';
import baseAxios from 'axios';

import { AXIOS } from '../const';

import * as api from '../router/endpoints';

export const useInputGeometryCatalogsStore = defineStore('input_geometry_catalogs', () => {
  const axios = inject(AXIOS) || baseAxios;

  const inputGeometryCatalogList = ref([]);
  const ancestorNodesList = ref([]);
  const viewGeometryCatalogList = ref([]);

  const getInputGeometryCatalogListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const createInputGeometryCatalogRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const inputGeometryCatalogEditRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const inputGeometryCatalogDeleteRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const getAncestorNodesListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const inputGeometryCatalogChangeOrderRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const inputGeometryCatalogChangeDirectionalRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const getViewGeometryCatalogListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const createViewGeometryCatalogRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const viewGeometryCatalogsChangeOrderRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  function getInputGeometryCatalogList(projectId, bpId, nodeId) {
    getInputGeometryCatalogListRequest.value = {};
    const req = getInputGeometryCatalogListRequest.value;
    req.isLoading = true;

    return axios
      .get(api.inputGeometryCatalogs({ projectId, bpId, nodeId }))
      .then((resp) => {
        inputGeometryCatalogList.value = resp.data.sort((a, b) => a.order - b.order);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function createInputGeometryCatalog(
    projectId,
    bpId,
    nodeId,
    layer_id,
    title,
    required,
    order,
    child_node_ids
  ) {
    createInputGeometryCatalogRequest.value = {};
    const req = createInputGeometryCatalogRequest.value;
    req.isLoading = true;

    return axios
      .post(api.inputGeometryCatalogs({ projectId, bpId, nodeId }), {
        layer_id,
        title,
        required,
        order,
        child_node_ids
      })
      .then(() => {
        getInputGeometryCatalogList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function inputGeomCatalogUpdate(
    projectId,
    bpId,
    nodeId,
    inputGeomCatalogId,
    layer_id,
    title,
    required,
  ) {
    inputGeometryCatalogEditRequest.value = {};
    const req = inputGeometryCatalogEditRequest.value;
    req.isLoading = true;

    return axios
      .post(api.inputGeometryCatalogById({ projectId, bpId, nodeId, inputGeomCatalogId }), {
        layer_id,
        title,
        required
      })
      .then(() => {
        getInputGeometryCatalogList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }
  function inputGeomCatalogDelete(projectId, bpId, nodeId, inputGeomCatalogId) {
    inputGeometryCatalogDeleteRequest.value = {};
    const req = inputGeometryCatalogDeleteRequest.value;
    req.isLoading = true;

    return axios
      .delete(api.inputGeometryCatalogById({ projectId, bpId, nodeId, inputGeomCatalogId }))
      .then(() => {
        getInputGeometryCatalogList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }
  function getAncestorNodesList(projectId, bpId, nodeId) {
    getAncestorNodesListRequest.value = {};
    const req = getAncestorNodesListRequest.value;
    req.isLoading = true;

    return axios
      .get(api.inputGeometryCatalogAncestors({ projectId, bpId, nodeId }))
      .then((resp) => {
        ancestorNodesList.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function inputGeometryCatalogChangeOrder(projectId, bpId, nodeId, geo_catalogs) {
    inputGeometryCatalogChangeOrderRequest.value = {};
    const req = inputGeometryCatalogChangeOrderRequest.value;
    req.isLoading = true;

    return axios
      .post(api.inputGeometryCatalogOrder({ projectId, bpId, nodeId }), { geo_catalogs })
      .then(() => {
        getInputGeometryCatalogList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function changeDirectionalGeometryCatalogs(projectId, bpId, nodeId, directionId, creating_ids, removing_ids) {
    inputGeometryCatalogChangeDirectionalRequest.value = {};
    const req = inputGeometryCatalogChangeDirectionalRequest.value;
    req.isLoading = true;

    return axios
      .post(api.inputGeometryCatalogDirectional({ projectId, bpId, nodeId, directionId }), {
        creating_ids,
        removing_ids
      })
      .then(() => {
        getInputGeometryCatalogList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getViewGeometryCatalogsList(projectId, bpId, nodeId) {
    getViewGeometryCatalogListRequest.value = {};
    const req = getViewGeometryCatalogListRequest.value;
    req.isLoading = true;

    return axios
      .get(api.viewGeometryCatalogs({ projectId, bpId, nodeId }))
      .then((resp) => {
        viewGeometryCatalogList.value = resp.data.sort((a, b) => a.order - b.order);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function createViewGeometryCatalogs(
    projectId,
    bpId,
    nodeId,
    creating_input_geo_catalog_ids,
    removing_input_geo_catalog_ids
  ) {
    createViewGeometryCatalogRequest.value = {};
    const req = createViewGeometryCatalogRequest.value;
    req.isLoading = true;

    return axios
      .post(api.viewGeometryCatalogs({ projectId, bpId, nodeId }), {
        creating_input_geo_catalog_ids,
        removing_input_geo_catalog_ids
      })
      .then(() => {
        getViewGeometryCatalogsList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function viewGeometryCatalogsChangeOrder(projectId, bpId, nodeId, geo_catalogs) {
    viewGeometryCatalogsChangeOrderRequest.value = {};
    const req = viewGeometryCatalogsChangeOrderRequest.value;
    req.isLoading = true;

    return axios
      .post(api.viewGeometryCatalogsOrder({ projectId, bpId, nodeId }), { geo_catalogs })
      .then(() => {
        getViewGeometryCatalogsList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function clearRequestVariables() {
    viewGeometryCatalogsChangeOrderRequest.value = {};
    createViewGeometryCatalogRequest.value = {};
    getViewGeometryCatalogListRequest.value = {};
    inputGeometryCatalogChangeDirectionalRequest.value = {};
    inputGeometryCatalogChangeOrderRequest.value = {};
    getAncestorNodesListRequest.value = {};
    inputGeometryCatalogDeleteRequest.value = {};
    inputGeometryCatalogEditRequest.value = {};
    createInputGeometryCatalogRequest.value = {};
    getInputGeometryCatalogListRequest.value = {};
  }

  return {
    inputGeometryCatalogList,
    ancestorNodesList,
    viewGeometryCatalogList,
    viewGeometryCatalogsChangeOrderRequest,
    createViewGeometryCatalogRequest,
    getViewGeometryCatalogListRequest,
    inputGeometryCatalogChangeDirectionalRequest,
    inputGeometryCatalogChangeOrderRequest,
    getAncestorNodesListRequest,
    inputGeometryCatalogDeleteRequest,
    inputGeometryCatalogEditRequest,
    createInputGeometryCatalogRequest,
    getInputGeometryCatalogListRequest,
    getInputGeometryCatalogList,
    createInputGeometryCatalog,
    inputGeomCatalogUpdate,
    inputGeomCatalogDelete,
    getAncestorNodesList,
    inputGeometryCatalogChangeOrder,
    changeDirectionalGeometryCatalogs,
    getViewGeometryCatalogsList,
    createViewGeometryCatalogs,
    viewGeometryCatalogsChangeOrder,
    clearRequestVariables
  };
});
