<script setup lang="js">
import { onMounted, ref, watch, onUnmounted, computed } from 'vue';
import * as d3 from 'd3';
import * as d3dag from 'd3-dag';
import { storeToRefs } from 'pinia';
import { useNodeStore } from '@/stores/node.store';
import { useRoute, useRouter } from 'vue-router';
import _ from 'lodash';
import { useElementSize } from '@vueuse/core';
import applicantIcon from '@/assets/img/node_applicant.svg?raw';
import employeeIcon from '@/assets/img/node_employee.svg?raw';
import autonodeIcon from '@/assets/img/node_autonode.svg?raw';
import waitingAutonodeIcon from '@/assets/img/node_waiting_autonode.svg?raw';
import contextMenuIcon from '@/assets/img/context-menu-ico.svg?raw';
import applicantIconYellow from '@/assets/img/node_applicant_yellow.svg?raw';
import employeeIconYellow from '@/assets/img/node_employee_yellow.svg?raw';
import autonodeIconYellow from '@/assets/img/node_autonode_yellow.svg?raw';
import waitingAutonodeIconYellow from '@/assets/img/node_waiting_autonode_yellow.svg?raw';
import applicantIconWhite from '@/assets/img/node_applicant_white.svg?raw';
import employeeIconWhite from '@/assets/img/node_employee_white.svg?raw';
import autonodeIconWhite from '@/assets/img/node_autonode_white.svg?raw';
import waitingAutonodeIconWhite from '@/assets/img/node_waiting_autonode_white.svg?raw';

const route = useRoute();
const router = useRouter();
const emit = defineEmits(['createNodeChild', 'updateNode', 'deleteNodeRef', 'addNodeRef']);

const nodeStore = useNodeStore();
const { nodeList, bpNode, nodeRefData } = storeToRefs(nodeStore);
const svgElement = ref(null);
const { width: ctnWidth, height: ctnHeight } = useElementSize(svgElement);
const showContextMenu = ref(false);
const connectingActivated = ref(false);

const svg = ref(null);
const nodeWidth = ref(200);
const nodeHeight = ref(30);
const zoom = ref(d3.zoom().on('zoom', zoomHandler));

const graphGap = ref(500);

const nodeSize = computed(() => [nodeWidth.value * 2, nodeHeight.value * 2]);
const graphNodeList = computed(() => {
  let disabledNodes = [];
  if (connectingActivated.value) {
    disabledNodes = nodeStore.getNodeAncestors();
  }

  return nodeList.value.map((node) => ({
    ...node,
    id: `${node.id}`,
    parentIds: node.parent_nodes.map((n) => `${n.id}`),
    disabled: disabledNodes.includes(`${node.id}`)
  }));
});

function zoomHandler({ transform }) {
  svg.value.select('#nodesGroup').attr('transform', transform);
}

//Разбивка строк по максимальной ширине
function splitTextIntoLines(text = '', maxWidth) {
  const words = text.split(' ');
  const lines = [];
  let currentLine = 0;

  while (words.length) {
    const word = words.shift();
    const newLine = `${lines[currentLine] || ''} ${word}`;

    if (newLine.length * 10 <= maxWidth) {
      lines[currentLine] = newLine;
      continue;
    }

    lines[++currentLine] = word;
  }

  return lines.map((l) => l.trim());
}

const drawGraph = ({ fit } = { fit: false }) => {
  d3.selectAll('#defs, #links, #nodes, #arrows').html('');

  //подготовка форм узлов и связей
  const shape = d3dag.tweakShape(nodeSize.value, d3dag.shapeRect);
  const line = d3.line().curve(d3.curveMonotoneY);

  //преобразования данных для графа
  const builder = d3dag.graphStratify();
  const graph = builder(graphNodeList.value);

  //схема отрисовки
  const layout = d3dag
    .sugiyama()
    .layering(d3dag.layeringLongestPath())
    .nodeSize(nodeSize.value)
    .gap([nodeWidth.value, nodeHeight.value * 5])
    .tweaks([shape]);

  const { width, height } = layout(graph);

  const minScale = Math.min(1, 0.98 / Math.max(width / ctnWidth.value, height / ctnHeight.value));

  //задание ограничений перемещения и приближения
  zoom.value
    .translateExtent([
      [-graphGap.value, -graphGap.value],
      [width + graphGap.value, height + graphGap.value]
    ])
    .scaleExtent([minScale, 2]);

  svg.value = d3.select('#svg');
  const nodeGroup = svg.value.select('#nodesGroup');

  svg.value.call(zoom.value);

  //масштабирование и центрирование без активного узла
  if (!route.params.nodeId && fit) {
    svg.value.call(
      zoom.value.transform,
      d3.zoomIdentity
        .translate(
          (ctnWidth.value - width * minScale) / 2,
          (ctnHeight.value - height * minScale) / 2
        )
        .scale(minScale)
    );
  }

  //отрисовка узлов
  nodeGroup
    .select('#nodes')
    .selectAll('g')
    .data(graph.nodes())
    .join((enter) =>
      enter
        .append('g')
        .classed('graph-node__not-fixation', (d) => !d.data.current.stable)
        .classed('graph-node', true)
        .classed('graph-node__disabled', (d) => d.data.disabled)
        .classed('graph-node__one', (d) => d.data.current?.node.direction_type == 'one')
        .classed('graph-node__all', (d) => d.data.current?.node.direction_type == 'all')
        .classed('graph-node__active', (d) => {
          //масштабирование и центрирование активного узла
          if (d.data.id === route.params.nodeId && fit) {
            const del = nodeStore.isShowSidebar ? 3 : 2;
            const transform = d3.zoomIdentity
              .scale(1)
              .translate(ctnWidth.value / del - d.ux, ctnHeight.value / del - d.uy);
            svg.value.transition().duration(500).call(zoom.value.transform, transform);
          }
          return d.data.id == route.params.nodeId;
        })
        .attr('transform', ({ x, y }) => `translate(${x}, ${y})`)
        .call((enter) => {
          const iconX = 15 - nodeWidth.value;
          const iconY = 15 - nodeHeight.value;
          //фон и рамка узла
          enter
            .append('rect')
            .attr('rx', 5)
            .attr('ry', 5)
            .attr('fill', 'white')
            .attr('transform', `translate(${-nodeWidth.value}, ${-nodeHeight.value})`)
            .attr('width', nodeWidth.value * 2)
            .attr('height', nodeHeight.value * 2);
          //иконка узла
          enter
            .append('g')
            .classed('node-icongroup', true)
            .attr('transform', `translate(${iconX}, ${iconY})`)
            .html((d) => {
              if (d.data.id === route.params.nodeId) {
                if (d.data.current?.node?.role === 'applicant') return applicantIconWhite;
                else if (d.data.current?.node?.role === 'employee') return employeeIconWhite;
                else if (d.data.current?.node?.role === 'autonode') return autonodeIconWhite;
                else if (d.data.current?.node?.role === 'waiting_autonode')
                  return waitingAutonodeIconWhite;
              } else {
                if (
                  d.data.current?.node?.role === 'applicant' &&
                  d.data.current?.node.direction_type == 'all'
                )
                  return applicantIcon;
                else if (
                  d.data.current?.node?.role === 'applicant' &&
                  d.data.current?.node.direction_type == 'one'
                )
                  return applicantIconYellow;
                else if (
                  d.data.current?.node?.role === 'employee' &&
                  d.data.current?.node.direction_type == 'all'
                )
                  return employeeIcon;
                else if (
                  d.data.current?.node?.role === 'employee' &&
                  d.data.current?.node.direction_type == 'one'
                )
                  return employeeIconYellow;
                else if (
                  d.data.current?.node?.role === 'autonode' &&
                  d.data.current?.node.direction_type == 'all'
                )
                  return autonodeIcon;
                else if (
                  d.data.current?.node?.role === 'autonode' &&
                  d.data.current?.node.direction_type == 'one'
                )
                  return autonodeIconYellow;
                else if (
                  d.data.current?.node?.role === 'waiting_autonode' &&
                  d.data.current?.node.direction_type == 'all'
                )
                  return waitingAutonodeIcon;
                else if (
                  d.data.current?.node?.role === 'waiting_autonode' &&
                  d.data.current?.node.direction_type == 'one'
                )
                  return waitingAutonodeIconYellow;
              }
            })
            .append('rect')
            .attr('fill', 'transparent')
            .attr('stroke', 'none')
            .attr('width', 30)
            .attr('height', 30);
          //текст узла
          enter
            .append('g')
            .classed('node-text', true)
            .each(function (d) {
              const currentGroup = d3.select(this);
              const nodeName = d.data.current?.node?.name;
              const lines = splitTextIntoLines(nodeName, nodeWidth.value * 2 - 120);

              if (lines.length === 1) {
                currentGroup.attr('transform', 'translate(0, 5)');
              } else {
                currentGroup.attr('transform', 'translate(0, -5)');
              }
              if (!d.data.current?.stable) {
                currentGroup
                  .append('text')
                  .attr('font-size', 14)
                  .attr('font-weight', 500)
                  .attr('x', -nodeWidth.value)
                  .attr('y', lines.length - 45)
                  .classed('node-text_warn', true)
                  .text('Изменено!');
              }
              return lines.slice(0, 2).forEach((l, i) =>
                currentGroup
                  .append('text')
                  .attr('font-size', 18)
                  .attr('x', 60 - nodeWidth.value)
                  .attr('y', 20 * i)
                  .text(i === 1 && lines.length > 2 ? _.truncate(l) : l)
              );
            });

          if (connectingActivated.value) return;

          //кнопка вызова контекстного меню
          enter
            .append('g')
            .classed('node-menu', true)
            .attr('width', 30)
            .attr('height', 30)
            .attr('transform', `translate(${-iconX}, ${iconY}) rotate(90)`)
            .html(contextMenuIcon)
            .each(function () {
              d3.select(this).select('svg').attr('x', 3).attr('y', 3);
            })
            //фон кнопки (для контроля и увеличения области для клика)
            .append('circle')
            .attr('fill', 'transparent')
            .attr('stroke', '#AEAEAE')
            .attr('stroke-width', 0)
            .attr('r', 15)
            .attr('cy', 15)
            .attr('cx', 15)
            //действие при наведении (для интуитивности пользователю)
            .on('mouseover', function (...args) {
              d3.select(this).attr('stroke-width', 1);
            })
            .on('mouseout', function () {
              d3.select(this).attr('stroke-width', 0);
            })
            //отображение и управление контекстным меню
            .on('click', (event, d) => {
              showContextMenu.value = true;
              bpNode.value = d.data;
              const menu = d3.select('.menu');
              setTimeout(() => {
                menu
                  .style('left', function () {
                    const screenWidth = event.view.visualViewport.width;
                    const less = screenWidth - event.pageX < this.offsetWidth;
                    return `${event.pageX - (less ? this.offsetWidth : 0)}px`;
                  })
                  .style('top', function () {
                    const screenHeight = event.view.visualViewport.height;
                    const less = screenHeight - event.pageY < this.offsetHeight;
                    return `${event.pageY - (less ? this.offsetHeight : 0)}px`;
                  });
              }, 0);
              menu.selectAll('.menu-item').style('display', 'flex');
              if (d.data.current?.node?.role !== 'autonode') {
                menu.selectAll('.menu-item__autonode').style('display', 'none');
              }
              if (d.data.current?.node?.role !== 'waiting_autonode') {
                menu.selectAll('.menu-item__waiting-autonode').style('display', 'none');
              }
              event.stopPropagation();
            });
        })
    )
    //активация определенного узла
    .filter((d) => !d.disabled)
    .on('click', (e, d) => {
      e.stopPropagation();

      if (connectingActivated.value) {
        nodeRefData.value.sourceNode = bpNode.value;
        nodeRefData.value.targetNode = d.data;

        return emit('addNodeRef');
      }

      bpNode.value = d.data;
      nodeStore.isShowSidebar = true;
      router.push({
        name: 'input-fields',
        params: {
          projectId: route.params.projectId,
          bpId: route.params.bpId,
          nodeId: d.data.id
        }
      });
    });

  //отрисовка связей
  nodeGroup
    .select('#links')
    .classed('links__disabled', connectingActivated.value)
    .selectAll('path')
    .data(graph.links())
    .join((enter) =>
      enter
        .append('path')
        .classed('graph-link', true)
        .classed('graph-link_warn', (d) => !d.stable)
        .attr('d', ({ points }) => line(points))
        .on('click', (e, d) => {
          e.stopPropagation();
          if (connectingActivated.value) return;
          nodeRefData.value.sourceNode = d.source.data;
          nodeRefData.value.targetNode = d.target.data;
          emit('deleteNodeRef');
        })
    );
};

function addHandler() {
  emit('createNodeChild');
  showContextMenu.value = false;
}

function infoHandler() {
  emit('updateNode', true);
  showContextMenu.value = false;
}

function editHandler() {
  emit('updateNode', false);
  showContextMenu.value = false;
}

function connectHandler() {
  connectingActivated.value = true;
  showContextMenu.value = false;
}

function autonodeHandler() {
  router.push({
    name: 'autonode',
    params: {
      projectId: route.params.projectId,
      bpId: route.params.bpId,
      nodeId: bpNode.value.id
    }
  });
  showContextMenu.value = false;
}

function waitingAutonodeHandler() {
  router.push({
    name: 'waiting-autonode',
    params: {
      projectId: route.params.projectId,
      bpId: route.params.bpId,
      nodeId: bpNode.value.id
    }
  });
  showContextMenu.value = false;
}

function deleteHandler() {
  emit('deleteNode', false);
  showContextMenu.value = false;
}

watch(connectingActivated, () => drawGraph());

watch(nodeList, () => drawGraph(), { deep: true });

watch(
  () => route.params.nodeId,
  () => drawGraph({ fit: true })
);
onMounted(() => drawGraph({ fit: true }));
</script>

<template>
  <div class="chart" ref="svgElement">
    <svg id="svg">
      <g id="nodesGroup" transform="translate(2, 2)">
        <defs id="defs" />
        <g id="links" />
        <g id="nodes" />
        <g id="arrows" />
      </g>
    </svg>
    <Teleport to="body">
      <div v-show="showContextMenu" class="menu-wrapper">
        <div class="menu-overlay" @click="showContextMenu = false"></div>
        <div class="menu">
          <div class="menu-item menu-item__add" @click="addHandler">
            <div class="menu-item-icon" />
            <span>Создать дочерний узел</span>
          </div>
          <div class="menu-item menu-item__info" @click="infoHandler">
            <div class="menu-item-icon" />
            <span>Информация</span>
          </div>
          <div class="menu-item menu-item__edit" @click="editHandler">
            <div class="menu-item-icon" />
            <span>Редактировать</span>
          </div>
          <div class="menu-item menu-item__connect" @click="connectHandler">
            <div class="menu-item-icon" />
            <span>Связать узел</span>
          </div>
          <div class="menu-item menu-item__autonode" @click="autonodeHandler">
            <div class="menu-item-icon" />
            <span>Автоузел</span>
          </div>
          <div class="menu-item menu-item__waiting-autonode" @click="waitingAutonodeHandler">
            <div class="menu-item-icon" />
            <span>Ожидающий автоузел</span>
          </div>
          <div class="menu-item menu-item__delete" @click="deleteHandler">
            <div class="menu-item-icon" />
            <span>Удалить</span>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <div
          v-show="connectingActivated"
          id="cancel-connecting-btn"
          class="btn-default btn-primary"
          @click="connectingActivated = false"
        >
          Отменить связывание
        </div>
      </div>
    </Teleport>
  </div>
</template>
