import { inject, ref } from 'vue';
import { defineStore } from 'pinia';
import baseAxios from 'axios';

import { AXIOS } from '../const';

import * as api from '../router/endpoints';

export const useCriterionStore = defineStore('criterion', () => {
  const axios = inject(AXIOS) || baseAxios;

  const searchName = ref('');
  const criterionList = ref([]);
  const paginatedOptionList = ref([]);
  const optionList = ref({});
  const selectedOptions = ref([]);
  const selectedCriterionId = ref('');
  const selectedOptionId = ref('');
  const selectedCriterion = ref('');
  const selectedOption = ref('');
  const isSelectAll = ref(false);

  const getCriterionListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const createCriterionRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const getOptionListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const createOptionRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const updateOptionRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const deleteOptionRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  function getCriterionList(projectId) {
    getCriterionListRequest.value = {};
    const req = getCriterionListRequest.value;
    req.isLoading = true;
    criterionList.value = [];
    return axios
      .get(api.criterions({ projectId }))
      .then((resp) => {
        criterionList.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function createCriterion(projectId, title) {
    createCriterionRequest.value = {};
    const req = createCriterionRequest.value;
    req.isLoading = true;

    return axios
      .post(api.criterions({ projectId }), {
        title
      })
      .then(() => {
        getCriterionList(projectId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function updateCriterion(projectId, criterionId, title) {
    createCriterionRequest.value = {};
    const req = createCriterionRequest.value;
    req.isLoading = true;

    return axios
      .patch(api.criterionById({ projectId, criterionId }), {
        title
      })
      .then(() => {
        getCriterionList(projectId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }
  function getOptionList(projectId, criterionId) {
    getOptionListRequest.value = {};
    const req = getOptionListRequest.value;
    req.isLoading = true;
    optionList.value = {};
    return axios
      .get(api.options({ projectId, criterionId }), {
        params: { paginated: 0 }
      })
      .then((resp) => {
        optionList.value[criterionId] = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getPaginatedOptionList(projectId, criterionId, page = 1) {
    getOptionListRequest.value = {};
    const req = getOptionListRequest.value;
    req.isLoading = true;
    paginatedOptionList.value = [];
    return axios
      .get(api.options({ projectId, criterionId }), {
        params: { page, search: searchName.value }
      })
      .then((resp) => {
        paginatedOptionList.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function createOption(projectId, criterionId, title) {
    createOptionRequest.value = {};
    const req = createOptionRequest.value;
    req.isLoading = true;
    return axios
      .post(api.options({ projectId, criterionId }), {
        title
      })
      .then(() => {
        getPaginatedOptionList(projectId, criterionId, 1);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function updateOption(projectId, criterionId, optionId, title) {
    updateOptionRequest.value = {};
    const req = updateOptionRequest.value;
    req.isLoading = true;

    return axios
      .patch(api.optionById({ projectId, criterionId, optionId }), {
        title
      })
      .then(() => {
        getPaginatedOptionList(projectId, criterionId, 1);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function deleteOption( projectId, criterionId, optionId ) {
    deleteOptionRequest.value = {};
    const req = deleteOptionRequest.value;
    req.isLoading = true;

    return axios
      .delete(api.optionById({ projectId, criterionId, optionId }))
      .then(() => {
        getPaginatedOptionList(projectId, criterionId, 1);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }
  return {
    criterionList,
    paginatedOptionList,
    optionList,
    selectedOptions,
    selectedCriterionId,    
    isSelectAll,
    selectedOptionId,
    getCriterionListRequest,
    createCriterionRequest,
    getOptionListRequest,
    createOptionRequest,
    selectedCriterion,
    selectedOption,
    updateOptionRequest,
    deleteOptionRequest,
    getCriterionList,
    createCriterion,
    createOption,
    getPaginatedOptionList,
    getOptionList,
    updateCriterion,
    updateOption,
    deleteOption
  };
});
