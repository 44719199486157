<script setup lang="js">
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import { useInputGeometryCatalogsStore } from '@/stores/inputGeometryCatalogs.store';
import { useProjectStore } from '@/stores/project.store';
import { storeToRefs } from 'pinia';
import { onMounted, ref, defineProps, reactive } from 'vue';
import { useRouteParams } from '@vueuse/router';
import { useRouter } from 'vue-router';

const router = useRouter();
const { closeModal } = useModal();
const projectStore = useProjectStore();
const inputGeoCatalogStore = useInputGeometryCatalogsStore();
const { project } = storeToRefs(projectStore);
const { inputGeometryCatalogList, inputGeometryCatalogChangeDirectionalRequest } = storeToRefs(inputGeoCatalogStore);
const bpId = useRouteParams('bpId');
const nodeId = useRouteParams('nodeId');
const projectId = useRouteParams('projectId');
const selectAllChecked = ref(false);
const props = defineProps({
  selectedChildPivotId: Number,
  selectedChildBpNodeId: Number
});
const geoCatalogs = ref({
  creating_ids: [],
  removing_ids: []
});
const savedGeoCatalogs = ref([]);
const originalSavedGeoCatalogs = ref([]);
const selectedGeoCatalog = ref({});

onMounted(() => {
  inputGeoCatalogStore.clearRequestVariables();
  if (!project.value.locales) {
    projectStore.getProject(projectId.value);
  }
  let sGeoCatalogs = inputGeometryCatalogList.value.filter(isSavedGeoCatalog);
  sGeoCatalogs.forEach((geoCatalog) => {
    let geoCatalogId = geoCatalog.id;
    if (!savedGeoCatalogs.value.includes(geoCatalogId)) {
      originalSavedGeoCatalogs.value.push(geoCatalogId);
      savedGeoCatalogs.value.push(geoCatalogId);
    }
  });
});
function isSavedGeoCatalog(geom) {
  return geom.directional_geo_catalogs.some(
    (d) => d.direction?.next_bp_node_id == props.selectedChildBpNodeId
  );
}
function getTitle(geom) {
  return geom?.title[project.value.default_locale];
}
function toggleSelectAll() {
  const newState = !selectAllChecked.value;
  selectAllChecked.value = newState;
  inputGeometryCatalogList.value.forEach((geoCatalog) => {
    let geoCatalogId = geoCatalog.id;
    const isCurrentlyChecked = isCheckedGeom(geoCatalog);

    if (newState) {
      if (!isCurrentlyChecked) {
        if (!savedGeoCatalogs.value.includes(geoCatalogId)) {
          geoCatalogs.value.creating_ids.push(geoCatalogId);
        }
        const removingIndex = geoCatalogs.value.removing_ids.indexOf(geoCatalogId);
        if (removingIndex !== -1) {
          geoCatalogs.value.removing_ids.splice(removingIndex, 1);
        }
        selectedGeoCatalog[geoCatalogId] = true;
      }
    } else {
      if (isCurrentlyChecked) {
        if (savedGeoCatalogs.value.includes(geoCatalogId)) {
          geoCatalogs.value.removing_ids.push(geoCatalogId);
        }
        const creatingIndex = geoCatalogs.value.creating_ids.indexOf(geoCatalogId);
        if (creatingIndex !== -1) {
          geoCatalogs.value.creating_ids.splice(creatingIndex, 1);
        }
        selectedGeoCatalog[geoCatalogId] = false;
      }
    }
  });
}
function isCheckedGeom(geoCatalog) {
  let geoCatalogId = geoCatalog.id;
  return geoCatalogs.value.creating_ids.includes(geoCatalogId) || savedGeoCatalogs.value.includes(geoCatalogId);
}
function toggleGeometry(geom) {
  let geoCatalogId = geom.id;
  if (isCheckedGeom(geom)) {
    const savedIndex = savedGeoCatalogs.value.indexOf(geoCatalogId);
    if (savedIndex !== -1) {
      if (originalSavedGeoCatalogs.value.includes(geoCatalogId)) {
        geoCatalogs.value.removing_ids.push(geoCatalogId);
      }
      savedGeoCatalogs.value.splice(savedIndex, 1);
    }
    const creatingIndex = geoCatalogs.value.creating_ids.indexOf(geoCatalogId);
    if (creatingIndex !== -1) {
      geoCatalogs.value.creating_ids.splice(creatingIndex, 1);
    }
  } else {
    if (!geoCatalogs.value.creating_ids.includes(geoCatalogId)) {
      geoCatalogs.value.creating_ids.push(geoCatalogId);
    }
    const removingIndex = geoCatalogs.value.removing_ids.indexOf(geoCatalogId);
    if (removingIndex !== -1) {
      geoCatalogs.value.removing_ids.splice(removingIndex, 1);
    }
  }
  selectedGeoCatalog[geoCatalogId] = !selectedGeoCatalog[geoCatalogId];
}

function save() {
  inputGeoCatalogStore
    .changeDirectionalGeometryCatalogs(
      projectId.value,
      bpId.value,
      nodeId.value,
      props.selectedChildPivotId,
      geoCatalogs.value.creating_ids,
      geoCatalogs.value.removing_ids
    )
    .then(() => {
      closeModal();
    });
}
function isRequestError() {
  return inputGeometryCatalogChangeDirectionalRequest.value.error;
}
</script>

<template>
  <ModalTemplateSide @submit="save">
    <template v-slot:title>Добавить гео каталог для дочернего узла</template>
    <template v-slot:body>
      <div class="form-label d-flex align-items-center mt-3 pb-3" @click="toggleSelectAll">
        <div class="checkbox-btn" :class="{ checked: selectAllChecked }" />
        Выделить все
      </div>
      <p v-if="isRequestError()" class="error-mesage">
        {{ isRequestError() }}
      </p>
      <div class="mt-3" v-for="catalog in inputGeometryCatalogList" :key="catalog.id">
        <div class="d-flex align-items-center">
          <div class="checkbox-btn" :class="{ checked: isCheckedGeom(catalog) }" @click="toggleGeometry(catalog)" />
          <span class="ps-2">{{ getTitle(catalog.geo_catalog) }}</span>
        </div>
      </div>
    </template>
    <template v-slot:submit>Добавить</template>
  </ModalTemplateSide>
</template>
