<script setup lang="js">
import { onMounted, ref, computed } from 'vue';
import { useInputFieldStore } from '@/stores/inputField.store';
import { useNodeStore } from '@/stores/node.store';
import { useProjectStore } from '@/stores/project.store';
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';
import { useRouteParams } from '@vueuse/router';
import ChildNodeInputFieldsModal from '@/components/modals/ChildNodeInputFieldsModal.vue';

const inputFieldStore = useInputFieldStore();
const projectStore = useProjectStore();
const nodeStore = useNodeStore();
const { activeModal } = useModal();
const projectId = useRouteParams('projectId');
const bpId = useRouteParams('bpId');
const nodeId = useRouteParams('nodeId');
const { project } = storeToRefs(projectStore);
const { inputFieldList, typeList } = storeToRefs(inputFieldStore);
const { bpNode } = storeToRefs(nodeStore);
const selectedChildBpNode = ref({});
const selectedChildBpNodeId = ref('');
const selectedChildPivotId = ref('');
const hasUnstableInputField = computed(() => {
  return inputFieldList.value.some((inputField) => !inputField.stable);
});

function openSelectFieldsModal() {
  activeModal.value = 'ChildNodeFieldsModal';
}

function fieldType(field) {
  let fieldKeys = Object.keys(field);
  if (fieldKeys.includes('catalog')) {
    return 'Справочник';
  }
  if (fieldKeys.includes('the_table')) {
    return 'Таблица';
  }
  if (fieldKeys.includes('static')) {
    return 'Статичное поле';
  }
  if (fieldKeys.includes('field')) {
    return typeList.value[field.field.type];
  }
}

function fieldName(field) {
  let fieldKeys = Object.keys(field);
  let title = {};
  if (fieldKeys.includes('catalog')) {
    title = field.catalog.title;
  }
  if (fieldKeys.includes('the_table')) {
    title = field.the_table.title;
  }
  if (fieldKeys.includes('static')) {
    title = field.static.title;
  }
  if (fieldKeys.includes('field')) {
    title = field.field.title;
  }
  return title[project.value.default_locale];
}

function nodeName(title) {
  return title[project.value.default_locale];
}

function selectNode(bpNode) {
  selectedChildBpNode.value = bpNode;
  selectedChildBpNodeId.value = bpNode.id;
  selectedChildPivotId.value = bpNode.pivot.id;
}

function showField(field) {
  return field?.[getDirectionalKey(field)]?.some(
    (d) => d.direction?.next_bp_node_id == selectedChildBpNodeId.value
  );
}

function getDirectionalKey(ent) {
  return Object.keys(ent).find(k => k.startsWith('directional'));
}

onMounted(() => {
  if (!project.value.locales) {
    projectStore.getProject(projectId.value);
  }
  inputFieldStore.getInputFieldList(projectId.value, bpId.value, nodeId.value);
});
</script>
<template>
  <div class="d-flex align-items-center justify-content-between input-fields__btns mt-2">
    <div class="text-uppercase col-md-4 px-3">
      <b>Настроить поля для дочерних узлов</b>
    </div>
    <div class="d-flex align-items-center justify-content-end flex-wrap mr-3 col-md-8 px-3">
      <RouterLink
        :to="{
          name: 'input-fields',
          params: {
            projectId: projectId,
            bpId: bpId,
            nodeId: nodeId
          }
        }"
        class="gray-btn"
      >
        Вернуться к списку полей
      </RouterLink>
    </div>
  </div>
  <div class="bp-section-content_child-links">
    <div
      class="bp-section-content_child-link"
      v-for="childBpNode in bpNode.child_nodes"
      :key="childBpNode.id"
      @click="selectNode(childBpNode)"
      :class="{ active: selectedChildBpNodeId == childBpNode.id }"
    >
      <span :class="{ 'childNode-fixation_circle': hasUnstableInputField }">
        {{ nodeName(childBpNode.current.node.title) }}
      </span>
    </div>
  </div>
  <div class="bp-section-content_node-name px-3 my-3" v-if="selectedChildBpNodeId">
    <b>{{ nodeName(selectedChildBpNode.current.node.title) }}</b>
  </div>
  <button
    class="simple-btn mt-3 mb-3 ml-3"
    @click="openSelectFieldsModal()"
    v-if="selectedChildBpNodeId"
  >
    <span class="orange-plus" />
    Добавить
  </button>
  <div class="table-responsive pb-5 child-nodes_table">
    <table class="table gray-table" v-if="selectedChildBpNodeId">
      <thead>
        <tr>
          <th class="w-50">Наименование поля</th>
          <th>Тип</th>
          <th>Обязательность</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(inputField, rowIndex) in inputFieldList">
          <tr
            :key="rowIndex"
            v-if="showField(inputField)"
            :class="{
              'fixation-row-style': !inputField.stable
            }"
          >
            <td>
              <div class="d-flex align-items-center">
                <div v-if="!inputField.stable" class="fixation-circle-style"></div>
                <span class="name ps-2">{{ fieldName(inputField) }}</span>
              </div>
            </td>
            <td>{{ fieldType(inputField) }}</td>
            <td>
              <template v-if="inputField.required"> Да </template>
              <template v-else> Нет </template>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
  <ChildNodeInputFieldsModal
    v-if="activeModal === 'ChildNodeFieldsModal'"
    :selectedChildBpNodeId="selectedChildBpNodeId"
    :selectedChildPivotId="selectedChildPivotId"
  />
</template>
