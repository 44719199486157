<script setup lang="js">
import { ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import _ from 'lodash';
import { useAutonodeStore } from '@/stores/autonode.store';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import BasicToggleSwitch from '@/components/toggle-switch.vue';
import Multiselect from 'vue-multiselect';
const emit = defineEmits(['createStep']);

const autonodeStore = useAutonodeStore();
const { operationList, instruction_steps, custom_variables, variableList, newUuid } =
  storeToRefs(autonodeStore);
const { closeModal } = useModal();

const id = ref(0);
const operation = ref(null);
const input_vars = ref({});
const output_vars = ref({});
const condition = ref(false);
const condition_var = ref(null);

const inputVarList = computed(() => operation.value?.input_args || []);
const outputVarList = computed(() => operation.value?.output_args || []);

const allVariables = computed(() => [...custom_variables.value, ...variableList.value]);
const columnVariables = computed(() =>
  allVariables.value.reduce((acc, v) => {
    if (v.type != 'array') {
      return acc;
    }

    return [...acc, ...v.table_columns];
  }, [])
);

async function getUuid() {
  await autonodeStore.generateUuid();
  return newUuid.value;
}
async function generateUuid() {
  let uuid;
  let isUnique = false;

  while (!isUnique) {
    uuid = await getUuid();
    isUnique = !instruction_steps.value.some((step) => step.id === uuid);
  }

  return uuid;
}
async function createStep() {
  id.value = await generateUuid();
  let step = {
    id: id.value,
    operation_id: operation.value.id,
    input_vars: input_vars.value,
    output_vars: output_vars.value,
    parent_id: null
  };
  let children = [];
  let cond = null;
  let firstChildId = null;
  let secondChildId = null;
  firstChildId = await generateUuid();
  if (condition.value) {
    secondChildId = await generateUuid();
    children.push(secondChildId);
    cond = {};
    cond.var = condition_var.value;
    cond.true_step_id = firstChildId;
    cond.false_step_id = secondChildId;
  }
  step.condition = cond;
  children.push(firstChildId);
  step.children = children;
  instruction_steps.value.push(step);
  instruction_steps.value.push({
    id: firstChildId,
    parent_id: id.value,
    children: []
  });
  if (condition.value) {
    instruction_steps.value.push({
      id: secondChildId,
      parent_id: id.value,
      children: []
    });
  }
  emit('createStep');
  closeModal();
}

function selectOperation() {
  input_vars.value = {};
  output_vars.value = {};
}

async function createCustomVar(outputVar) {
  const varName = await autonodeStore.generateUuid();

  let varTitle = outputVar.name;
  let counter = 1;

  while (custom_variables.value.find((v) => v.title == varTitle)) {
    varTitle = `${outputVar.name}_${counter++}`;
  }

  const cVar = {
    name: varName,
    title: varTitle,
    type: outputVar.type
  };

  if (outputVar.type === 'array') {
    cVar.table_columns = Object.values(outputVar.columns).map((i) => ({ ...i }));
  }
  if (outputVar.type === 'text') {
    cVar.default_value = ' ';
  }

  custom_variables.value.push(cVar);

  output_vars.value[outputVar.name] = varName;
}

function isValidVarType(outputVar, customVar) {
  if (outputVar?.type !== 'array') {
    return outputVar?.type == customVar?.type;
  }

  return !_.differenceBy(outputVar?.columns, customVar?.table_columns, (c) => c?.name).length;
}
</script>

<template>
  <ModalTemplateSide @submit="createStep">
    <template v-slot:title>Создать операцию</template>
    <template v-slot:body>
      <div class="mt-3">
        <label class="form-label">Операция</label>
        <Multiselect
          v-model="operation"
          :options="operationList"
          placeholder="Выбрать..."
          :multiple="false"
          label="title"
          track-by="id"
          @select="selectOperation"
          class="multiselect-style"
        />
      </div>
      <div class="operation-modal_section-header">Входные данные</div>
      <div class="mt-3" v-for="(inputVar, index) in inputVarList" :key="index">
        <label class="form-label">{{ inputVar.title }} [{{ inputVar.type }}]</label>
        <select v-if="inputVar.onlyColumn" v-model="input_vars[inputVar.name]" class="form-select">
          <option :value="null" selected></option>
          <template v-for="variable in columnVariables">
            <option
              :value="variable.name"
              v-if="inputVar.type == variable.type"
              :key="variable.name"
            >
              {{ variable.title }}
            </option>
          </template>
        </select>
        <select v-else v-model="input_vars[inputVar.name]" class="form-select">
          <option :value="null" selected></option>
          <template v-for="variable in allVariables">
            <option
              :value="variable.name"
              v-if="inputVar.type == variable.type"
              :key="variable.name"
            >
              {{ variable.title }}
            </option>
          </template>
        </select>
      </div>
      <div class="operation-modal_section-header">Выходные данные</div>
      <div class="mt-3" v-for="(outputVar, index) in outputVarList" :key="index">
        <div class="d-flex justify-content-between">
          <label class="form-label flex-shrink-0">
            {{ outputVar.title }} [{{ outputVar.type }}]
          </label>
          <div class="add-btn mb-2 flex-shrink-0">
            <img
              @click="createCustomVar(outputVar)"
              src="@/assets/img/plus-ico.svg"
              alt="plus-icon"
            />
          </div>
        </div>
        <select v-model="output_vars[outputVar.name]" class="form-select">
          <option :value="null" selected></option>
          <template v-for="variable in custom_variables">
            <option
              :value="variable.name"
              v-if="isValidVarType(outputVar, variable)"
              :key="variable.name"
            >
              {{ variable.title }}
            </option>
          </template>
        </select>
      </div>
      <div class="operation-modal_section-header">&nbsp;</div>
      <div class="mt-3 d-flex align-items-center justify-content-between">
        <label class="form-label">Условие</label>
        <basic-toggle-switch v-model="condition" />
      </div>
      <div class="mt-3" v-if="condition">
        <label class="form-label">Переменная для условия [boolean]</label>
        <select v-model="condition_var" class="form-select">
          <option :value="null" selected></option>
          <template v-for="variable in variableList">
            <option :value="variable.name" v-if="variable.type == 'boolean'" :key="variable.name">
              {{ variable.title }}
            </option>
          </template>
          <template v-for="variable in custom_variables">
            <option :value="variable.name" v-if="variable.type == 'boolean'" :key="variable.name">
              {{ variable.title }}
            </option>
          </template>
        </select>
      </div>
    </template>
    <template v-slot:submit>Создать</template>
  </ModalTemplateSide>
</template>
