<script setup lang="js">
import { ref, onMounted } from 'vue';
import { useBusinessProcessStore } from '@/stores/businessProcess.store';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import { useRouteParams } from '@vueuse/router';
import { storeToRefs } from 'pinia';

const { updateBP, bp } = useBusinessProcessStore();
const { updateBPRequest } = storeToRefs(useBusinessProcessStore());
const { closeModal } = useModal();
const projectId = useRouteParams('projectId');
const name = ref('');
const description = ref('');
const type = ref('');

const typeOptions = [
  { value: null, text: '' },
  { value: 'legal_entity', text: 'Юр.лицо' },
  { value: 'individual', text: 'Физ.лицо' }
];

onMounted(() => {
  name.value = bp.name;
  description.value = bp.description;
  type.value = bp.type;
});

function updateItem() {
  updateBP(projectId.value, bp.id, name.value, description.value, type.value).then(() => closeModal());
}
function isErrorField(fieldName) {
  if (updateBPRequest.value.error?.errors) {
    return updateBPRequest.value.error.errors[fieldName] || false;
  }
}
function isNotFieldError() {
  if (!(updateBPRequest.value.error?.errors)) {
    return updateBPRequest.value.error;
  }
}
</script>

<template>
  <ModalTemplateSide @submit="updateItem">
    <template v-slot:title>Редактировать бизнес-процесс</template>
    <template v-slot:body>
      <div class="mt-3">
        <label class="form-label">Системное наименование БП</label>
        <input type="text" v-model="name" class="form-control" :class="{'error': isErrorField('name')}" />
        <p v-if="isErrorField('name')" class="error-mesage">
          <span v-for="error in isErrorField('name')">{{ error }}</span>
        </p>
      </div>
      <div class="mt-3">
        <label class="form-label">Тип</label>
        <select class="form-select" v-model="type" :class="{ error: isErrorField('type') }">
          <option v-for="option in typeOptions" :key="option.value" :value="option.value">
            {{ option.text }}
          </option>
        </select>
        <p v-if="isErrorField('type')" class="error-mesage">
          <span v-for="error in isErrorField('type')">{{ error }}</span>
        </p>
      </div>
      <div class="mt-3">
        <label class="form-label">Описание БП</label>
        <textarea v-model="description" class="form-control" :class="{'error': isErrorField('description')}"></textarea>
        <p v-if="isErrorField('description')" class="error-mesage">
          <span v-for="error in isErrorField('description')">{{ error }}</span>
        </p>
      </div>
      <p v-if="isNotFieldError()" class="error-mesage">
        {{ isNotFieldError() }}
      </p>
    </template>
    <template v-slot:submit>Сохранить</template>
  </ModalTemplateSide>
</template>