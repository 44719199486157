<script setup lang="js">
import { Bootstrap5Pagination } from 'laravel-vue-pagination';
import { onMounted, ref } from 'vue';
import { useServiceStore } from '@/stores/service.store';
import { useRouteParams } from '@vueuse/router';
import { storeToRefs } from 'pinia';
import ServiceCreateModal from '@/components/modals/ServiceCreateModal.vue';
import DeleteModal from '@/components/modals/DeleteModal.vue';
import useModal from '@/stores/modal.store';
import Search from '@/components/Search.vue';
import Sort from '@/components/Sort.vue';
import { useRouter } from 'vue-router';
import { useProjectStore } from '@/stores/project.store';

const router = useRouter();
const serviceStore = useServiceStore();
const projectId = useRouteParams('projectId');
const { activeModal, openModal, closeModal } = useModal();
const { paginatedServiceList, searchName, selectedServices, isSelectAll, sortType, getServiceListRequest } =
  storeToRefs(serviceStore);
const projectStore = useProjectStore();
const { project } = storeToRefs(projectStore);
const selectedService = ref(null);
const selectedServiceId = ref(null);

const types = { legal_entity: 'Юр.лицо', individual: 'Физ.лицо' };

onMounted(() => {
  serviceStore.getPaginatedServiceList(projectId.value);
});
function changeSortType(newValue) {
  sortType.value = newValue;
  serviceStore.getPaginatedServiceList();
}
function selectItem(itemId) {
  if (selectedServices.value.includes(itemId)) {
    selectedServices.value = selectedServices.value.filter((service) => service != itemId);
  } else {
    selectedServices.value.push(itemId);
  }
}
function selectAll() {
  const isActive = isSelectAll.value;
  if (!isActive) {
    const services = paginatedServiceList.value.data;
    services.forEach((service) => {
      if (!selectedServices.value.includes(service.id)) {
        selectedServices.value.push(service.id);
      }
    });
  } else {
    selectedServices.value = [];
  }
  isSelectAll.value = !isActive;
}
function handlePageChange(page) {
  serviceStore.getPaginatedServiceList(projectId.value, page);
}
function handleClick(service, modal) {
  selectedService.value = service;
  selectedServiceId.value = service.id;
  activeModal.value = modal;
}
function deleteService() {
  serviceStore.deleteService(projectId.value, selectedServiceId.value).then(() => {
    closeModal();
  });
}
function deleteAll() {}
function redirectTo(projectId, serviceId) {
  router.push({
    name: 'service-route',
    params: {
      projectId,
      serviceId
    }
  });
}
</script>

<template>
  <div class="container-fluid">
    <div class="row">
      <div class="d-flex justify-content-between mb-4 flex-wrap projects_filter-block">
        <div class="d-flex align-items-center flex-wrap">
          <Search
            @search="serviceStore.getPaginatedServiceList(projectId)"
            :value="searchName"
            :placeholderText="'Поиск по названию'"
            @input="searchName = $event.target.value"
          />
          <Sort :value="sortType" @applyChange="changeSortType" />
          <span class="delete-selected-btn" @click="deleteAll()" v-show="selectedServices.length"
            >Удалить выбранные</span
          >
        </div>
        <div class="d-flex align-items-center">
          <Bootstrap5Pagination
            :data="paginatedServiceList"
            @pagination-change-page="handlePageChange"
            show-disabled="true"
            :limit="2"
          />
          <div @click="openModal('serviceCreateModal')" class="orange-btn">
            <img src="@/assets/img/plus-ico.svg" alt="" /> Создать услугу
          </div>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table gray-table">
          <thead>
            <tr>
              <th class="checkbox-td">
                <span
                  @click="selectAll()"
                  class="table-checkbox"
                  :class="{ active: isSelectAll }"
                />
              </th>
              <th>Наименование услуги</th>
              <th>Тип</th>
              <th>Действие</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="getServiceListRequest.isLoading">
              <td colspan="5">
                <div class="d-flex justify-content-center">
                  <img class="loader" src="@/assets/img/loader.gif" alt="loader.gif" />
                </div>
              </td>
            </tr>
            <tr
              v-for="service in paginatedServiceList.data"
              :key="service.id"
              :class="{ selected: selectedServices.includes(service.id) }"
              @click="redirectTo(projectId, service.id)"
            >
              <td class="checkbox-td">
                <span @click.stop="selectItem(service.id)" class="table-checkbox name"></span>
              </td>
              <td>
                {{ service.title[project.default_locale] }}
              </td>
              <td>{{ types[service.type] }}</td>
              <td class="table-buttons">
                <div class="table-button" @click.stop="handleClick(service, '')">
                  <span class="edit-ico" />
                </div>
                <div class="table-button" @click.stop="handleClick(service, 'deleteModal')">
                  <span class="delite-ico" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <DeleteModal
        v-if="activeModal === 'deleteModal'"
        :itemText="'услугу'"
        :title="selectedService.title[project.default_locale]"
        @confirmDelete="deleteService()"
      />
      <ServiceCreateModal v-if="activeModal === 'serviceCreateModal'" />
    </div>
  </div>
</template>
