<script setup lang="js">
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';
import { useRouteParams } from '@vueuse/router';
import { useRoute } from 'vue-router';
import useModal from '@/stores/modal.store';
import ViewGeometryCreateModal from '@/components/modals/ViewGeometryCreateModal.vue';
import { useInputGeometriesStore } from '@/stores/inputGeometries.store';
import { useProjectStore } from '@/stores/project.store';

const { activeModal, openModal, closeModal } = useModal();
const inputGeometriesStore = useInputGeometriesStore();
const projectStore = useProjectStore();
const { viewGeometryList, viewGeometriesChangeOrderRequest } = storeToRefs(inputGeometriesStore);
const { project } = storeToRefs(projectStore);
const projectId = useRouteParams('projectId');
const bpId = useRouteParams('bpId');
const nodeId = useRouteParams('nodeId');
const order = ref(false);
const originalGeometriesOrder = ref([]);
const selectedGeometry = ref(null);

onMounted(() => {
  inputGeometriesStore.clearRequestVariables();
  if (!project.value.locales) {
    projectStore.getProject(projectId.value);
  }
  inputGeometriesStore.getViewGeometriesList(projectId.value, bpId.value, nodeId.value);
});

function getTitle(title) {
  return title[project.value.default_locale];
}
function toggleOrderState() {
  if (!order.value) {
    originalGeometriesOrder.value = [...viewGeometryList.value];
  }
  order.value = !order.value;
}
function selectForChangeOrder(index) {
  if (order.value) {
    if (selectedGeometry.value === null) {
      selectedGeometry.value = index;
    } else {
      const selectedItem = viewGeometryList.value[selectedGeometry.value];
      viewGeometryList.value.splice(selectedGeometry.value, 1);
      viewGeometryList.value.splice(index, 0, selectedItem);
      selectedGeometry.value = null;

      viewGeometryList.value.forEach((geom, idx) => {
        geom.order = idx + 1;
      });
    }
  }
}
function saveOrderState() {
  order.value = false;
  const geometriesOrder = viewGeometryList.value.map((geom, index) => ({
    id: geom.id,
    order: index + 1
  }));

  inputGeometriesStore.viewGeometriesChangeOrder(
    projectId.value,
    bpId.value,
    nodeId.value,
    geometriesOrder
  );
}
function cancel() {
  viewGeometryList.value = [...originalGeometriesOrder.value];
  order.value = false;
}
function isChangeOrderError() {
  return viewGeometriesChangeOrderRequest.value.error;
}
</script>

<template>
  <div class="input-btns d-flex justify-content-between align-items-center">
    <span v-if="order">НАСТРОИТЬ ПОРЯДОК ГЕОМЕТРИИ</span>
    <div v-else class="add-btn" @click="openModal('viewGeomCreateModal')">
      <img src="@/assets/img/plus-ico.svg" alt="plus-ico.svg" />
      Добавить геометрию
    </div>
    <div class="input-btns_order d-flex">
      <div v-if="order" class="d-flex">
        <div class="gray-btn me-3" @click="cancel()">Отмена</div>
        <div class="orange-btn" @click="saveOrderState">Сохранить изменения</div>
      </div>
      <div v-else class="order-btn" @click="toggleOrderState">Настроить порядок</div>
    </div>
  </div>
  <p v-if="isChangeOrderError()" class="error-mesage">
    {{ isChangeOrderError() }}
  </p>
  <div class="table-responsive pb-5">
    <table class="table gray-table">
      <thead>
        <tr>
          <th>Наименование геометрии</th>
          <th>Тип</th>
        </tr>
      </thead>
      <tbody>
        <tr
          :class="{
            'fixation-row-style': !viewGeometry.stable,
            selectedDoc: selectedDocument === index
          }"
          v-for="(viewGeometry, index) in viewGeometryList"
          :key="viewGeometry.id"
        >
          <td>
            <div class="d-flex align-items-center">
              <div v-if="!viewGeometry.stable" class="fixation-circle-style"></div>
              <img
                v-if="order"
                src="@/assets/img/order-dots-ico.svg"
                alt="order-dots-ico.svg"
                @click="selectForChangeOrder(index)"
                :class="{ hidden: order && selectedGeometry === index }"
              />
              <img
                v-if="order && selectedGeometry === index"
                src="@/assets/img/order-dots-ico_hover.svg"
                alt="order-dots-ico_hover.svg"
              />
              {{ getTitle(viewGeometry.input_geometry.geometry.title) }}
            </div>
          </td>
          <td>{{ viewGeometry.input_geometry.geometry.type }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <ViewGeometryCreateModal v-if="activeModal === 'viewGeomCreateModal'" />
</template>
