<script setup lang="js">
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import { useInputGeometryCatalogsStore } from '@/stores/inputGeometryCatalogs.store';
import { useProjectStore } from '@/stores/project.store';
import { storeToRefs } from 'pinia';
import { onMounted, ref, computed } from 'vue';
import { useRouteParams } from '@vueuse/router';
import { useRouter } from 'vue-router';
import { useNodeStore } from '@/stores/node.store';
import { useLayerStore } from '@/stores/layer.store';
import Multiselect from 'vue-multiselect';
import BasicToggleSwitch from '@/components/toggle-switch.vue';

const locale = computed(() => projectStore.project.default_locale);
const router = useRouter();
const { closeModal } = useModal();
const inputGeoCatalogStore = useInputGeometryCatalogsStore();
const { inputGeometryCatalogList, createInputGeometryCatalogRequest } = storeToRefs(inputGeoCatalogStore);
const projectStore = useProjectStore();
const { project } = storeToRefs(projectStore);
const layerStore = useLayerStore();
const { layersList } = storeToRefs(layerStore);

const layerId = ref(null);
const title = ref({});
const required = ref(false);
const bpId = useRouteParams('bpId');
const nodeId = useRouteParams('nodeId');
const projectId = useRouteParams('projectId');
const nodeStore = useNodeStore();
const { bpNode } = storeToRefs(nodeStore);
const childNodes = bpNode.value.child_nodes;
const child_node_ids = ref([]);
const selectedChildNodeIds = computed(() => {
  return child_node_ids.value.map(node => node.id);
});

onMounted(() => {
  inputGeoCatalogStore.clearRequestVariables();
  layerStore.getLayersList(projectId.value, bpId.value, nodeId.value);
  if (!project.value.locales) {
    projectStore.getProject(projectId.value);
  }
});

function getName(node) {
  return node.current.node.name;
}
function getLayerTitle(layer) {
  return layer.title[locale.value];
}
function create() {
  inputGeoCatalogStore
    .createInputGeometryCatalog(
      projectId.value,
      bpId.value,
      nodeId.value,
      layerId.value?.id,
      title.value,
      required.value,
      inputGeometryCatalogList.value.length + 1,
      selectedChildNodeIds.value
    )
    .then(() => {
      closeModal();
    });
}
function isErrorField(fieldName) {
  if (createInputGeometryCatalogRequest.value.error?.errors) {
    return createInputGeometryCatalogRequest.value.error.errors[fieldName] || false;
  }
}
function isNotFieldError() {
  if (!(createInputGeometryCatalogRequest.value.error?.errors)) {
    return createInputGeometryCatalogRequest.value.error;
  }
}
</script>

<template>
  <ModalTemplateSide @submit="create">
    <template v-slot:title>Добавить гео каталог для заполнения</template>
    <template v-slot:body>
      <div class="mt-3" v-for="locale in project.locales" :key="locale.id">
        <label class="form-label">Наименование каталога на {{ locale.name }}</label>
        <input type="text" v-model="title[locale.id]" class="form-control"
          :class="{ 'error': isErrorField('title') }" />
      </div>
      <p v-if="isErrorField('title')" class="error-mesage">
        <span v-for="error in isErrorField('title')">{{ error }}</span>
      </p>
      <div class="mt-3">
        <label class="form-label">Слои</label>
        <Multiselect
          v-model="layerId"
          :options="layersList"
          placeholder="Выбрать..."
          :multiple="false"
          :custom-label="getLayerTitle"
          track-by="id"
          class="multiselect-style"
          :class="{ error: isErrorField('layerId') }"
        />
        <p v-if="isErrorField('layerId')" class="error-mesage">
          <span v-for="error in isErrorField('layerId')">{{ error }}</span>
        </p>
      </div>
      <div class="mt-3 d-flex align-items-center justify-content-between">
        <label class="form-label">Обязательность</label>
        <basic-toggle-switch v-model="required" />
        
      </div>
      <p v-if="isErrorField('required')" class="error-mesage">
          <span v-for="error in isErrorField('required')">{{ error }}</span>
        </p>
      
      <div class="mt-3">
        <label class="form-label">Дочерние узлы</label>
        <Multiselect
          v-model="child_node_ids"
          :options="childNodes"
          placeholder="Выбрать..."
          :multiple="true"
          :custom-label="getName"
          track-by="id"
          class="multiselect-style"
          :class="{ error: isErrorField('child_node_ids') }"
        />
        <p v-if="isErrorField('child_node_ids')" class="error-mesage">
          <span v-for="error in isErrorField('child_node_ids')">{{ error }}</span>
        </p>
      </div>
      <p v-if="isNotFieldError()" class="error-mesage">
        {{ isNotFieldError() }}
      </p>
    </template>
    <template v-slot:submit>Добавить</template>
  </ModalTemplateSide>
</template>
