import { inject, ref } from 'vue';
import { defineStore } from 'pinia';
import baseAxios from 'axios';

import { AXIOS } from '../const';

import * as api from '../router/endpoints';

export const useInputDocumentsStore = defineStore('input_documents', () => {
  const axios = inject(AXIOS) || baseAxios;

  const inputDocumentsList = ref([]);
  const viewDocumentsList = ref([]);
  const ancestorsList = ref([]);
  const inputDocumentTemplates = ref([]);
  const inputDocument = ref(null);

  const getInputDocumentsListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const getInputDocumentRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const createInputDocumentsRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });
  const inputDocumentsDeleteRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });
  const inputDocumentsEditRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });
  const inputDocumentsDownloadTemplateRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });
  const inputDocumentsUploadTemplateRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });
  const inputDocumentsChangeOrderRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });
  const inputDocumentsChangeDirectionalRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });
  const getViewDocumentsListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });
  const createViewDocumentsRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });
  const viewDocumentsChangeOrderRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });
  const getAncestorsListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const inputDocumentsDeleteTemplateRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const inputDocumentsDownloadBasicTemplateRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  function getInputDocumentsList(projectId, bpId, nodeId) {
    getInputDocumentsListRequest.value = {};
    const req = getInputDocumentsListRequest.value;
    req.isLoading = true;

    return axios
      .get(api.inputDocuments({ projectId, bpId, nodeId }))
      .then((resp) => {
        inputDocumentsList.value = resp.data.sort((a, b) => a.order - b.order);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function createInputDocuments(
    projectId,
    bpId,
    nodeId,
    title,
    is_result,
    max_size,
    required,
    signable,
    order,
    child_node_ids
  ) {
    createInputDocumentsRequest.value = {};
    const req = createInputDocumentsRequest.value;
    req.isLoading = true;

    return axios
      .post(api.inputDocuments({ projectId, bpId, nodeId }), {
        title,
        is_result,
        max_size,
        required,
        signable,
        order,
        child_node_ids
      })
      .then(() => {
        getInputDocumentsList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getAncestorsList(projectId, bpId, nodeId) {
    getAncestorsListRequest.value = {};
    const req = getAncestorsListRequest.value;
    req.isLoading = true;

    return axios
      .get(api.inputDocumentsAncestors({ projectId, bpId, nodeId }))
      .then((resp) => {
        ancestorsList.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function inputDocUpdate(
    projectId,
    bpId,
    nodeId,
    inputDocId,
    title,
    is_result,
    max_size,
    required,
    signable
  ) {
    inputDocumentsEditRequest.value = {};
    const req = inputDocumentsEditRequest.value;
    req.isLoading = true;

    return axios
      .post(api.inputDocumentsById({ projectId, bpId, nodeId, inputDocId }), {
        title,
        is_result,
        max_size,
        required,
        signable
      })
      .then(() => {
        getInputDocumentsList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function inputDocDelete(projectId, bpId, nodeId, inputDocId) {
    inputDocumentsDeleteRequest.value = {};
    const req = inputDocumentsDeleteRequest.value;
    req.isLoading = true;

    return axios
      .delete(api.inputDocumentsById({ projectId, bpId, nodeId, inputDocId }))
      .then(() => {
        getInputDocumentsList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getInputDoc(projectId, bpId, nodeId, inputDocId) {
    getInputDocumentRequest.value = {};
    const req = getInputDocumentRequest.value;
    req.isLoading = true;

    return axios
      .get(api.inputDocumentsById({ projectId, bpId, nodeId, inputDocId }))
      .then((resp) => {
        inputDocument.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function uploadTemplate(projectId, bpId, nodeId, inputDocId, fd) {
    inputDocumentsUploadTemplateRequest.value = {};
    const req = inputDocumentsUploadTemplateRequest.value;
    req.isLoading = true;

    return axios
      .post(api.inputDocumentTemplates({ projectId, bpId, nodeId, inputDocId }), fd)
      .then((resp) => {
        getInputDocumentsList(projectId, bpId, nodeId);
        getInputDoc(projectId, bpId, nodeId, inputDocId);
      })
      .catch((e) => {
        req.error = e;
        const errMsg = e.message;

        if (errMsg === 'template_wrong_entities') {
          let text =
            'Вы загрузили шаблон с недопустимыми переменными. Ознакомьтесь с базовым шаблоном и удалите данные переменные:';
          let alertText = [text, ...e.errors].join('\n');
          alert(alertText);
        }
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function deleteTemplate(projectId, bpId, nodeId, inputDocId, inputDocTemplateId) {
    inputDocumentsDeleteTemplateRequest.value = {};
    const req = inputDocumentsDeleteTemplateRequest.value;
    req.isLoading = true;

    return axios
      .post(
        api.deleteInputDocumentTemplateById({
          projectId,
          bpId,
          nodeId,
          inputDocId,
          inputDocTemplateId
        })
      )
      .then(() => {
        getInputDocumentsList(projectId, bpId, nodeId);
        getInputDoc(projectId, bpId, nodeId, inputDocId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function downloadTemplate(projectId, bpId, nodeId, inputDocId, inputDocTemplateId, docName) {
    inputDocumentsDownloadTemplateRequest.value = {};
    const req = inputDocumentsDownloadTemplateRequest.value;
    req.isLoading = true;

    return axios
      .get(
        api.inputDocumentTemplateById({ projectId, bpId, nodeId, inputDocId, inputDocTemplateId }),
        {
          responseType: 'blob'
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', docName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function downloadBasicTemplate(projectId, bpId, nodeId) {
    inputDocumentsDownloadBasicTemplateRequest.value = {};
    const req = inputDocumentsDownloadBasicTemplateRequest.value;
    req.isLoading = true;

    return axios
      .get(api.inputDocumentsBasicTemplate({ projectId, bpId, nodeId }), {
        responseType: 'blob'
      })
      .then((response) => {
        let fileName = ['basic_template', projectId, bpId, nodeId].join('_') + '.docx';
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function inputDocumentsChangeOrder(projectId, bpId, nodeId, documents) {
    inputDocumentsChangeOrderRequest.value = {};
    const req = inputDocumentsChangeOrderRequest.value;
    req.isLoading = true;

    return axios
      .post(api.inputDocumentsOrder({ projectId, bpId, nodeId }), { documents })
      .then(() => {
        getInputDocumentsList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function changeDirectionalDocs(projectId, bpId, nodeId, directionId, docs) {
    inputDocumentsChangeDirectionalRequest.value = {};
    const req = inputDocumentsChangeDirectionalRequest.value;
    req.isLoading = true;

    return axios
      .post(api.inputDocumentsDirectional({ projectId, bpId, nodeId, directionId }), {
        docs
      })
      .then(() => {
        getInputDocumentsList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getViewDocumentsList(projectId, bpId, nodeId) {
    getViewDocumentsListRequest.value = {};
    const req = getViewDocumentsListRequest.value;
    req.isLoading = true;

    return axios
      .get(api.viewDocuments({ projectId, bpId, nodeId }))
      .then((resp) => {
        viewDocumentsList.value = resp.data.sort((a, b) => a.order - b.order);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }
  function createViewDocuments(
    projectId,
    bpId,
    nodeId,
    creating_input_docs,
    removing_input_doc_ids
  ) {
    createViewDocumentsRequest.value = {};
    const req = createViewDocumentsRequest.value;
    req.isLoading = true;

    return axios
      .post(api.viewDocuments({ projectId, bpId, nodeId }), {
        creating_input_docs,
        removing_input_doc_ids
      })
      .then(() => {
        getViewDocumentsList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function viewDocumentsChangeOrder(projectId, bpId, nodeId, documents) {
    viewDocumentsChangeOrderRequest.value = {};
    const req = viewDocumentsChangeOrderRequest.value;
    req.isLoading = true;

    return axios
      .post(api.viewDocumentsOrder({ projectId, bpId, nodeId }), { documents })
      .then(() => {
        getViewDocumentsList(projectId, bpId, nodeId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function clearRequestVariables() {
    viewDocumentsChangeOrderRequest.value = {};
    createViewDocumentsRequest.value = {};
    getViewDocumentsListRequest.value = {};
    inputDocumentsChangeDirectionalRequest.value = {};
    inputDocumentsChangeOrderRequest.value = {};
    inputDocumentsDownloadTemplateRequest.value = {};
    inputDocumentsUploadTemplateRequest.value = {};
    inputDocumentsDeleteRequest.value = {};
    inputDocumentsEditRequest.value = {};
    getAncestorsListRequest.value = {};
    createInputDocumentsRequest.value = {};
    getInputDocumentsListRequest.value = {};
    getInputDocumentRequest.value = {};
    inputDocumentsDeleteTemplateRequest.value = {};
  }

  return {
    inputDocumentsList,
    inputDocument,
    createInputDocuments,
    getInputDocumentsList,
    getViewDocumentsList,
    viewDocumentsList,
    getAncestorsList,
    ancestorsList,
    createViewDocuments,
    inputDocDelete,
    inputDocUpdate,
    downloadTemplate,
    uploadTemplate,
    inputDocumentTemplates,
    inputDocumentsChangeOrder,
    changeDirectionalDocs,
    viewDocumentsChangeOrder,
    clearRequestVariables,
    deleteTemplate,
    getInputDoc,
    downloadBasicTemplate,
    inputDocumentsDownloadBasicTemplateRequest,
    viewDocumentsChangeOrderRequest,
    createViewDocumentsRequest,
    getViewDocumentsListRequest,
    inputDocumentsChangeDirectionalRequest,
    inputDocumentsChangeOrderRequest,
    inputDocumentsDownloadTemplateRequest,
    inputDocumentsUploadTemplateRequest,
    inputDocumentsDeleteRequest,
    inputDocumentsEditRequest,
    getAncestorsListRequest,
    createInputDocumentsRequest,
    getInputDocumentsListRequest,
    inputDocumentsDeleteTemplateRequest,
    getInputDocumentRequest
  };
});
