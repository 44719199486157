<script setup lang="js">
import { onMounted, ref, computed } from 'vue';
import { useProjectStore } from '@/stores/project.store';
import { useInputFieldStore } from '@/stores/inputField.store';
import { useRegistryStore } from '@/stores/registry.store';
import { useRouteParams } from '@vueuse/router';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import BasicToggleSwitch from '@/components/toggle-switch.vue';
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';
import Multiselect from 'vue-multiselect';

const inputFieldStore = useInputFieldStore();
const projectStore = useProjectStore();
const registryStore = useRegistryStore();

const projectId = useRouteParams('projectId');
const bpId = useRouteParams('bpId');
const nodeId = useRouteParams('nodeId');
const { closeModal } = useModal();
const { project } = storeToRefs(projectStore);
const { regTables } = storeToRefs(registryStore);
const { inputField, updateInputCatalogRequest } = storeToRefs(inputFieldStore);
const locale = computed(() => projectStore.project.default_locale);

const selectedRegTable = ref(null);
const reg_version = ref(null);
const title = ref({});
const title_columns = ref({});
const required = ref(false);
const child_node_ids = ref([]);
const filteredRegTables = computed(() => regTables.value.filter((registry) => registry.stable));

onMounted(() => {
  registryStore.getRegTableList(projectId.value).then(() => {
    selectedRegTable.value = regTables.value.find(
      (regTable) => regTable.id == inputField.value.catalog.reg_table_id
    );
  });

  inputFieldStore.clearRequestVariables();
  if (!project.value.locales) {
    projectStore.getProject(projectId.value);
  }
  if (Object.keys(inputField.value).length) {
    title.value = inputField.value.catalog.title;
    reg_version.value = inputField.value.catalog.reg_version;
    title_columns.value = inputField.value.title_columns;
    required.value = inputField.value.catalog.required;
    inputField.value.directional_catalogs.forEach((d) => {
      child_node_ids.value.push(d.direction.next_bp_node_id);
    });
  }
});

function updateCatalog() {
  inputFieldStore
    .updateInputCatalog(
      projectId.value,
      bpId.value,
      nodeId.value,
      selectedRegTable.value.id,
      selectedRegTable.value.last_version,
      title.value,
      title_columns.value,
      required.value,
      child_node_ids.value
    )
    .then(() => {
      closeModal();
    });
}

function convertIdToString(id) {
  return id.toString();
}
function isErrorField(fieldName) {
  if (updateInputCatalogRequest.value.error?.errors) {
    return updateInputCatalogRequest.value.error.errors[fieldName] || false;
  }
}
function isNotFieldError() {
  if (!updateInputCatalogRequest.value.error?.errors) {
    return updateInputCatalogRequest.value.error;
  }
}
</script>

<template>
  <ModalTemplateSide @submit="updateCatalog">
    <template v-slot:title>Редактировать справочник</template>
    <template v-slot:body>
      <div class="mt-3" v-for="locale in project.locales" :key="locale.id">
        <label class="form-label">Наименование поля({{ locale.name }})</label>
        <input
          type="text"
          v-model="title[locale.id]"
          class="form-control"
          :class="{ error: isErrorField('title') }"
        />
      </div>
      <p v-if="isErrorField('title')" class="error-mesage">
        <span v-for="error in isErrorField('title')" :key="error">
          {{ error }}
        </span>
      </p>
      <div class="mt-3">
        <label class="form-label">Реестр</label>
        <Multiselect
          v-model="selectedRegTable"
          :options="filteredRegTables"
          placeholder="Выбрать..."
          :multiple="false"
          label="id"
          track-by="id"
          class="multiselect-style"
          :class="{ error: isErrorField('selectedRegTable') }"
        />
        <p v-if="isErrorField('reg_table_id')" class="error-mesage">
          <span v-for="error in isErrorField('reg_table_id')" :key="error">
            {{ error }}
          </span>
        </p>
      </div>
      <!--
        <div class="mt-3">
        <label class="form-label">Версия реестра</label>
        <select v-model="reg_version" class="form-select" :class="{'error': isErrorField('type')}">
          <option v-for="version in registryVersionList" :key="version.id" :value="version.id">
            {{ version }}
          </option>
        </select>
        <p v-if="isErrorField('reg_version')" class="error-mesage">
          <span v-for="error in isErrorField('reg_version')">{{ error }}</span>
        </p>
      </div> -->
      <template v-if="selectedRegTable">
        <div class="mt-3" v-for="locale in project.locales" :key="locale.id">
          <label class="form-label">Колонка({{ locale.name }})</label>
          <select
            v-model="title_columns[locale.id]"
            class="form-select"
            :class="{ error: isErrorField('title_columns') }"
          >
            <option
              v-for="column in selectedRegTable.columns"
              :key="column.id"
              :value="convertIdToString(column.id)"
            >
              {{ column.name }}
            </option>
          </select>
        </div>
      </template>
      <p v-if="isErrorField('title_columns')" class="error-mesage">
        <span v-for="error in isErrorField('title_columns')" :key="error">
          {{ error }}
        </span>
      </p>
      <div class="mt-3 d-flex align-items-center justify-content-between">
        <label class="form-label">Обязательность</label>
        <basic-toggle-switch v-model="required" />
      </div>
      <div class="mt-3">
        <label class="form-label">Дочерние узлы</label>
        <div v-for="directional in inputField.directional_catalogs" :key="directional.id">
          <b>{{ directional?.direction?.title?.[locale] || ''}}</b>
        </div>
      </div>
      <p v-if="isNotFieldError()" class="error-mesage">
        {{ isNotFieldError() }}
      </p>
    </template>
    <template v-slot:submit>Добавить</template>
  </ModalTemplateSide>
</template>
