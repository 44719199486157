<script setup lang="js">
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';
import { useRouteParams } from '@vueuse/router';
import { useRoute } from 'vue-router';
import useModal from '@/stores/modal.store';
import { useNodeStore } from '@/stores/node.store';
import { useProjectStore } from '@/stores/project.store';
import InputGeoCatalogCreateModal from '@/components/modals/InputGeoCatalogCreateModal.vue';
import InputGeoCatalogEditModal from '@/components/modals/InputGeoCatalogEditModal.vue';
import DeleteModal from '@/components/modals/DeleteModal.vue';
import { useInputGeometryCatalogsStore } from '@/stores/inputGeometryCatalogs.store';

const route = useRoute();
const { activeModal, openModal, closeModal } = useModal();
const nodeStore = useNodeStore();
const projectStore = useProjectStore();
const { bpNode } = storeToRefs(nodeStore);
const projectId = useRouteParams('projectId');
const bpId = useRouteParams('bpId');
const nodeId = useRouteParams('nodeId');
const inputGeometryCatalogStore = useInputGeometryCatalogsStore();
const {
  inputGeometryCatalogList,
  inputGeometryCatalogChangeOrderRequest,
  inputGeometryCatalogDeleteRequest
} = storeToRefs(inputGeometryCatalogStore);
const { project } = storeToRefs(projectStore);
const selectedGeoCatalog= ref(null);
const openMenu = ref({});
const orderInputGeomCatalog = ref(false);
const originalGeometryCatalogsOrder = ref([]);

onMounted(() => {
  if (!project.value.locales) {
    projectStore.getProject(projectId.value);
  }
  inputGeometryCatalogStore.clearRequestVariables();
  inputGeometryCatalogStore.getInputGeometryCatalogList(projectId.value, bpId.value, nodeId.value);
  if (Object.keys(bpNode.value).length == 0) {
    nodeStore.getNode(projectId.value, bpId.value, nodeId.value);
  }
});
function getTitle(item) {
  return item?.title[project.value.default_locale];
}
function toggleMenu(id) {
  for (const key in openMenu.value) {
    if (key !== id.toString()) {
      openMenu.value[key] = false;
    }
  }
  openMenu.value[id] = !openMenu.value[id];
}
function openMenuModal(modalName, geomField) {
  activeModal.value = modalName;
  selectedGeoCatalog.value = geomField;
}
function deleteGeomCatalog() {
  inputGeometryCatalogStore.inputGeomCatalogDelete(
    projectId.value,
    bpId.value,
    nodeId.value,
    selectedGeoCatalog.value.id
  );
  closeModal();
}
function toggleOrderState() {
  if (!orderInputGeomCatalog.value) {
    originalGeometryCatalogsOrder.value = [...inputGeometryCatalogList.value];
  }
  orderInputGeomCatalog.value = !orderInputGeomCatalog.value;
}
function cancel() {
  inputGeometryCatalogList.value = [...originalGeometryCatalogsOrder.value];
  orderInputGeomCatalog.value = false;
}
function saveOrderState() {
  orderInputGeomCatalog.value = false;
  const geometryCatalogsOrder = inputGeometryCatalogList.value.map((geomCatalog, index) => ({
    id: geomCatalog.id,
    order: index + 1
  }));

  inputGeometryCatalogStore.inputGeometryCatalogChangeOrder(
    projectId.value,
    bpId.value,
    nodeId.value,
    geometryCatalogsOrder
  );
}
function selectForChangeOrder(index) {
  if (orderInputGeomCatalog.value) {
    if (selectedGeoCatalog.value === null) {
      selectedGeoCatalog.value = index;
    } else {
      const selectedItem = inputGeometryCatalogList.value[selectedGeoCatalog.value];
      inputGeometryCatalogList.value.splice(selectedGeoCatalog.value, 1);
      inputGeometryCatalogList.value.splice(index, 0, selectedItem);
      selectedGeoCatalog.value = null;

      inputGeometryCatalogList.value.forEach((geomCatalog, idx) => {
        geomCatalog.order = idx + 1;
      });
    }
  }
}
function isChangeOrderError() {
  return inputGeometryCatalogChangeOrderRequest.value.error;
}
function isDeleteFieldError() {
  return inputGeometryCatalogDeleteRequest.value.error;
}
</script>

<template>
  <div v-if="route.name === 'input-geometry-catalog'" class="docs-view">
    <div class="input-btns d-flex justify-content-between align-items-center">
      <span v-if="orderInputGeomCatalog">НАСТРОИТЬ ПОРЯДОК ГЕО СПРАВОЧНИКОВ</span>
      <div v-else class="add-btn" @click="openModal('inputGeometryCatalogCreateModal')">
        <img src="@/assets/img/plus-ico.svg" alt="plus-ico.svg" />
        Добавить гео справочник
      </div>
      <div class="input-btns_order d-flex">
        <RouterLink
          :to="{
            name: 'input-geometry_catalogs-child',
            params: {
              projectId: projectId,
              bpId: bpId,
              nodeId: nodeId
            }
          }"
          v-if="!orderInputGeomCatalog"
          class="order-btn me-4"
        >
          Дочерние узлы
        </RouterLink>

        <div v-if="orderInputGeomCatalog" class="d-flex">
          <div class="gray-btn me-3" @click="cancel()">Отмена</div>
          <div class="orange-btn" @click="saveOrderState">Сохранить изменения</div>
        </div>

        <div v-else class="order-btn" @click="toggleOrderState">Настроить порядок</div>
      </div>
    </div>
    <p v-if="isChangeOrderError()" class="error-mesage">
      {{ isChangeOrderError() }}
    </p>
    <p v-if="isDeleteFieldError()" class="error-mesage">
      {{ isDeleteFieldError() }}
    </p>
    <div class="table-responsive pb-5">
      <table class="table gray-table">
        <thead>
          <tr>
            <th class="w-50">Наименование каталога</th>
            <th>Слой</th>
            <th>Обязательность</th>
            <th class="w-5">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(inputGeoCatalog, index) in inputGeometryCatalogList"
            :key="inputGeoCatalog.id"
            :class="{
              'fixation-row-style': !inputGeoCatalog.stable,
              selectedDoc: selectedGeoCatalog === index
            }"
          >
            <td>
              <div class="d-flex align-items-center">
                <div v-if="!inputGeoCatalog.stable" class="fixation-circle-style"></div>
                <img
                  v-if="orderInputGeomCatalog"
                  src="@/assets/img/order-dots-ico.svg"
                  alt="order-dots-ico.svg"
                  @click="selectForChangeOrder(index)"
                  :class="{ hidden: orderInputGeomCatalog && selectedGeoCatalog === index }"
                />
                <img
                  v-if="orderInputGeomCatalog && selectedGeoCatalog === index"
                  src="@/assets/img/order-dots-ico_hover.svg"
                  alt="order-dots-ico_hover.svg"
                />
                <span class="ps-2 name">{{ getTitle(inputGeoCatalog.geo_catalog) }}</span>
              </div>
            </td>
            <td>{{ getTitle(inputGeoCatalog.geo_catalog.layer) }}</td>
            <td>{{ inputGeoCatalog.geo_catalog.required }}</td>
            <td class="imgs" @click="toggleMenu(inputGeoCatalog.id)">
              <img src="@/assets/img/context-menu-ico.svg" alt="context-menu-ico.svg" class="img" />
              <img
                src="@/assets/img/context-menu-ico-hover.svg"
                alt="context-menu-ico.svg"
                class="img-hover"
              />
              <div v-if="openMenu[inputGeoCatalog.id]" class="menu">
                <div class="mb-2" @click="openMenuModal('inputGeoCatalogEditModal', inputGeoCatalog)">
                  <img
                    src="@/assets/img/edit-ico.svg"
                    alt="edit-ico.svg"
                    class="pe-2"
                  />Редактировать
                </div>
                <div @click="openMenuModal('deleteModal', inputGeoCatalog)">
                  <img
                    src="@/assets/img/context-menu_delete-ico.svg"
                    alt="context-menu_delete-ico"
                    class="pe-2"
                  />Удалить
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <InputGeoCatalogCreateModal v-if="activeModal === 'inputGeometryCatalogCreateModal'" />
    <InputGeoCatalogEditModal
      v-if="activeModal === 'inputGeoCatalogEditModal'"
      :selectedGeomCatalog="selectedGeoCatalog"
    />
    <DeleteModal
      v-if="activeModal === 'deleteModal'"
      :itemText="'гео каталог'"
      :title="getTitle(selectedGeoCatalog.geo_catalog)"
      @confirmDelete="deleteGeomCatalog()"
    />
  </div>
  <router-view />
</template>
