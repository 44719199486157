<script setup lang="js">
import { onMounted, ref } from 'vue';
import { useVirtualMachineStore } from '@/stores/virtualMachine.store';
import { useRouteParams } from '@vueuse/router';
import { storeToRefs } from 'pinia';
import DeleteModal from '@/components/modals/DeleteModal.vue';
import VirtualMachineCreateModal from '@/components/modals/VirtualMachineCreateModal.vue';
import VirtualMachineUpdateModal from '@/components/modals/VirtualMachineUpdateModal.vue';
import useModal from '@/stores/modal.store';
import { useRouter } from 'vue-router';

const virtualMachineStore = useVirtualMachineStore();
const projectId = useRouteParams('projectId');
const { activeModal, openModal, closeModal } = useModal();
const { virtualMachine, virtualMachineList } = storeToRefs(virtualMachineStore);
const router = useRouter();

onMounted(() => {
  virtualMachineStore.getVirtualMachineList(projectId.value);
});

function handleClick(machine, modal) {
  virtualMachineStore.clearRequestVariables();
  virtualMachine.value = machine;
  activeModal.value = modal;
}
function deleteVirtualMachine() {
  virtualMachineStore.deleteVirtualMachine(projectId.value, virtualMachine.value.id).then(() => {
    closeModal();
  });
}
function redirectTo(projectId, virtualMachineId) {
  router.push({
    name: 'virtual-machine-info',
    params: {
      projectId,
      virtualMachineId
    }
  });
}
</script>

<template>
  <div class="container-fluid">
    <div class="row">
      <div class="d-flex justify-content-end mb-4 flex-wrap">
        <div @click="openModal('VirtualMachineCreateModal')" class="orange-btn">
          <img src="@/assets/img/plus-ico.svg" alt="" /> Создать вирт. машину
        </div>
      </div>
      <div class="table-responsive">
        <table class="table gray-table">
          <thead>
            <tr>
              <th>Домен</th>
              <th>IP</th>
              <th>Тип</th>
              <th>Пользователь</th>
              <th>Пароль</th>
              <th>ENV файл</th>
              <th>Compose файл</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in virtualMachineList" :key="item.id">
              <td>
                {{ item.domain }}
              </td>
              <td>
                {{ item.ip }}
              </td>
              <td>
                {{ item.type }}
              </td>
              <td>
                {{ item.user }}
              </td>
              <td>
                {{ item.password }}
              </td>
              <td>
                {{ item.env_file_path }}
              </td>
              <td>
                {{ item.custom_compose_file_path }}
              </td>
              <td class="table-buttons">
                <div class="table-button">
                  <span @click="redirectTo(projectId, item.id)" class="fixate-ico" />
                </div>
                <div
                  class="table-button"
                  @click.stop="handleClick(item, 'VirtualMachineUpdateModal')"
                >
                  <span class="edit-ico" />
                </div>
                <div class="table-button" @click.stop="handleClick(item, 'deleteModal')">
                  <span class="delite-ico" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <DeleteModal
        v-if="activeModal === 'deleteModal'"
        :itemText="'вирт. машину с этим доменом '"
        :title="virtualMachine.domain"
        @confirmDelete="deleteVirtualMachine()"
      />
      <VirtualMachineCreateModal v-if="activeModal === 'VirtualMachineCreateModal'" />
      <VirtualMachineUpdateModal v-if="activeModal === 'VirtualMachineUpdateModal'" />
    </div>
  </div>
</template>
