<script setup lang="js">
import { ref, onMounted } from 'vue';
import { useRouteParams } from '@vueuse/router';
import { useInputFieldStore } from '@/stores/inputField.store';
import { useAutonodeStore } from '@/stores/waitingAutonode.store';
import { useProjectStore } from '@/stores/project.store';
import { useInputGeometriesStore } from '@/stores/inputGeometries.store';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';

const inputFieldStore = useInputFieldStore();
const autonodeStore = useAutonodeStore();
const projectStore = useProjectStore();
const inputGeometriesStore = useInputGeometriesStore();
const { inputFieldList } = storeToRefs(inputFieldStore);
const { custom_variables, output_entities, selectedOutputIndex } = storeToRefs(autonodeStore);
const { inputGeometryList } = storeToRefs(inputGeometriesStore);
const { project } = storeToRefs(projectStore);
const { closeModal } = useModal();
const projectId = useRouteParams('projectId');

const variable = ref(null);
const entity_id = ref(null);
const entity = ref(null);
const columns = ref(null);
const entity_types = {
  "input_field": "Поле",
  "input_table": "Таблица",
  "input_geometry": "Геометрия",
  //"input_document": "Документ",
  "input_catalog": "Справочник"
};
const filteredFieldList = ref([]);

function updateOutput() {
  let output = {};
  output.entity = entity.value;
  output.entity_id = entity_id.value;
  output.variable = variable.value;
  output.columns = columns.value;
  output_entities.value[selectedOutputIndex.value] = output;
  closeModal();
}
function fieldName(field) {
  let fieldKeys = Object.keys(field);
  let title = {};
  if (fieldKeys.includes("catalog")) {
    title = field.catalog.title
  }
  if (fieldKeys.includes("the_table")) {
    title = field.the_table.title
  }
  if (fieldKeys.includes("static")) {
    title = field.static.title
  }
  if (fieldKeys.includes("field")) {
    title = field.field.title
  }
  if (fieldKeys.includes("geometry")) {
    title = field.geometry.title
  }

  return title[project.value.default_locale];
}
function fieldType(field) {
  let fieldKeys = Object.keys(field);
  if (fieldKeys.includes("catalog")) {
    return "catalog";
  }
  if (fieldKeys.includes("the_table")) {
    return "the_table";
  }
  if (fieldKeys.includes("static")) {
    return "input_field";
  }
  if (fieldKeys.includes("field")) {
    return "input_field";
  }
}
function prepareFieldList() {
  let list = [...inputFieldList.value];
  filteredFieldList.value = list.filter((field) => fieldType(field) == entity.value)
}
onMounted(() => {
  if (!project.value.locales) {
    projectStore.getProject(projectId.value);
  }
  if (output_entities.value[selectedOutputIndex.value]) {
    let output_entity = { ...output_entities.value[selectedOutputIndex.value] };
    variable.value = output_entity.variable;
    entity.value = output_entity.entity;
    columns.value = output_entity.columns;
    prepareFieldList();
    entity_id.value = output_entity.entity_id;
  }
});
</script>

<template>
  <ModalTemplateSide @submit="updateOutput">
    <template v-slot:title>Редактировать выходные данные</template>
    <template v-slot:body>
      <div class="mt-3">
        <label class="form-label">Переменная</label>
        <select v-model="variable" class="form-select">
          <option v-for="variable in custom_variables" :key="variable.name" :value="variable.name">
            {{ variable.title }}
          </option>
        </select>
      </div>
      <div class="mt-3">
        <label class="form-label">Тип поля</label>
        <select v-model="entity" class="form-select" @change="prepareFieldList()">
          <option v-for="entity_type in Object.keys(entity_types)" :key="entity_type" :value="entity_type">
            {{ entity_types[entity_type] }}
          </option>
        </select>
      </div>
      <div class="mt-3" v-if="entity">
        <label class="form-label">Поле (в исполняемой системе)</label>
        <select v-model="entity_id" class="form-select">
          <template v-if="entity != 'input_geometry'">
            <option v-for="field in filteredFieldList" :key="field.id" :value="field.id">
              {{ fieldName(field) }}
            </option>
          </template>
          <option v-for="field in inputGeometryList" :key="field.id" :value="field.id" v-if="entity == 'input_geometry'">
            {{ fieldName(field) }}
          </option>
        </select>
      </div>
    </template>
    <template v-slot:submit>Сохранить</template>
  </ModalTemplateSide>
</template>