import { inject, ref } from 'vue';
import { defineStore } from 'pinia';
import baseAxios from 'axios';
import _ from 'lodash';

import { AXIOS } from '../const';

import * as api from '../router/endpoints';

export const useNodeStore = defineStore('node', () => {
  const axios = inject(AXIOS) || baseAxios;

  const nodeList = ref([]);

  const bpNode = ref({});

  const roleList = ref({
    applicant: 'Заявитель',
    employee: 'Сотрудник',
    autonode: 'Автоузел',
    waiting_autonode: 'Ожидающий автоузел',
  });

  const directionTypeList = ref({
    all: 'Все',
    one: 'Один из'
  });

  const isShowSidebar = ref(false);
  const getNodeListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const createNodeRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const updateNodeRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const deleteNodeRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const getNodeRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const deleteNodeRefRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const addNodeRefRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const nodeRefData = ref({
    sourceNode: null,
    targetNode: null
  });

  function getNodeList(projectId, bpId) {
    nodeList.value = [];
    getNodeListRequest.value = {};
    const req = getNodeListRequest.value;
    req.isLoading = true;

    return axios
      .get(api.nodes({ projectId, bpId }))
      .then((resp) => {
        nodeList.value = resp.data;
      })
      .catch((e) => {
        req.error = 'onload_error';
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function createNode(
    projectId,
    bpId,
    title,
    name,
    note,
    role,
    direction_type,
    parent_bp_node_id,
    direction_field_title,
    direction_title,
    set_reject,
    delegate
  ) {
    createNodeRequest.value = {};
    const req = createNodeRequest.value;
    req.isLoading = true;

    return axios
      .post(api.nodes({ projectId, bpId }), {
        title,
        name,
        note,
        role,
        direction_type,
        parent_bp_node_id,
        direction_field_title,
        direction_title,
        set_reject,
        delegate
      })
      .then(() => {
        bpNode.value = {};
        getNodeList(projectId, bpId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function updateNode(
    projectId,
    bpId,
    nodeId,
    title,
    name,
    note,
    role,
    direction_type,
    direction_field_title,
    direction_title,
    set_reject,
    delegate
  ) {
    updateNodeRequest.value = {};
    const req = updateNodeRequest.value;
    req.isLoading = true;

    return axios
      .post(api.nodeById({ projectId, bpId, nodeId }), {
        title,
        name,
        note,
        role,
        direction_type,
        direction_field_title,
        direction_title,
        set_reject,
        delegate
      })
      .then(() => {
        bpNode.value = {};
        getNodeList(projectId, bpId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function deleteNode(projectId, bpId, nodeId) {
    deleteNodeRequest.value = {};
    const req = deleteNodeRequest.value;
    req.isLoading = true;

    return axios
      .delete(api.nodeById({ projectId, bpId, nodeId }))
      .then(() => {
        bpNode.value = {};
        getNodeList(projectId, bpId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getNode(projectId, bpId, nodeId) {
    getNodeRequest.value = {};
    const req = getNodeRequest.value;
    req.isLoading = true;

    return axios
      .get(api.nodeById({ projectId, bpId, nodeId }))
      .then((resp) => {
        bpNode.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function deleteNodeRef(projectId, bpId, bpNodeId, childBpNodeId) {
    deleteNodeRefRequest.value = {};
    const req = deleteNodeRefRequest.value;
    req.isLoading = true;

    return axios
      .delete(api.deattachNode({ projectId, bpId, nodeId: bpNodeId, childNodeId: childBpNodeId }))
      .then(() => {
        nodeRefData.value = {
          sourceNode: null,
          targetNode: null
        };
        getNodeList(projectId, bpId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function addNodeRef(projectId, bpId, bpNodeId, childBpNodeId) {
    addNodeRefRequest.value = {};
    const req = addNodeRefRequest.value;
    req.isLoading = true;

    return axios
      .post(api.attachNode({ projectId, bpId, nodeId: bpNodeId, childNodeId: childBpNodeId }))
      .then(() => {
        nodeRefData.value = {
          sourceNode: null,
          targetNode: null
        };
        getNodeList(projectId, bpId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getNodeAncestors() {
    const nodeParents = _.chain(nodeList.value)
      .keyBy('id')
      .mapValues(n => n.parent_nodes.map(p => `${p.id}`))
      .value();

    const result = [];
    let parents = [bpNode.value.id];

    do {
      result.unshift(parents.shift());
      parents.push(...nodeParents[result[0]]);
    } while (parents.length);

    return result;
  }

  function clearRequestVariables() {
    getNodeRequest.value = {};
    deleteNodeRequest.value = {};
    updateNodeRequest.value = {};
    createNodeRequest.value = {};
  }

  return {
    createNode,
    getNodeList,
    updateNode,
    deleteNode,
    getNode,
    clearRequestVariables,
    deleteNodeRef,
    addNodeRef,
    nodeList,
    roleList,
    directionTypeList,
    bpNode,
    getNodeAncestors,
    getNodeRequest,
    deleteNodeRequest,
    updateNodeRequest,
    createNodeRequest,
    nodeRefData,
    deleteNodeRefRequest,
    addNodeRefRequest,
    isShowSidebar
  };
});
