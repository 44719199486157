<script setup lang="js">
import { onMounted, ref, defineProps } from 'vue';
import { useServiceStore } from '@/stores/service.store';
import { useBusinessProcessStore } from '@/stores/businessProcess.store';
import { useRouteParams } from '@vueuse/router';
import { storeToRefs } from 'pinia';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import { useRouter } from 'vue-router';
import Multiselect from 'vue-multiselect';

const serviceStore = useServiceStore();
const businessProcessStore = useBusinessProcessStore();
const projectId = useRouteParams('projectId');
const serviceId = useRouteParams('serviceId');
const { bpList } = storeToRefs(businessProcessStore);
const { closeModal } = useModal();
const router = useRouter();
const props = defineProps({
  selectedRoute: Object,
});
const selectedBusinessProcessId = ref(props.selectedRoute.business_process.id);
onMounted(() => {
  businessProcessStore.getBPList();
});

function changeServiceRoute() {
  serviceStore
    .changeBPInRoute(
      projectId.value,
      serviceId.value,
      props.selectedRoute.route_id,
      selectedBusinessProcessId.value.id
    )
    .then(() => {
      closeModal();
      router.push({ name: 'service-route' });
      serviceStore.getServiceRouteList(projectId.value, serviceId.value);
    });
}
</script>

<template>
  <ModalTemplateSide @submit="changeServiceRoute">
    <template v-slot:title>Редактировать маршрут</template>
    <template v-slot:body>
      <div class="mt-3">
        <label class="form-label">Бизнес-процесс</label>
        <Multiselect
          v-model="selectedBusinessProcessId"
          :options="bpList"
          placeholder="Выбрать..."
          :multiple="false"
          label="name"
          track-by="id"
          class="multiselect-style"
        />
      </div>
      
    </template>
    <template v-slot:submit>Сохранить</template>
  </ModalTemplateSide>
</template>