import { inject, ref } from 'vue';
import { defineStore } from 'pinia';
import baseAxios from 'axios';
import { useRouteParams } from '@vueuse/router';

import { AXIOS } from '../const';

import * as api from '../router/endpoints';

export const useBusinessProcessStore = defineStore('businessProcess', () => {
  const axios = inject(AXIOS) || baseAxios;

  const searchName = ref('');
  const sortType = ref('1');
  const bp = ref({});
  const paginatedBPList = ref([]);
  const selectedBPs = ref([]);
  const isSelectAll = ref(false);
  const projectId = useRouteParams('projectId');
  const bpList = ref([]);

  const getBPListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const createBPRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const copyBPRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const updateBPRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const deleteBPRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const getBPRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const fixateBPRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const validateBPRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  function getBPList() {
    getBPListRequest.value = {};
    const req = getBPListRequest.value;
    req.isLoading = true;
    bpList.value = [];
    return axios
      .get(api.businessProcess({ projectId: projectId.value }), {
        params: { paginated: 0 }
      })
      .then((resp) => {
        bpList.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getPaginatedBPList(page = 1) {
    getBPListRequest.value = {};
    const req = getBPListRequest.value;
    req.isLoading = true;
    paginatedBPList.value = [];
    return axios
      .get(api.businessProcess({ projectId: projectId.value }), {
        params: { page, searchName: searchName.value }
      })
      .then((resp) => {
        paginatedBPList.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function createBP(name, description, type) {
    createBPRequest.value = {};
    const req = createBPRequest.value;
    req.isLoading = true;

    return axios
      .post(api.businessProcess({ projectId: projectId.value }), {
        name,
        description,
        type
      })
      .then(() => {
        getPaginatedBPList(1);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function copyBP(name, description) {
    copyBPRequest.value = {};
    const req = copyBPRequest.value;
    req.isLoading = true;

    return axios
      .post(api.copyBusinessProcess({ projectId: projectId.value, bpId: bp.value.id }), {
        name,
        description
      })
      .then(() => {
        getPaginatedBPList(1);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function updateBP(projectId, bpId, name, description, type) {
    updateBPRequest.value = {};
    const req = updateBPRequest.value;
    req.isLoading = true;

    return axios
      .patch(api.businessProcessById({ projectId, bpId }), {
        name,
        description,
        type
      })
      .then(() => {
        getPaginatedBPList(1);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function fixateBP(projectId, bpId) {
    fixateBPRequest.value = {};
    const req = fixateBPRequest.value;
    req.isLoading = true;

    return axios
      .post(api.fixateBusinessProcess({ projectId, bpId }))
      .then(() => {
        req.error = null;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function validateBP(projectId, bpId) {
    validateBPRequest.value = {};
    const req = validateBPRequest.value;
    req.isLoading = true;

    return axios
      .post(api.validateBusinessProcess({ projectId, bpId }))
      .then(() => {
        req.error = null;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function deleteBP(bp_id) {
    deleteBPRequest.value = {};
    const req = deleteBPRequest.value;
    req.isLoading = true;

    return axios
      .post(api.businessProcessById({ bp_id }))
      .then(() => {
        getPaginatedBPList(1);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getBP(bpId) {
    getBPRequest.value = {};
    const req = getBPRequest.value;
    req.isLoading = true;
    bp.value = {};
    return axios
      .get(api.businessProcessById({ projectId: projectId.value, bpId }))
      .then((resp) => {
        bp.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  return {
    projectId,
    searchName,
    sortType,
    paginatedBPList,
    selectedBPs,
    isSelectAll,
    bp,
    bpList,
    getBPRequest,
    deleteBPRequest,
    updateBPRequest,
    createBPRequest,
    copyBPRequest,
    getBPListRequest,
    fixateBPRequest,
    validateBPRequest,

    getBPList,
    getPaginatedBPList,
    createBP,
    updateBP,
    deleteBP,
    copyBP,
    getBP,
    fixateBP,
    validateBP
  };
});
