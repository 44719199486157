<script setup lang="js">
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';
import { useRouteParams } from '@vueuse/router';
import useModal from '@/stores/modal.store';
import { useLayerStore } from '@/stores/layer.store';
import LayerCreateModal from '@/components/modals/LayerCreateModal.vue';
import DeleteModal from '@/components/modals/DeleteModal.vue';

const { activeModal, openModal, closeModal } = useModal();
const layerStore = useLayerStore();
const { layersList } = storeToRefs(layerStore);
const projectId = useRouteParams('projectId');
const bpId = useRouteParams('bpId');
const nodeId = useRouteParams('nodeId');
const selectedLayer = ref(null);

onMounted(() => {
  layerStore.getLayersList(projectId.value, bpId.value, nodeId.value);
});
function openMenuModal(modalName, layer) {
  activeModal.value = modalName;
  selectedLayer.value = layer;
}
function deleteLayer() {
  layerStore.layerDelete(projectId.value, bpId.value, nodeId.value, selectedLayer.value.id);
  closeModal();
}
</script>
<template>
  <div class="geometry-layers">
    <div class="input-btns d-flex justify-content-end align-items-center">
      <div class="add-btn" @click="openModal('layerCreateModal')">
        <img src="@/assets/img/plus-ico.svg" alt="plus-ico.svg" />
        Добавить слой
      </div>
    </div>
    <div class="table-responsive pb-5">
      <table class="table gray-table">
        <thead>
          <tr>
            <th>Наименование</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="layer in layersList"
            :key="layer.id"
            :class="{ 'fixation-row-style': !layer.stable, selectedDoc: selectedLayer === layer }"
          >
            <td>
              <div class="d-flex align-items-center">
                <div v-if="!layer.stable" class="fixation-circle-style"></div>
                <span class="name ps-2">{{ layer.reg_table_id }}</span>
              </div>
            </td>
            <td class="imgs">
              <div @click="openMenuModal('deleteModal', layer)">
                <img
                  src="@/assets/img/context-menu_delete-ico.svg"
                  alt="context-menu_delete-ico"
                  class="pe-2"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <LayerCreateModal v-if="activeModal === 'layerCreateModal'" />
  <DeleteModal
    v-if="activeModal === 'deleteModal'"
    :itemText="'слой'"
    :title="selectedLayer.reg_table_id"
    @confirmDelete="deleteLayer()"
  />
</template>
