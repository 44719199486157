<script setup lang="js">
import { ref } from 'vue';
import { useRegistryStore } from '@/stores/registry.store';
import { useRouteParams } from '@vueuse/router';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';

const emit = defineEmits(['save']);
const projectId = useRouteParams('projectId');
const { closeModal } = useModal();
const registryStore = useRegistryStore();
const { createRegTableRequest } = storeToRefs(registryStore);
const { createRegTable } = useRegistryStore();
const id = ref(null);
const description = ref(null);

function createTable() {
  createRegTable(projectId.value, id.value, description.value).then(() => {
    emit('save');
    closeModal();
  });
}
function isErrorField(fieldName) {
  if (createRegTableRequest.value.error?.errors) {
    return createRegTableRequest.value.error.errors[fieldName] || false;
  }
}
function isNotFieldError() {
  if (!(createRegTableRequest.value.error?.errors)) {
    return createRegTableRequest.value.error;
  }
}
</script>

<template>
  <ModalTemplateSide @submit="createTable">
    <template v-slot:title>Создать таблицу</template>
    <template v-slot:body>
      <div class="mt-3">
        <label class="form-label">Название (на латинском без пробелов)</label>
        <input type="text" v-model="id" class="form-control" :class="{'error': isErrorField('id')}" />
        <p v-if="isErrorField('id')" class="error-mesage">
          <span v-for="error in isErrorField('id')">{{ error }}</span>
        </p>
      </div>
      <div class="mt-3">
        <label class="form-label">Описание</label>
        <input type="text" v-model="description" class="form-control" :class="{'error': isErrorField('description')}" />
        <p v-if="isErrorField('description')" class="error-mesage">
          <span v-for="error in isErrorField('description')">{{ error }}</span>
        </p>
      </div>
      <p v-if="isNotFieldError()" class="error-mesage">
        {{ isNotFieldError() }}
      </p>
    </template>
    <template v-slot:submit>Создать</template>
  </ModalTemplateSide>
</template>
