<script setup lang="js">
import { onMounted, ref, computed } from 'vue';
import { useProjectStore } from '@/stores/project.store';
import { useInputFieldStore } from '@/stores/inputField.store';
import { useRouteParams } from '@vueuse/router';
import ModalTemplateCenter from '@/components/modals/ModalTemplateCenter.vue';
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';
import BasicToggleSwitch from '@/components/toggle-switch.vue';

const inputFieldStore = useInputFieldStore();
const projectStore = useProjectStore();

const projectId = useRouteParams('projectId');
const bpId = useRouteParams('bpId');
const nodeId = useRouteParams('nodeId');
const { closeModal } = useModal();
const { project } = storeToRefs(projectStore);
const { inputField, updateInputTableRequest } = storeToRefs(inputFieldStore);
const locale = computed(() => projectStore.project.default_locale);

const title = ref({});
const child_node_ids = ref([]);
const required = ref(false);

onMounted(() => {
  inputFieldStore.clearRequestVariables();
  if (!project.value.locales) {
    projectStore.getProject(projectId.value);
  }
  if (Object.keys(inputField.value).length) {
    title.value = inputField.value.the_table.title;
    required.value = inputField.value.the_table?.required || false;
  }
  inputField.value.directional_tables.forEach((d) => {
    child_node_ids.value.push(d.direction.next_bp_node_id);
  });
});

function updateTable() {
  inputFieldStore
    .updateInputTable(
      projectId.value,
      bpId.value,
      nodeId.value,
      title.value,
      child_node_ids.value,
      required.value
    )
    .then(() => {
      closeModal();
    });
}

function isErrorField(fieldName) {
  if (updateInputTableRequest.value.error?.errors) {
    return updateInputTableRequest.value.error.errors[fieldName] || false;
  }
}
function isNotFieldError() {
  if (!updateInputTableRequest.value.error?.errors) {
    return updateInputTableRequest.value.error;
  }
}
</script>

<template>
  <ModalTemplateCenter @submit="updateTable">
    <template v-slot:title>Редактировать таблицу</template>
    <template v-slot:body>
      <div class="mt-3" v-for="locale in project.locales" :key="locale.id">
        <label class="form-label">Наименование таблицы({{ locale.name }})</label>
        <input
          type="text"
          v-model="title[locale.id]"
          class="form-control"
          :class="{ error: isErrorField('title') }"
        />
      </div>
      <p v-if="isErrorField('title')" class="error-mesage">
        <span v-for="error in isErrorField('title')" :key="error">
          {{ error }}
        </span>
      </p>
      <div class="mt-3 d-flex align-items-center justify-content-between">
        <label class="form-label">Обязательность</label>
        <basic-toggle-switch v-model="required" />
      </div>
      <div class="mt-3">
        <label class="form-label">Дочерние узлы</label>
        <div v-for="directional in inputField.directional_tables" :key="directional.id">
          <b>{{ directional?.direction?.title?.[locale] || '' }}</b>
        </div>
      </div>
      <p v-if="isNotFieldError()" class="error-mesage">
        {{ isNotFieldError() }}
      </p>
    </template>
    <template v-slot:submit>Сохранить</template>
  </ModalTemplateCenter>
</template>
