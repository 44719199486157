<script setup lang="js">
import { onMounted, ref, computed } from 'vue';
import { useInputGeometryCatalogsStore } from '@/stores/inputGeometryCatalogs.store';
import { useProjectStore } from '@/stores/project.store';
import { useNodeStore } from '@/stores/node.store';
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';
import { useRouteParams } from '@vueuse/router';
import InputGeoCatalogChildNodeModal from '@/components/modals/InputGeoCatalogChildNodeModal.vue';

const inputGeoCatalogStore = useInputGeometryCatalogsStore();
const projectStore = useProjectStore();
const { project } = storeToRefs(projectStore);
const nodeStore = useNodeStore();
const { openModal, activeModal } = useModal();
const projectId = useRouteParams('projectId');
const bpId = useRouteParams('bpId');
const nodeId = useRouteParams('nodeId');
const { inputGeometryCatalogList } = storeToRefs(inputGeoCatalogStore);
const { bpNode } = storeToRefs(nodeStore);
const selectedChildBpNode = ref({});
const selectedChildBpNodeId = ref('');
const selectedChildPivotId = ref('');
const hasUnstableInputGeoCatalog = computed(() => {
  return inputGeometryCatalogList.value.some((inputGeomCatalog) => !inputGeomCatalog.stable);
});

onMounted(() => {
  inputGeoCatalogStore.clearRequestVariables();
  if (!project.value.locales) {
    projectStore.getProject(projectId.value);
  }
  inputGeoCatalogStore.getInputGeometryCatalogList(projectId.value, bpId.value, nodeId.value);
  nodeStore.getNode(projectId.value, bpId.value, nodeId.value);
});
function selectNode(bpNode) {
  selectedChildBpNode.value = bpNode;
  selectedChildBpNodeId.value = bpNode.id;
  selectedChildPivotId.value = bpNode.pivot.id;
}
function nodeName(title) {
  return title[project.value.default_locale];
}
function getTitle(item) {
  return item?.title[project.value.default_locale];
}
function showDoc(doc) {
  return doc?.directional_geo_catalogs.some(
    (d) => d.direction.next_bp_node_id == selectedChildBpNodeId.value
  );
}
</script>

<template>
  <div class="a">
    <div class="d-flex align-items-center justify-content-between input-fields__btns mt-2">
      <div class="text-uppercase col-md-4 px-3">
        <b>Настроить гео каталоги для дочерних узлов</b>
      </div>
      <div class="d-flex align-items-center justify-content-end flex-wrap mr-3 col-md-8 px-3">
        <RouterLink
          :to="{
            name: 'input-geometry-catalog',
            params: {
              projectId: projectId,
              bpId: bpId,
              nodeId: nodeId
            }
          }"
          class="gray-btn"
        >
          Закрыть
        </RouterLink>
      </div>
    </div>
    <div class="bp-section-content_child-links">
      <div
        class="bp-section-content_child-link"
        v-for="childBpNode in bpNode.child_nodes"
        :key="childBpNode.id"
        @click="selectNode(childBpNode)"
        :class="{ active: selectedChildBpNodeId == childBpNode.id }"
      >
      <span :class="{ 'childNode-fixation_circle': hasUnstableInputGeoCatalog }">{{
        nodeName(childBpNode.current.node.title)
      }}</span>
      </div>
    </div>
    <div class="bp-section-content_node-name px-3 my-3" v-if="selectedChildBpNodeId">
      <b>{{ nodeName(selectedChildBpNode.current.node.title) }}</b>
    </div>
    <button
      class="simple-btn mt-3 mb-3 ml-3"
      @click="openModal('addGeoCatalogForChildsModal')"
      v-if="selectedChildBpNodeId"
    >
      <span class="orange-plus" />
      Добавить
    </button>
  </div>
  <div class="table-responsive pb-5 child-nodes_table">
    <table class="table gray-table" v-if="selectedChildBpNodeId">
      <thead>
        <tr>
          <th>Наименование</th>
          <th>Слой</th>
          <th>Обязательность</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="inputGeoCatalog in inputGeometryCatalogList" :key="inputGeoCatalog.id">
          <tr
            v-if="showDoc(inputGeoCatalog)"
            :class="{
              'fixation-row-style': !inputGeoCatalog.stable
            }"
          >
            <td>
              <div class="d-flex align-items-center">
                <div v-if="!inputGeoCatalog.stable" class="fixation-circle-style"></div>
                <span class="name ps-2">{{ getTitle(inputGeoCatalog.geo_catalog) }}</span>
              </div>
            </td>
            <td>{{ getTitle(inputGeoCatalog.geo_catalog.layer) }}</td>
            <td>{{ inputGeoCatalog.geo_catalog.required }}</td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
  <InputGeoCatalogChildNodeModal
    v-if="activeModal === 'addGeoCatalogForChildsModal'"
    :selectedChildPivotId="selectedChildPivotId"
    :selectedChildBpNodeId="selectedChildBpNodeId"
  />
</template>
