import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '../stores/auth.store';
import LoginView from '../views/LoginView.vue';
import ProjectListView from '../views/ProjectListView.vue';
import ProjectView from '../views/ProjectView.vue';
import ServiceListView from '../views/ServiceListView.vue';
import ServiceRouteListView from '../views/ServiceRouteListView.vue';
import BPListView from '../views/BPListView.vue';
import CriterionListView from '../views/CriterionListView.vue';
import ProjectInfoView from '../views/ProjectInfoView.vue';

import BPView from '../views/BPView.vue';
import NodeView from '../views/NodeView.vue';
import NodeFormView from '../views/NodeFormView.vue';
import InputFieldsView from '../views/InputFieldsView.vue';
import InputFieldsForChildNodesView from '../views/InputFieldsForChildNodesView.vue';
import ViewFieldsView from '../views/ViewFieldsView.vue';
import DocumentsView from '../views/DocumentsView.vue';
import InputDocumentsView from '../views/InputDocumentsView.vue';
import InputDocumentsForChildNodesView from '../views/InputDocumentsForChildNodesView.vue';
import ViewDocumentsView from '../views/ViewDocumentsView.vue';

import GeometryView from '../views/GeometryView.vue';

import GeometryFields from '../views/GeometryFieldsView.vue';
import GeometryFieldsInput from '../views/InputGeometryFieldsView.vue';
import InputGeometriesForChildNodesView from '../views/InputGeometriesForChildNodesView.vue';
import GeometryFieldsView from '../views/ViewGeometryFieldsView.vue';

import GeometryCatalogs from '../views/GeometryCatalogsView.vue';
import GeometryCatalogsInput from '../views/InputGeometryCatalogsView.vue';
import InputGeometryCatalogsForChildNodesView from '../views/InputGeometryCatalogsForChildNodesView.vue';
import GeometryCatalogsView from '../views/ViewGeometryCatalogsView.vue';

import GeometryLayers from '../views/GeometryLayers.vue';
import СolumnsView from '../views/СolumnsView.vue';
import AdminView from '../views/AdminView.vue';
import AdminSettingAccessView from '../views/AdminSettingAccessView.vue';
import AutoformsView from '../views/AutoformsView.vue';
import AutoformCreateView from '../views/AutoformCreateView.vue';
import AutoformUpdateView from '../views/AutoformUpdateView.vue';
import ProjectTeamView from '../views/ProjectTeamView.vue';
import RegistriesView from '../views/RegistriesView.vue';
import SubmoduleGroupsView from '../views/SubmoduleGroupsView.vue';
import SubmoduleGroupView from '../views/SubmoduleGroupView.vue';
import EndSubmoduleView from '../views/EndSubmoduleView.vue';
import CommonSubmoduleView from '../views/CommonSubmoduleView.vue';
import AutonodeView from '../views/AutonodeView.vue';
import WaitingAutonodeView from '../views/WaitingAutonodeView.vue';
import VirtualMachinesView from '../views/VirtualMachinesView.vue';
import VirtualMachineInfoView from '../views/VirtualMachineInfoView.vue';
import ProjectSubmodulesGroupsView from '../views/ProjectSubmodulesGroupsView.vue';

import { storeToRefs } from 'pinia';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: { name: 'project-list' },
      meta: {
        needAuth: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: LoginView
    },
    {
      path: '/admin',
      name: 'admin-list',
      component: AdminView,
      meta: {
        needAuth: true
      }
    },
    {
      path: '/admin/:userId(\\d+)',
      name: 'user-id',
      component: AdminSettingAccessView,
      meta: {
        needAuth: true
      }
    },
    {
      path: '/projects',
      name: 'project-list',
      component: ProjectListView,
      meta: {
        needAuth: true
      }
    },
    {
      path: '/projects/:projectId(\\d+)',
      name: 'project-id',
      component: ProjectView,
      meta: {
        needAuth: true
      },
      children: [
        {
          path: 'virtual-machines',
          name: 'virtual-machine-list',
          component: VirtualMachinesView,
          meta: {
            needAuth: true
          }
        },
        {
          path: 'project-submodules-groups',
          name: 'project-submodule-group-list',
          component: ProjectSubmodulesGroupsView,
          meta: {
            needAuth: true
          }
        },
        {
          path: 'services',
          name: 'service-list',
          component: ServiceListView,
          meta: {
            needAuth: true
          }
        },
        {
          path: 'bp',
          name: 'bp',
          component: BPListView,
          meta: {
            needAuth: true
          }
        },
        {
          path: 'criterion',
          name: 'criterion',
          component: CriterionListView,
          meta: {
            needAuth: true
          }
        },
        {
          path: 'info',
          name: 'project-info',
          component: ProjectInfoView,
          meta: {
            needAuth: true
          }
        },
        {
          path: 'team',
          name: 'team',
          component: ProjectTeamView,
          meta: {
            needAuth: true
          }
        },
        {
          path: 'registries',
          name: 'registries',
          component: RegistriesView,
          meta: {
            needAuth: true
          }
        }
      ]
    },
    {
      path: '/projects/:projectId(\\d+)/bp/:bpId(\\d+)',
      name: 'bp-id',
      component: BPView,
      meta: {
        needAuth: true
      },
      children: [
        {
          path: ':nodeId(\\d+)',
          name: 'node-id',
          component: NodeView,
          meta: {
            needAuth: true
          },
          children: [
            {
              path: 'form',
              name: 'form',
              component: NodeFormView,
              meta: {
                needAuth: true
              },
              children: [
                {
                  path: 'input',
                  name: 'input-fields',
                  component: InputFieldsView,
                  meta: {
                    needAuth: true
                  },
                  children: [
                    {
                      path: 'childs',
                      name: 'childs',
                      component: InputFieldsForChildNodesView,
                      meta: {
                        needAuth: true
                      }
                    }
                  ]
                },
                {
                  path: 'view',
                  name: 'view-fields',
                  component: ViewFieldsView,
                  meta: {
                    needAuth: true
                  }
                }
              ]
            },
            {
              path: 'documents',
              name: 'documents',
              component: DocumentsView,
              meta: {
                needAuth: true
              },
              children: [
                {
                  path: 'input',
                  name: 'input-documents',
                  component: InputDocumentsView,
                  meta: {
                    needAuth: true
                  },
                  children: [
                    {
                      path: 'inputDocChilds',
                      name: 'input-documents-child',
                      component: InputDocumentsForChildNodesView,
                      meta: {
                        needAuth: true
                      }
                    }
                  ]
                },
                {
                  path: 'view',
                  name: 'view-documents',
                  component: ViewDocumentsView,
                  meta: {
                    needAuth: true
                  }
                }
              ]
            },
            {
              path: 'geometry',
              name: 'geometry',
              component: GeometryView,
              meta: {
                needAuth: true
              },
              children: [
                {
                  path: 'fields',
                  name: 'geometry-fields',
                  component: GeometryFields,
                  meta: {
                    needAuth: true
                  },
                  children: [
                    {
                      path: 'input',
                      name: 'input-geometry',
                      component: GeometryFieldsInput,
                      meta: {
                        needAuth: true
                      },
                      children: [
                        {
                          path: 'inputGeomChilds',
                          name: 'input-geometries-child',
                          component: InputGeometriesForChildNodesView,
                          meta: {
                            needAuth: true
                          }
                        }
                      ]
                    },
                    {
                      path: 'view',
                      name: 'view-geometry',
                      component: GeometryFieldsView,
                      meta: {
                        needAuth: true
                      }
                    }
                  ]
                },
                {
                  path: 'catalogs',
                  name: 'geometry-catalogs',
                  component: GeometryCatalogs,
                  meta: {
                    needAuth: true
                  },
                  children: [
                    {
                      path: 'input',
                      name: 'input-geometry-catalog',
                      component: GeometryCatalogsInput,
                      meta: {
                        needAuth: true
                      },
                      children: [
                        {
                          path: 'inputGeomCatalogChilds',
                          name: 'input-geometry_catalogs-child',
                          component: InputGeometryCatalogsForChildNodesView,
                          meta: {
                            needAuth: true
                          }
                        }
                      ]
                    },
                    {
                      path: 'view',
                      name: 'view-geometry-catalog',
                      component: GeometryCatalogsView,
                      meta: {
                        needAuth: true
                      }
                    }
                  ]
                },
                {
                  path: 'layers',
                  name: 'geometry-layers',
                  component: GeometryLayers,
                  meta: {
                    needAuth: true
                  }
                }
              ]
            },
            {
              path: 'autoforms',
              name: 'autoforms',
              component: AutoformsView,
              meta: {
                needAuth: true
              }
            }
          ]
        }
      ]
    },
    {
      path: '/projects/:projectId(\\d+)/services/:serviceId(\\d+)',
      name: 'service-route',
      component: ServiceRouteListView,
      meta: {
        needAuth: true
      }
    },
    {
      path: '/projects/:projectId(\\d+)/bp/:bpId(\\d+)/:nodeId(\\d+)/form/input/:inputTableId(\\d+)/columns',
      name: 'input-table-id',
      component: СolumnsView,
      meta: {
        needAuth: true
      }
    },
    {
      path: '/projects/:projectId(\\d+)/bp/:bpId(\\d+)/:nodeId(\\d+)/autoforms/create',
      name: 'create-autoform',
      component: AutoformCreateView,
      meta: {
        needAuth: true
      }
    },
    {
      path: '/projects/:projectId(\\d+)/bp/:bpId(\\d+)/:nodeId(\\d+)/autoforms/:autoformId(\\d+)',
      name: 'update-autoform',
      component: AutoformUpdateView,
      meta: {
        needAuth: true
      }
    },
    {
      path: '/projects/:projectId(\\d+)/bp/:bpId(\\d+)/:nodeId(\\d+)/autonode',
      name: 'autonode',
      component: AutonodeView,
      meta: {
        needAuth: true
      }
    },
    {
      path: '/projects/:projectId(\\d+)/bp/:bpId(\\d+)/:nodeId(\\d+)/waiting-autonode',
      name: 'waiting-autonode',
      component: WaitingAutonodeView,
      meta: {
        needAuth: true
      }
    },
    {
      path: '/submodule-groups',
      name: 'submodule-group-list',
      component: SubmoduleGroupsView,
      meta: {
        needAuth: true
      }
    },
    {
      path: '/submodule-groups/:submoduleGroupId(\\d+)',
      name: 'submodule-group-id',
      component: SubmoduleGroupView,
      meta: {
        needAuth: true
      },
      children: [
        {
          path: 'end-submodule',
          name: 'end-submodule-list',
          component: EndSubmoduleView,
          meta: {
            needAuth: true
          }
        },
        {
          path: 'common-submodule',
          name: 'common-submodule-list',
          component: CommonSubmoduleView,
          meta: {
            needAuth: true
          }
        }
      ]
    },
    {
      path: '/projects/:projectId(\\d+)/virtual-machines/:virtualMachineId(\\d+)',
      name: 'virtual-machine-info',
      component: VirtualMachineInfoView,
      meta: {
        needAuth: true
      }
    },
  ]
});

router.beforeEach((to) => {
  const authStore = useAuthStore();
  const { authorized } = storeToRefs(authStore);

  if (to.meta.needAuth && !authorized.value && to.name !== 'login') {
    return { name: 'login' };
  }
});

export default router;
