<script setup lang="js">
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import { useInputGeometryCatalogsStore } from '@/stores/inputGeometryCatalogs.store';
import { useProjectStore } from '@/stores/project.store';
import { storeToRefs } from 'pinia';
import { onMounted, ref, defineProps, computed } from 'vue';
import { useRouteParams } from '@vueuse/router';
import { useLayerStore } from '@/stores/layer.store';
import Multiselect from 'vue-multiselect';
import BasicToggleSwitch from '@/components/toggle-switch.vue';

const locale = computed(() => projectStore.project.default_locale);
const { closeModal } = useModal();
const inputGeoCatalogStore = useInputGeometryCatalogsStore();
const { inputGeometryCatalogEditRequest } = storeToRefs(inputGeoCatalogStore);
const projectStore = useProjectStore();
const { project } = storeToRefs(projectStore);
const bpId = useRouteParams('bpId');
const nodeId = useRouteParams('nodeId');
const projectId = useRouteParams('projectId');
const props = defineProps({
  selectedGeomCatalog: Object
});
const layerStore = useLayerStore();
const { layersList } = storeToRefs(layerStore);

onMounted(() => {
  inputGeoCatalogStore.clearRequestVariables();
  if (!project.value.locales) {
    projectStore.getProject(projectId.value);
  }
  layerStore.getLayersList(projectId.value, bpId.value, nodeId.value);
});

function updateGeometry() {
  console.log('sd',  props.selectedGeomCatalog.geo_catalog)
  inputGeoCatalogStore
    .inputGeomCatalogUpdate(
      projectId.value,
      bpId.value,
      nodeId.value,
      props.selectedGeomCatalog.id,
      props.selectedGeomCatalog.geo_catalog.layer.id,
      props.selectedGeomCatalog.geo_catalog.title,
      props.selectedGeomCatalog.geo_catalog.required
    )
    .then(() => {
      closeModal();
    });
}
function isErrorField(fieldName) {
  if (inputGeometryCatalogEditRequest.value.error?.errors) {
    return inputGeometryCatalogEditRequest.value.error.errors[fieldName] || false;
  }
}
function isNotFieldError() {
  if (!inputGeometryCatalogEditRequest.value.error?.errors) {
    return inputGeometryCatalogEditRequest.value.error;
  }
}
function getLayerTitle(layer) {
  return layer.title[locale.value];
}
</script>

<template>
  <ModalTemplateSide @submit="updateGeometry">
    <template v-slot:title>Редактирование гео каталога для заполнения</template>
    <template v-slot:body>
      <div class="mt-3" v-for="locale in project.locales" :key="locale.id">
        <label class="form-label">Наименование геометрии на {{ locale.name }}</label>
        <input
          type="text"
          v-model="props.selectedGeomCatalog.geo_catalog.title[locale.id]"
          class="form-control"
          :class="{ error: isErrorField('title') }"
        />
      </div>
      <p v-if="isErrorField('title')" class="error-mesage">
        <span v-for="error in isErrorField('title')">{{ error }}</span>
      </p>
      <div class="mt-3">
        <label class="form-label">Слои</label>
        <Multiselect
          v-model="props.selectedGeomCatalog.geo_catalog.layer"
          :options="layersList"
          placeholder="Выбрать..."
          :multiple="false"
          :custom-label="getLayerTitle"
          track-by="id"
          class="multiselect-style"
          :class="{ error: isErrorField('layerId') }"
        />
        <p v-if="isErrorField('layerId')" class="error-mesage">
          <span v-for="error in isErrorField('layerId')">{{ error }}</span>
        </p>
      </div>
      <div class="mt-3 d-flex align-items-center justify-content-between">
        <label class="form-label">Обязательность</label>
        <basic-toggle-switch v-model="props.selectedGeomCatalog.geo_catalog.required" />
      </div>
      <p v-if="isErrorField('required')" class="error-mesage">
        <span v-for="error in isErrorField('required')">{{ error }}</span>
      </p>
      <p v-if="isNotFieldError()" class="error-mesage">
        {{ isNotFieldError() }}
      </p>
    </template>
    <template v-slot:submit>Сохранить</template>
  </ModalTemplateSide>
</template>
